import React from "react";
import { SideBar } from "./sidebar";
import {
  getAuctions,
  getAllBidders,
  updateInvoiceHelpers,
  getAllAuctionsByGroup,
  auctionApproval,
  reAuction,
  //getAllWinners,
} from "../../models/auctions";
import { getAllCategories } from "../../models/categories";
import { getAllGroups } from "../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import { getLoggedUser } from "../../state/user";
import { MzadLoader } from "../helpers/mzadLoader";
import { HomeBanner } from "../home/homeBanner";
import { getAllOrganization } from "../../models/organization";
import FileInputNew from "../helpers/fileInputNew";
import AuctionTable from "./helper/auction_table";
import { apiUrl } from "../../constants/global";
const user = getLoggedUser();

function objectToQueryString(obj) {
  const queryString = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryString;
}
function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export class AuctionApproval extends React.Component {
  constructor() {
    super();
    //let urlSearchParams = new URLSearchParams(window.location.search);
    //let params = Object.fromEntries(urlSearchParams.entries());
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    this.state = {
      op_type: "approve",
      group_total: 0,
      is_loading: false,
      is_group_loading: false,
      auctions: [],
      open_popup: false,
      categories: [],
      clients: [],
      groups: [],
      group_auctions: [],
      selected_group: {},
      totalRows: 0,
      totalGroupRows: 0,
      currentPage: 1,
      approve: {
        approved_date: formatDate(today),
        invoice: "MZD",
      },
      reauction: {},
      counts: {
        pending: 0,
        approved: 0,
        rejected: 0,
        total: 0,
      },
      input: {
        section: "main",
        page: 1,
        limit: 15,
        fdate: formatDate(startDate),
        tdate: formatDate(endDate),
      },
      rem_amount: "",
      service_inv_link: "",
      remarks_popup: false,
      remarks: {
        amount: "",
        remark: "",
      },
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e4f7ff",
            color: "#000000",
          },
        },
      },
      selected_id: null,
      selectedRows: [],
      columns: [
        {
          id: 1,
          name: i18n.t("sl_no"),
          sortable: true,
          wrap: true,
          width: "70px",
          cell: (row, i) => i + 1,
        },
        {
          id: 2,
          name: "ID",
          sortable: true,
          wrap: true,
          width: "70px",
          cell: (row) => row.id,
        },
        // {
        //   id: 3,
        //   name: i18n.t("title"),
        //   cell: (row) => row.title,
        //   sortable: true,
        // },
        {
          id: 4,
          name: i18n.t("group_name"),
          cell: (row) => row.group_name,
        },
        {
          id: 5,
          name: i18n.t("client"),
          cell: (row) =>
            row.organization_info
              ? row.organization_info.organization_name
              : "",
          wrap: true,
        },
        {
          id: 6,
          name: i18n.t("total_auctions"),
          cell: (row) => row.total_auctons,
          wrap: true,
        },
        {
          id: 7,
          name: i18n.t("start_date"),
          cell: (row) => row.start_date,
          wrap: true,
        },
        {
          id: 8,
          name: i18n.t("end_date"),
          cell: (row) => row.end_date,
          wrap: true,
        },
        {
          id: 8,
          name: i18n.t("total_amount"),
          cell: (row) => row.total_amount.toFixed(3),
          wrap: true,
        },
        // {
        //   id: 8,
        //   name: i18n.t("approved_document"),
        //   cell: (row) => {
        //     return row.file_approved_doc ? (
        //       <a
        //         className="btn btn-outline-info btn-sm text-info"
        //         href={row.file_approved_doc}
        //         target="_new"
        //       >
        //         {i18n.t("view")}
        //       </a>
        //     ) : (
        //       ""
        //     );
        //   },
        //   wrap: true,
        // },
        // {
        //   id: 9,
        //   name: i18n.t("reauction_document"),
        //   cell: (row) => {
        //     return row.file_reauction_doc ? (
        //       <a
        //         className="btn btn-outline-danger btn-sm text-danger"
        //         href={row.file_reauction_doc}
        //         target="_new"
        //       >
        //         {i18n.t("view")}
        //       </a>
        //     ) : (
        //       ""
        //     );
        //   },
        //   wrap: true,
        // },
        // {
        //   id: 10,
        //   name: i18n.t("status"),
        //   wrap: false,
        //   width: "100px",
        //   cell: (row) => {
        //     return (
        //       <>
        //         {!row.approve_status && (
        //           <span className="text-warning my-2">{i18n.t("pending")}</span>
        //         )}
        //         {row.approve_status && row.approve_status === "A" && (
        //           <span className="text-success my-2">
        //             {i18n.t("approved")}
        //           </span>
        //         )}
        //         {row.approve_status && row.approve_status === "R" && (
        //           <span className="text-danger my-2">{i18n.t("rejected")}</span>
        //         )}
        //       </>
        //     );
        //   },
        // },
        {
          id: 11,
          name: i18n.t("download"),
          wrap: false,
          width: "180px",
          cell: (row) => {
            return (
              <div className="d-flex gap-1 mx-1 mt-1">
                {row.approve_status && row.total_auctons > 0 && (
                  <>
                    <a
                      className="btn btn-info btn-sm"
                      href={`${apiUrl}approval_invoice/export?group=${row.id}`}
                      target="_new"
                    >
                      {i18n.t("invoice")}
                    </a>
                    <a
                      className="btn btn-info btn-sm"
                      href={`${apiUrl}approval_invoice_vat/export?group=${row.id}`}
                      target="_new"
                    >
                      {i18n.t("VAT")}&nbsp;{i18n.t("invoice")}
                    </a>
                  </>
                )}
                {!row.approve_status && row.total_auctons > 0 && (
                  <>
                    <button
                      className="btn border-dark btn-sm text-dark"
                      disabled={true}
                      style={{ fontSize: "0.6rem" }}
                    >
                      {i18n.t("invoice")}
                    </button>
                    &nbsp;
                    <button
                      className="btn border-dark btn-sm text-dark"
                      disabled={true}
                      style={{ fontSize: "0.6rem" }}
                    >
                      {i18n.t("VAT")}&nbsp;{i18n.t("invoice")}
                    </button>
                  </>
                )}
              </div>
            );
          },
        },
        // {
        //   id: 12,
        //   name: "",
        //   wrap: false,
        //   width: "70px",
        //   cell: (row) => {
        //     return (
        //       <div className="d-flex gap-1 mx-1 mt-1">
        //         <a
        //           className="text-success"
        //           href={`${apiUrl}auction_approval/export/excel?group=${row.id}`}
        //           target="_new"
        //         >
        //           <i className="fa fa-file-excel-o fa-2x" />
        //         </a>
        //         <a
        //           className="text-danger"
        //           href={`${apiUrl}auction_approval/export/pdf?group=${row.id}`}
        //           target="_new"
        //         >
        //           <i className="fa fa-file-pdf-o fa-2x" />
        //         </a>
        //       </div>
        //     );
        //   },
        // },
        {
          id: 13,
          name: i18n.t("action"),
          wrap: false,
          width: "250px",
          cell: (row) => {
            return (
              <div className="d-flex gap-1 mx-1 mt-1">
                {/* {!row.approve_status && ( */}
                {row.total_auctons > 0 && (
                  <button
                    className="btn btn-outline-info btn-sm"
                    style={{
                      textWrap: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({ is_individual: true });
                      this.openApprove(row);
                    }}
                  >
                    {i18n.t("approve")}/{i18n.t("reject")}
                  </button>
                )}
                {/* )} */}

                {/* <button
                  className="btn btn-outline-warning btn-sm"
                  style={{
                    textWrap: "nowrap",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.openReAuction(row.id);
                  }}
                >
                  {i18n.t("re_auction")}
                </button> */}
              </div>
            );
          },
        },
      ],
      bidder_loader: false,
      file_approved_doc: null,
      file_reauction_doc: null,
      winners: [],
      winners_loading: false,
      winners_meta: {},
      fileInputs: [{ id: 1, file: null }],
      is_individual: true,
      auc_page: 1,
      auc_limit: 5,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRemarkChange = this.handleRemarkChange.bind(this);
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
    this.remarksPopup = this.remarksPopup.bind(this);
    this.updateInveHelpers = this.updateInveHelpers.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleApproveChange = this.handleApproveChange.bind(this);
    this.handleReauctionChange = this.handleReauctionChange.bind(this);
    this.submitReAuction = this.submitReAuction.bind(this);
    this.submitApproval = this.submitApproval.bind(this);
    this.loadWinners = this.loadWinners.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    console.log("params", params);
    let { input } = this.state;
    let qstring =
      params.type === "click"
        ? new URLSearchParams(params).toString()
        : objectToQueryString(input);
    this.loadAllCategories();
    this.loadAllOrganisation();
    this.loadAllGroups();
    this.loadAllAuctions(qstring);

    if (user.role === "U") {
      let columns = this.state.columns;
      delete columns[1];
      delete columns[2];
      this.setState({ columns });
    }
  }

  handlePageChange = (page) => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let { input } = this.state;
    input["page"] = page;
    this.setState({ currentPage: page });
    this.setState({ input: input });
    this.setState({ query: params });
    let qstring = new URLSearchParams(input).toString();
    this.loadAllGroups(qstring);
  };

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
  }

  handleApproveChange(event) {
    let { approve } = this.state;
    approve[event.target.name] = event.target.value;
    this.setState({ approve });
  }

  handleReauctionChange(event) {
    let { reauction } = this.state;
    reauction[event.target.name] = event.target.value;
    this.setState({ reauction });
  }

  handleRemarkChange(event) {
    let { remarks } = this.state;
    remarks[event.target.name] = event.target.value;
    this.setState({ remarks });
  }

  onFileChange = (event) => {
    if (!this.validateFileSize(event.target.files[0])) {
      swal("Failed", "File size should be less than 5MB", "error");
      return;
    }
    if (event.target.name === "file_approved_doc") {
      this.setState({ file_approved_doc: event.target.files[0] });
    }
    if (event.target.name === "file_reauction_doc") {
      this.setState({ file_reauction_doc: event.target.files[0] });
    }
  };

  onFileChangeNew = (e, index) => {
    const updatedInputs = [...this.state.fileInputs];
    updatedInputs[index].file = e.target.files[0];
    this.setState({ fileInputs: updatedInputs });
  };

  addNewFileInput = () => {
    this.setState((prevState) => ({
      fileInputs: [
        ...prevState.fileInputs,
        { id: prevState.fileInputs.length + 1, file: null },
      ],
    }));
  };

  validateFileSize(file) {
    //const MIN_FILE_SIZE = 1024; // 1MB
    const MAX_FILE_SIZE = 2048; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  async openBidPopup(auction) {
    this.setState({ bidders: {}, bidder_loader: true });
    const response = await getAllBidders({ auction });
    let bidders = response.data;
    if (bidders && bidders.length > 0) {
      this.setState({ open_popup: true, bidders, bidder_loader: false });
    }
  }

  async loadAllCategories() {
    const response = await getAllCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  async loadAllOrganisation() {
    let q = "client_type=auc_apprv_not_approved";
    const response = await getAllOrganization(q);
    let clients = response.data;
    this.setState({ clients });
  }

  async loadAllGroups() {
    this.setState({ is_group_loading: true });
    let { input } = this.state;
    let q = `page=${input.page}&limit=${input.limit}&group_type=auc_apprv_not_approved&`;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    if (input.fdate) {
      q += `&fdate=${input.fdate}`;
    }
    if (input.tdate) {
      q += `&tdate=${input.tdate}`;
    }
    if (input.search_type) {
      q += `&search_type=${input.search_type}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    let meta = response.meta;
    this.setState({ totalGroupRows: meta.total });
    this.setState({ groups });
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total, counts: meta.counts });
      this.setState({ is_group_loading: false });
    }
  }

  async loadAuctionsByGrp(group) {
    const response = await getAllAuctionsByGroup(group);
    let group_auctions = response.data;
    this.setState({ group_auctions });
  }

  async loadAllAuctions(params) {
    this.setState({ is_loading: true });
    const response = await getAuctions("?" + params);
    if (response) {
      let auctions = response.data;
      let meta = response.meta;
      //this.setState({ totalRows: meta.total, counts: meta.counts });
      this.setState({ auctions: auctions, is_loading: false });
      this.setState({ group_total: meta.totalAmount });
    }
  }

  remarksPopup(link, amt, row) {
    if (row.inv_amount_words && row.inv_remarks && row.inv_title) {
      window.open(link, "_new");
      return;
    }
    this.setState({ service_inv_link: link, rem_amount: amt });
    this.setState({ remarks_popup: true });
    this.setState({ selected_id: row.id });
  }

  formatQueryNewLine(str) {
    str = encodeURIComponent(str);
    return str.replace(/%0A/g, "\\n");
  }

  updateInveHelpers() {
    let { remarks, selected_id } = this.state;
    let payload = {
      id: selected_id,
      inv_amount_words: remarks.amount,
      inv_title: remarks.title,
      inv_remarks: remarks.remark,
    };
    const response = updateInvoiceHelpers(payload);
    if (response) {
      let auction = response.data;
      console.log(auction);
      let params = {
        type: "",
      };
      this.loadAllAuctions(params);

      swal(i18n.t("success"), "Details updated", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          this.setState({ remarks_popup: false });
          let url = this.state.service_inv_link;
          window.open(url, "_new");
        })
        .catch((response) => {
          swal("Failed", "Something went wrong", "error");
        });
    }
  }

  // openBulkApprove() {
  //   let { selectedRows } = this.state;
  //   this.setState({ is_individual: false });
  //   this.openApprove(selectedRows[0], true);
  // }
  openApprove(group, is_group = false) {
    let { approve } = this.state;
    approve.approve_status = "A";
    approve.client_name = group?.organization_info?.organization_name;
    this.setState({
      open_approve_popup: true,
      approve,
      selected_id: group.id,
      selectedRows: [],
      selected_group: group,
    });
    this.loadAllAuctions(`group=${group.id}`);
  }
  openReject(id) {
    let { approve } = this.state;
    approve.approve_status = "R";
    this.setState({ open_approve_popup: true, approve, selected_id: id });
  }
  openReAuction(id) {
    this.setState({ open_reauction_popup: true, selected_id: id });
  }
  async submitReAuction() {
    let { selected_id, reauction } = this.state;
    const formData = new FormData();
    formData.append("id", [selected_id]);
    formData.append("reg_start_date", reauction.reg_start_date);
    formData.append("reg_end_date", reauction.reg_end_date);
    formData.append("start_date", reauction.start_date);
    formData.append("end_date", reauction.end_date);
    let msg = i18n.t("success");
    if (this.state.file_reauction_doc) {
      formData.append(
        "file_reauction_doc",
        this.state.file_reauction_doc,
        this.state.file_reauction_doc.name
      );
    }
    const response = await reAuction(formData);
    if (response.success) {
      this.setState({ open_reauction_popup: false });
      swal(i18n.t("success"), msg, "success");
    } else {
      swal(i18n.t("error"), response.message, "error");
      return;
    }
  }

  validateForm = () => {
    const { approve, fileInputs } = this.state;
    const errors = [];
    // Check for empty required fields
    const requiredFields = [
      "client_name",
      "bank_name",
      "bank_account",
      "remarks",
    ];
    requiredFields.forEach((field) => {
      if (!approve[field]) {
        errors.push(i18n.t(`${field}_required`));
      }
    });
    // Validate file inputs
    const hasFile = fileInputs.some((input) => input.file);
    if (!hasFile) {
      errors.push(i18n.t("file required"));
    }

    if (approve.approved_date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const approvedDate = new Date(approve.approved_date);
      if (approvedDate < today) {
        errors.push("Approved date must be future");
        swal(i18n.t("Error"), "Approved date must be future", "error");
        return;
      }
    }

    // Display errors if any
    if (errors.length > 0) {
      swal({
        title: i18n.t("error"),
        text: errors.join("\n"),
        icon: "error",
      });
      return false;
    }

    return true;
  };

  async submitApproval(status) {
    let { approve, is_individual, selectedRows, fileInputs } = this.state;
    // let file_valid = true;
    // fileInputs.forEach((input, index) => {
    //   if (input.file) {
    //     if (!input.file) {
    //       file_valid = false;
    //     }
    //   }
    // });

    // if (
    //   !approve.client_name ||
    //   !approve.bank_name ||
    //   !approve.bank_account ||
    //   !approve.remarks ||
    //   !file_valid
    // ) {
    //   swal(i18n.t("error"), "Please fill mandatory fields", "error");
    //   return;
    // }

    if (!this.validateForm()) {
      swal(
        i18n.t("Error"),
        `Please fill mandatory fields\nApproved date must be future`,
        "error"
      );
      return;
    }

    // Show confirmation dialog
    const willProceed = await swal({
      title: i18n.t("confirm_msg_continue"),
      //text: i18n.t("confirm_msg_continue"),
      icon: "warning",
      buttons: [i18n.t("cancel"), i18n.t("yes")],
      dangerMode: status !== "A",
    });

    // If user doesn't confirm, exit the function
    if (!willProceed) {
      return;
    }

    const formData = new FormData();
    formData.append("id", selectedRows);
    formData.append("approve_status", status);
    formData.append("remarks", approve.remarks);
    formData.append("invoice", approve.invoice);
    formData.append("client_name", approve.client_name);
    formData.append("bank_name", approve.bank_name);
    formData.append("bank_account", approve.bank_account);
    formData.append("is_individual", is_individual);

    let msg = i18n.t("rejected");
    if (status === "A") {
      fileInputs.forEach((input, index) => {
        if (input.file) {
          formData.append(
            `file_approved_doc_${index}`,
            input.file,
            input.file.name
          );
        }
      });

      msg = i18n.t("approved");
      formData.append("approved_date", approve.approved_date);
    }

    try {
      const response = await auctionApproval(formData);
      if (response.success) {
        //this.setState({ open_approve_popup: false });
        swal(i18n.t("success"), msg, "success");
        this.setState({
          fileInputs: [{ id: 1, file: null }],
        });
      } else {
        swal(i18n.t("error"), response.message, "error");
      }
    } catch (error) {
      console.error("Error in submitApproval:", error);
      swal(i18n.t("error"), i18n.t("an_error_occurred"), "error");
    }
  }

  async loadWinners(auction = false) {
    // this.setState({ winners_loading: true });
    // let payload = { group: this.state.input.group };
    // if (auction) {
    //   payload.auction = auction;
    // }
    // const response = await getAllWinners(payload);
    // if (response.data) {
    //   this.setState({ winners_loading: false });
    //   this.setState({ winners: response.data });
    //   this.setState({ winners_meta: response.meta });
    // }
  }

  handleSelectAll = (selectedIds) => {
    this.setState({ selectedRows: selectedIds });
  };

  handleSelectRow = (selectedIds) => {
    this.setState({ selectedRows: selectedIds });
  };

  removeFile = (index) => {
    this.setState((prevState) => ({
      fileInputs: prevState.fileInputs.filter((_, i) => i !== index),
    }));
  };

  render() {
    let {
      clients,
      groups,
      auctions,
      counts,
      selectedRows,
      selected_group,
      group_total,
      input,
    } = this.state;

    let export_params = new URLSearchParams(input).toString();

    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0 ">
                  <div className="card-header d-flex justify-content-between">
                    <div className="d-flex">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      <h3 className="card-title" style={{ textWrap: "nowrap" }}>
                        {i18n.t("auction_approval")}
                      </h3>
                    </div>
                  </div>

                  {/* {!this.state.is_loading && ( */}
                  <div className="card-body">
                    <div className="search-bar d-flex">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control textbox rounded-2"
                            placeholder={i18n.t("from_date")}
                            name="fdate"
                            value={this.state.input.fdate}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 mx-1">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control textbox rounded-2"
                            placeholder={i18n.t("to_date")}
                            name="tdate"
                            value={this.state.input.tdate}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2 mx-1">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Client"
                          name="client"
                          value={this.state.input.client}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_client")}</option>
                          {clients.length > 0 &&
                            clients.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.organization_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      {/* <div className="col-sm-2 col-md-2 mx-1">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Group"
                          name="group"
                          value={this.state.input.group}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_group")}</option>
                          {groups.length > 0 &&
                            groups.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.group_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-sm-2 col-md-2 mx-1">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Auction"
                          name="auction"
                          value={this.state.input.auction}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_auction")}</option>
                          {group_auctions.length > 0 &&
                            group_auctions.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.title}
                                </option>
                              );
                            })}
                        </select>
                      </div> */}
                      <div className="col-sm-2 col-md-2">
                        <button
                          className="btn btn-primary rounded-5"
                          type="button"
                          onClick={() => {
                            let { input } = this.state;
                            let qstring = new URLSearchParams(input).toString();
                            this.loadAllGroups(qstring);
                          }}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                    {this.state.is_loading && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(255, 255, 255, 0.7)",
                          zIndex: "99",
                        }}
                      >
                        <MzadLoader
                          animation="grow"
                          size="md"
                          loading={this.state.is_loading}
                          style={{ position: "relative" }}
                        />
                      </div>
                    )}
                    <div className="table-responsive ">
                      {/* {groups.length > 0 && ( */}
                      <>
                        <div class="bg-white rounded-4 hide_div mb-3">
                          <div class="p-1">
                            <div class="p-2 user-brd rounded-4">
                              <div class="grid_container ">
                                <div
                                  class="outer_round1 flex_container"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    let { input } = this.state;
                                    input.search_type = "pending";
                                    let qstring = new URLSearchParams(
                                      input
                                    ).toString();
                                    this.loadAllGroups(qstring);
                                    this.setState({ input });
                                  }}
                                >
                                  <div class="inner_round">
                                    <span>{counts.pending}</span>
                                  </div>
                                  <div class="outer_text lh20">
                                    <span>{i18n.t("pending")}</span>
                                    {i18n.t("auctions")}
                                  </div>
                                </div>
                                <div
                                  class="outer_round2 flex_container"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    let { input } = this.state;
                                    input.search_type = "approved";
                                    let qstring = new URLSearchParams(
                                      input
                                    ).toString();
                                    this.loadAllGroups(qstring);
                                    this.setState({ input });
                                  }}
                                >
                                  <div class="inner_round">
                                    <span>{counts.approved}</span>
                                  </div>
                                  <div class=" outer_text lh20">
                                    <span>{i18n.t("approved")}</span>
                                    {i18n.t("auctions")}
                                  </div>
                                </div>
                                <div
                                  class="outer_round3 flex_container"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    let { input } = this.state;
                                    input.search_type = "";
                                    let qstring = new URLSearchParams(
                                      input
                                    ).toString();
                                    this.loadAllGroups(qstring);
                                    this.setState({ input });
                                  }}
                                >
                                  <div class="inner_round">
                                    <span>{counts.total}</span>
                                  </div>
                                  <div class=" outer_text lh20">
                                    <span>{i18n.t("all")}</span>
                                    {i18n.t("auctions")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-wrapper">
                          {/* <div className="d-flex mb-1">
                              <button
                                className="btn btn-sm btn-success rounded-0"
                                disabled={selectedRows.length === 0}
                                onClick={() => {
                                  this.openBulkApprove();
                                }}
                              >
                                {i18n.t("approve")}/{i18n.t("reject")}
                              </button>
                            </div> */}

                          <MzadLoader
                            animation="grow"
                            size="md"
                            loading={this.state.is_group_loading}
                            style={{ position: "relative" }}
                          />
                          {groups.length > 0 && (
                            <div className="d-flex gap-1 justify-content-end mb-1 mx-1 mt-1">
                              <a
                                className="text-success"
                                href={`${apiUrl}auction_approval/export/excel?${export_params}`}
                                target="_new"
                              >
                                <i className="fa fa-file-excel-o fa-2x" />
                              </a>
                              <a
                                className="text-danger"
                                href={`${apiUrl}auction_approval/export/pdf?${export_params}`}
                                target="_new"
                              >
                                <i className="fa fa-file-pdf-o fa-2x" />
                              </a>
                            </div>
                          )}
                          <DataTable
                            responsive={true}
                            title=""
                            fixedHeader
                            columns={this.state.columns}
                            data={groups}
                            customStyles={this.state.customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalGroupRows}
                            paginationPerPage={this.state.input.limit}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Groups per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                            onChangeRowsPerPage={(size) => {
                              this.setState({ rows_per_page: 15 });
                              let { input } = this.state;
                              input.limit = size;
                              this.setState({ input });
                              this.handlePageChange(1);
                            }}
                            // selectableRows={
                            //   this.state.input.group ? true : false
                            // }
                            // selected={this.state.selectedRows}
                            // onSelectedRowsChange={(e) => {
                            //   let { selectedRows } = e;
                            //   const selectedIds = selectedRows.map(
                            //     (row) => row.id
                            //   );
                            //   this.setState({ selectedRows: selectedIds });
                            // }}
                          />
                        </div>
                      </>
                      {/* )} */}
                    </div>
                  </div>
                  {/* )} */}
                  {!this.state.is_loading && auctions.length === 0 && (
                    <div className="m-3 text-center p-3 text-red">
                      {i18n.t("no_auctions_found")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.open_approve_popup}
          onHide={() => this.setState({ open_approve_popup: false })}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-modal-sizes-title-lg"
              style={{ width: "95%" }}
            >
              <div className="d-flex justify-content-between">
                <div style={{ fontSize: "1rem" }}>
                  {selected_group?.organization_info?.organization_name} (
                  {i18n.t("total_auctions")}:{selected_group?.total_auctons})
                </div>

                {!this.state.is_loading && auctions.length > 0 && (
                  <div className="d-flex gap-1 justify-content-end mb-1 mx-1 mt-1">
                    <a
                      className="text-success"
                      href={`${apiUrl}auction_approval_grouped/export/excel?group=${selected_group?.id}`}
                      target="_new"
                    >
                      <i className="fa fa-file-excel-o fa-1x" />
                    </a>
                    <a
                      className="text-danger"
                      href={`${apiUrl}auction_approval_grouped/export/pdf?group=${selected_group?.id}`}
                      target="_new"
                    >
                      <i className="fa fa-file-pdf-o fa-1x" />
                    </a>
                  </div>
                )}

                <div className="px-4" style={{ fontSize: "1rem" }}>
                  {i18n.t("total_amount")} {group_total.toFixed(2)}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MzadLoader loading={this.state.is_loading} />
            {!this.state.is_loading && auctions.length > 0 && (
              <AuctionTable
                auctions={auctions}
                selectedRows={selectedRows}
                onSelectAll={this.handleSelectAll}
                onSelectRow={this.handleSelectRow}
                group={selected_group}
                onTrigger={(id) => {
                  this.loadAllAuctions(`group=${id}`);
                }}
              />
            )}

            {(user.role === "PM" || user.role === "OM") && (
              <div className="row mb-3">
                <div className="col-12 d-flex justify-content-center">
                  <div className="btn-group" role="group" aria-label="">
                    <button
                      type="button"
                      className={
                        this.state.op_type === "approve"
                          ? "btn btn-success"
                          : "btn btn-secondary"
                      }
                      onClick={() => {
                        this.setState({ op_type: "approve" });
                      }}
                    >
                      {i18n.t("approve")}
                    </button>
                    <button
                      type="button"
                      className={
                        this.state.op_type === "reject"
                          ? "btn btn-danger"
                          : "btn btn-secondary"
                      }
                      onClick={() => {
                        this.setState({ op_type: "reject" });
                      }}
                    >
                      {i18n.t("reject")}
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="row d-flex justify-content-center">
              {this.state.op_type === "approve" && (
                <div className="col-6 border p-2 rounded-3 bg-light mx-4">
                  <div className="row">
                    <div className="col-5">
                      <div className="control-group form-group">
                        <div className="form-group">
                          <input
                            type={user.role === "F" ? "date" : "hidden"}
                            placeholder={i18n.t("approval_date")}
                            value={this.state.approve.approved_date}
                            name="approved_date"
                            onChange={this.handleApproveChange}
                            className="form-control rounded-1"
                            style={{ fontSize: "0.9rem", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2"></div>
                    <div className="col-5 m-auto gap-3">
                      <div className="control-group form-group">
                        <div className="form-group">
                          <input
                            type="hidden"
                            placeholder={i18n.t("invoice")}
                            value={this.state.approve.invoice}
                            name="invoice"
                            onChange={this.handleApproveChange}
                            className="form-control rounded-1"
                            style={{ fontSize: "0.9rem", width: "95%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="form-label m-0">
                      {i18n.t("attatchment")}
                    </label>
                    {this.state.fileInputs.map((input, index) => (
                      <div key={input.id} className="row mb-2">
                        <div className="col-3"></div>
                        <div className="col-12 d-flex justify-content-between align-items-center">
                          <div className="flex-grow-1 me-2">
                            <FileInputNew
                              wrapperClass={`bg-white ${
                                !this.state.fileInputs[index].file
                                  ? "is-empty"
                                  : ""
                              }`}
                              className="form-control custom-input rounded-pill"
                              placeholder={i18n.t("choose_file")}
                              help_text=""
                              name="file_approved_doc"
                              id={`file_approved_doc_${index}`}
                              onChange={(e) => this.onFileChangeNew(e, index)}
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm rounded-circle"
                            style={{ padding: "0px 6px" }}
                            onClick={() => this.removeFile(index)}
                          >
                            &times;
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-3"></div>
                      <div className="col-12 d-flex justify-content-end align-items-center">
                        <button
                          className="btn btn-sm btn-info"
                          style={{ height: "90%" }}
                          onClick={this.addNewFileInput}
                        >
                          <i className="fa fa-plus" />
                        </button>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-12 m-auto gap-3">
                        <div className="control-group form-group">
                          <div className="form-group">
                            <label className="form-label m-0">
                              {i18n.t("remarks")}
                            </label>
                            <textarea
                              placeholder={i18n.t("remarks")}
                              value={this.state.approve.remarks}
                              name="remarks"
                              onChange={this.handleApproveChange}
                              className={`form-control rounded-1 ${
                                !this.state.approve.remarks
                                  ? "border-danger"
                                  : ""
                              }`}
                              rows={5}
                              style={{ fontSize: "0.9rem", width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="control-group form-group">
                          <div className="form-group">
                            <label className="form-label m-0">
                              {i18n.t("client_name")}
                            </label>
                            <input
                              placeholder={i18n.t("client_name")}
                              value={this.state.approve.client_name}
                              name="client_name"
                              onChange={this.handleApproveChange}
                              className={`form-control rounded-1 ${
                                !this.state.approve.client_name
                                  ? "border-danger"
                                  : ""
                              }`}
                              style={{ fontSize: "0.9rem", width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="control-group form-group">
                          <div className="form-group">
                            <label className="form-label m-0">
                              {i18n.t("client_bank_name")}
                            </label>
                            <input
                              placeholder={i18n.t("client_bank_name")}
                              value={this.state.approve.bank_name}
                              name="bank_name"
                              onChange={this.handleApproveChange}
                              className={`form-control rounded-1 ${
                                !this.state.approve.bank_name
                                  ? "border-danger"
                                  : ""
                              }`}
                              style={{ fontSize: "0.9rem", width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-4 m-auto gap-3">
                        <div className="control-group form-group">
                          <div className="form-group">
                            <label className="form-label m-0">
                              {i18n.t("client_bank_account")}
                            </label>
                            <input
                              placeholder={i18n.t("client_bank_account")}
                              value={this.state.approve.bank_account}
                              name="bank_account"
                              onChange={this.handleApproveChange}
                              className={`form-control rounded-1 ${
                                !this.state.approve.bank_account
                                  ? "border-danger"
                                  : ""
                              }`}
                              style={{ fontSize: "0.9rem", width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6 gap-3">
                        <div className="control-group form-group">
                          <div className="form-group">
                            <label className="form-label m-0">
                              {i18n.t("payment_date")}
                            </label>
                            <br />
                            <input
                              type="date"
                              placeholder={i18n.t("payment_date")}
                              value={this.state.approve.approved_date}
                              name="approved_date"
                              onChange={this.handleApproveChange}
                              className="form-control custom-input rounded-pill"
                              style={{ fontSize: "0.9rem" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-6 m-auto d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary rounded-5 m-auto px-4 mx-1"
                          onClick={() => {
                            this.submitApproval("A");
                          }}
                          disabled={selectedRows.length < 1 ? true : false}
                        >
                          {i18n.t("approve")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.op_type === "reject" &&
                (user.role === "PM" || user.role === "OM") && (
                  <div
                    className="col-4 border p-2 rounded-3 bg-light"
                    style={{ height: "fit-content" }}
                  >
                    <div className="row m-0 p-0">
                      <div className="col-12 m-0 p-0">
                        <div className="control-group form-group m-0 p-0">
                          <div className="form-group m-2">
                            <textarea
                              placeholder={i18n.t("reject_reason")}
                              value={this.state.approve.remarks}
                              name="remarks"
                              onChange={this.handleApproveChange}
                              className="form-control rounded-1"
                              rows={10}
                              style={{ fontSize: "0.9rem", width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-12 text-center">
                        <button
                          type="button"
                          className="btn btn-danger rounded-5 m-auto px-4"
                          onClick={() => {
                            this.submitApproval("R");
                          }}
                          disabled={selectedRows.length < 1 ? true : false}
                        >
                          {i18n.t("reject")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.open_reauction_popup}
          onHide={() => this.setState({ open_reauction_popup: false })}
          size="lg"
          dialogClassName="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("re_auction")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 m-auto">
                <FileInputNew
                  wrapperClass=""
                  className="form-control custom-input rounded-pill"
                  placeholder={i18n.t("choose_file")}
                  help_text=""
                  name="file_reauction_doc"
                  id="file_reauction_doc"
                  onChange={this.onFileChange}
                />
              </div>
              <div className="col-12 m-auto mt-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <b>{i18n.t("registration")}</b>
                  </div>
                  <div>
                    <label>{i18n.t("start_date")}</label>
                    <input
                      type="date"
                      placeholder={i18n.t("start_date")}
                      value={this.state.reauction.reg_start_date}
                      name="reg_start_date"
                      onChange={this.handleReauctionChange}
                      className="form-control custom-input rounded-pill"
                      style={{ fontSize: "0.9rem" }}
                    />
                  </div>
                  <div>
                    <label>{i18n.t("end_date")}</label>
                    <input
                      type="date"
                      placeholder={i18n.t("end_date")}
                      value={this.state.reauction.reg_end_date}
                      name="reg_end_date"
                      onChange={this.handleReauctionChange}
                      className="form-control custom-input rounded-pill"
                      style={{ fontSize: "0.9rem" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 m-auto mt-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <b>{i18n.t("auction")}</b>
                  </div>
                  <div>
                    <label>{i18n.t("start_date")}</label>
                    <input
                      type="date"
                      placeholder={i18n.t("start_date")}
                      value={this.state.reauction.start_date}
                      name="start_date"
                      onChange={this.handleReauctionChange}
                      className="form-control custom-input rounded-pill"
                      style={{ fontSize: "0.9rem" }}
                    />
                  </div>
                  <div>
                    <label>{i18n.t("end_date")}</label>
                    <input
                      type="date"
                      placeholder={i18n.t("end_date")}
                      value={this.state.reauction.end_date}
                      name="end_date"
                      onChange={this.handleReauctionChange}
                      className="form-control custom-input rounded-pill"
                      style={{ fontSize: "0.9rem" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 m-auto text-center mt-4">
                <button
                  type="button"
                  className="btn btn-primary rounded-5 m-auto px-4"
                  onClick={this.submitReAuction}
                >
                  {i18n.t("submit")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
