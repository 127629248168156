import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";

export class Recycling extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src={
              process.env.PUBLIC_URL + "/assets/images/banners/banner2.jpg"
            }
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">{i18n.t("recycling")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      Services
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="text-justify mb-7">
              <h2 className="mb-8">{i18n.t("recycling")}</h2>
              <p className="leading-Automatic mb-4"></p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
