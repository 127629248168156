import React from "react";
import { SideBar } from "./sidebar";
import { getUsersByRoles, getWalletUsers } from "../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
//import { getLoggedUser } from "../../state/user";
import { apiUrl } from "../../constants/global";
import { HomeBanner } from "../home/homeBanner";
import {
  getAllClientPayments,
  updateClientPayment,
} from "../../models/client_payment";
import swal from "sweetalert";
import { getAllGroups } from "../../models/groups";
import { getAllOrganization } from "../../models/organization";
import { getAllAuctionsByGroup } from "../../models/auctions";

//const user = getLoggedUser();
//let lang = localStorage.getItem("I18N_LANGUAGE");
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9 }),
};

const payment_statuses = {
  N: i18n.t("not_paid"),
  P: i18n.t("paid"),
};

const statuses = {
  N: i18n.t("not_approved"),
  A: i18n.t("approved"),
};

export class AdminClientPayments extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
      update_popup: false,
      add: {},
      data: {},
      errors: {},
      is_loading: false,
      transactions: [],
      customers: [],
      all_customers: [],
      hideSidebar: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
      },
      groups: [],
      clients: [],
      auctions: [],
      paid_amount: 0,
      total_amount: 0,
      due_amount: 0,
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "70px",
        },
        {
          name: i18n.t("bidder_id"),
          cell: (row) => row.bidder,
          sortable: true,
          wrap: false,
        },
        {
          name: i18n.t("username"),
          sortable: true,
          wrap: true,
          cell: (row) =>
            `${row.bidder && row.user_info ? row.user_info.name : ""}`,
        },
        {
          name: i18n.t("transaction"),
          cell: (row) => row.transaction_id,
          sortable: true,
        },
        {
          name: i18n.t("reference"),
          cell: (row) => row.reference,
          wrap: true,
        },
        {
          name: i18n.t("amount"),
          cell: (row) => row.paid_amount,
        },
        {
          name: i18n.t("payment"),
          wrap: true,
          cell: (row) =>
            `${row.payment_status ? payment_statuses[row.payment_status] : ""}`,
        },
        {
          name: i18n.t("date"),
          wrap: true,
          cell: (row) => row.payment_date,
        },
        {
          name: i18n.t("receipt"),
          selector: "file_receipt",
          cell: (row) => {
            if (row.file_receipt) {
              return (
                <a href={row.file_receipt} target="_new">
                  {i18n.t("receipt")}
                </a>
              );
            }
          },
        },
        {
          name: i18n.t("status"),
          wrap: true,
          cell: (row) => {
            let txtClass = row.status === "N" ? "text-danger" : "text-success";
            return (
              <span className={txtClass} style={{ textWrap: "nowrap" }}>
                {row.status ? statuses[row.status] : ""}
              </span>
            );
          },
        },
        {
          name: "",
          wrap: true,
          cell: (row) => {
            return row.status === "N" ? (
              <button
                className="btn btn-sm btn-success btn-block"
                style={{ textWrap: "nowrap" }}
                onClick={() => {
                  this.updateStatus(row.id);
                }}
              >
                {i18n.t("approve")}
              </button>
            ) : (
              ""
            );
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.triggerReload = this.triggerReload.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
    this.loadCustomers();
    this.loadAllCustomers();
    this.loadAllGroups();
    this.loadAllOrganisation();
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }
  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let clients = response.data;
    this.setState({ clients });
  }

  async loadCustomers() {
    this.setState({ is_loading: true });
    const response = await getWalletUsers();
    let data = response.data;
    if (response.success) {
      const customers = data.map((item) => {
        return {
          value: item.id,
          label: item.name + " (" + item.username + ")",
        };
      });

      this.setState({ is_loading: false });
      this.setState({ customers: customers });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async loadAllCustomers() {
    this.setState({ is_loading: true });
    let q = `roles=U&is_role_wise=${true}`;
    const response = await getUsersByRoles(q);
    let data = response.data["U"];
    if (response.success) {
      const customers = data.map((item) => {
        return {
          value: item.id,
          label: item.name + " (" + item.username + ")",
        };
      });
      this.setState({ is_loading: false });
      this.setState({ all_customers: customers });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async loadAuctionsByGrp(grp) {
    const response = await getAllAuctionsByGroup(grp);
    let auctions = response.data;
    this.setState({ auctions });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (
      event.target.name === "group" ||
      event.target.name === "client" ||
      event.target.name === "auction"
    ) {
      this.handleSearch(event);
    }
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
    if (event.target.name === "group") {
      this.loadAuctionsByGrp(event.target.value);
    }
  }

  handleSearch(event) {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
  }

  async loadAllTransactions(q) {
    this.setState({ is_loading: true });
    const response = await getAllClientPayments(q);
    let transactions = response.data;
    let meta = response.meta;
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ transactions: transactions });
      this.setState({ totalRows: meta.total });
      this.setState({
        total_amount: meta["total_amount"],
        paid_amount: meta["paid_amount"],
        due_amount: meta["due_amount"],
      });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllTransactions(qstring);
  };

  triggerReload(opt) {
    if (opt) {
      let { input } = this.state;
      let qstring = new URLSearchParams(input).toString();
      this.loadAllTransactions(qstring);
    }
  }

  updateStatus(id) {
    swal(i18n.t("confirm_msg_continue"), {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      const response = await updateClientPayment(
        { status: "P", is_status_update: true, id: id },
        id
      );
      if (response.success) {
        swal(i18n.t("success"), "Approved the selected info", "success", {
          buttons: false,
          timer: 2000,
        })
          .then((response) => {
            let { input } = this.state;
            let qstring = new URLSearchParams(input).toString();
            this.loadAllTransactions(qstring);
          })
          .catch((response) => {
            swal("Failed", "Something went wrong", "error");
          });
      }
      return false;
    });
  }

  render() {
    let { transactions, customers, input, clients, groups, auctions } =
      this.state;
    const queryString = Object.keys(input)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(input[key])}`
      )
      .join("&");
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between">
                    <div className=" d-flex">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      <h3 className="card-title">{i18n.t("client_payment")}</h3>
                    </div>

                    {!this.state.is_loading && transactions.length > 0 && (
                      <div className="p-0 m-0">
                        <a
                          href={apiUrl + "user_wallet/export?q=" + queryString}
                          target="_new"
                        >
                          <i className="fa fa-print fa-1x text-dark px-1 mx-1" />
                        </a>

                        <a
                          href={apiUrl + "user_wallet/excel?q=" + queryString}
                          target="_new"
                        >
                          <i className="fa fa-file-excel-o fa-1x text-dark px-1 mx-1" />
                        </a>
                      </div>
                    )}

                    <div className="d-flex gap-2 m-0 p-0">
                      <select
                        className="form-select textbox fs15 text-gray filter-icon rounded-2 w-200"
                        data-placeholder="Select Category"
                        name="client"
                        value={this.state.input.client}
                        onChange={this.handleChange}
                      >
                        <option value="">{i18n.t("select_client")}</option>
                        {clients.length > 0 &&
                          clients.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.organization_name}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        className="fform-select textbox fs15 text-gray filter-icon rounded-2 w-200"
                        data-placeholder="Select Group"
                        name="group"
                        value={this.state.input.group}
                        onChange={this.handleChange}
                      >
                        <option value="">{i18n.t("select_group")}</option>
                        {groups.length > 0 &&
                          groups.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.group_name}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        className="fform-select textbox fs15 text-gray filter-icon rounded-2 w-200"
                        data-placeholder="Select Auction"
                        name="auction"
                        value={this.state.input.auction}
                        onChange={this.handleChange}
                      >
                        <option value="">{i18n.t("select_auction")}</option>
                        {auctions.length > 0 &&
                          auctions.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>

                    {/* {user.role === "A" && (
                      <button
                        className="btn btn-sm btn-outline-dark"
                        onClick={(event) => {
                          this.setState({ open_popup: !this.state.open_popup });
                        }}
                      >
                        <i className="fa fa-plus-circle" />
                        &nbsp;
                        <span>{i18n.t("add_fund")}</span>
                      </button>
                    )} */}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.is_loading && (
                        <div className="text-center">
                          <Spinner animation="border" size="lg" />
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <input
                              type="date"
                              className="form-control"
                              placeholder={i18n.t("from_date")}
                              name="fdate"
                              value={this.state.input.fdate}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <input
                              type="date"
                              className="form-control"
                              placeholder={i18n.t("to_date")}
                              name="tdate"
                              value={this.state.input.tdate}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="pay_type"
                              value={this.state.input.pay_type}
                              onChange={this.handleChange}
                            >
                              <option value="">{i18n.t("pay_type")}</option>
                              <option value="online">{i18n.t("online")}</option>
                              <option value="offline">
                                {i18n.t("offline")}
                              </option>
                              <option value="wallet">{i18n.t("wallet")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="status"
                              value={this.state.input.status}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("status")}--</option>
                              <option value="P">{i18n.t("Paid")}</option>
                              <option value="N">{i18n.t("Not Paid")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <Select
                              options={customers}
                              onChange={(e) => {
                                let { input } = this.state;
                                input.customer = e.value;
                                this.setState({ input });
                              }}
                              name="customer"
                              styles={selectStyles}
                              menuPlacement="auto"
                              menuPortalTarget={document.body}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            {(this.state.input.customer ||
                              this.state.input.fdate ||
                              this.state.input.tdate) && (
                              <button
                                className="btn btn-danger rounded-0"
                                onClick={(e) => {
                                  let { input } = this.state;
                                  input.customer = "";
                                  input.fdate = "";
                                  input.tdate = "";
                                  this.setState({ input });
                                  this.handleSearch(e);
                                }}
                              >
                                <i className="fa fa-times" />
                              </button>
                            )}
                            <button
                              className="btn btn-outline-dark rounded-5"
                              onClick={this.handleSearch}
                            >
                              <i className="fa fa-search fa-1x" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {!this.state.is_loading && transactions.length > 0 && (
                        <div className="">
                          {this.state.input.customer && (
                            <div class="payment-info-section">
                              <div class="bg-lt-orange payment-info-container">
                                <span class="payment-info-text">
                                  {i18n.t("paid_amount")} :{" "}
                                  {this.state.paid_amount}
                                </span>
                              </div>
                              <div class="bg-lt-red payment-info-container">
                                <span class="payment-info-text">
                                  {i18n.t("due_amount")} :{" "}
                                  {this.state.due_amount}
                                </span>
                              </div>
                              <div class="bg-lt-green payment-info-container">
                                <span class="payment-info-text">
                                  {i18n.t("total_amount")} :{" "}
                                  {this.state.total_amount}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="table-wrapper">
                            <DataTable
                              title=""
                              fixedHeader
                              columns={this.state.columns}
                              data={transactions}
                              customStyles={customStyles}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={this.state.totalRows}
                              paginationPerPage={15}
                              paginationDefaultPage={this.state.currentPage}
                              paginationRowsPerPageOptions={[15, 25, 50, 100]}
                              paginationComponentOptions={{
                                rowsPerPageText: i18n.t(
                                  "transactions_per_page"
                                ),
                                rangeSeparatorText: i18n.t("out"),
                              }}
                              onChangePage={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
