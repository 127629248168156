import React from "react";
import i18n from "../../i18n";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// import i18n from "../../i18n";
// let lang = localStorage.getItem("I18N_LANGUAGE");
export class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer className="mzad-footer">
          <div className="head-office">
            <span className="ho-icon">
              <img
                src="/assets/images/head-office-icon.png"
                alt="head-office"
              />
              <span className="ho-title">{i18n.t("head_office")}</span>
            </span>
            <div className="head-office-map">
              <img src="/assets/images/map.png" alt="map" />
            </div>
          </div>
          <div className="footer-mid-section">
            <div className="address-whoweare">
              <div className="address">
                <span>
                  <img src="/assets/images/address-icon.png" alt="address" />
                  <span className="addr-title">{i18n.t("address")}</span>
                </span>
                <span className="addr-text">
                  <span className="footer-text">
                    Second Floor, Road No.oo.v July Street,
                  </span>
                  <span className="footer-text">
                    AI Ghubra South, P.O.Box Muscat,
                  </span>
                  <span className="footer-text">
                    {" "}
                    Sultanate of Oman, Muscat III{" "}
                  </span>
                </span>
              </div>
              <div className="who-we-are">
                <span>
                  <img
                    src="/assets/images/who-we-are-icon.png"
                    alt="who-we-are"
                  />
                  <span className="wwr-title">{i18n.t("whoweare")}</span>
                </span>
                <span className="wwr-content">
                  <span className="footer-text">
                    <Link to="about/message-chairman">
                      {i18n.t("about_mzadcom")}
                    </Link>
                  </span>
                  <span className="footer-text">
                    {" "}
                    <Link to="services">{i18n.t("services")}</Link>
                  </span>
                  <span className="footer-text">
                    {" "}
                    <Link to="contact">{i18n.t("contactus")}</Link>
                  </span>
                </span>
              </div>
            </div>
            <hr />
            <div className="contact">
              <span>
                <a href="mailto:info@mzadcom.om">
                  <img src="/assets/images/email-icon.png" alt="email" />
                  <span className="footer-text">info@mzadcom.om</span>
                </a>
              </span>
              <span>
                <a href="tel:+96892788822">
                  <img src="/assets/images/phone-icon.png" alt="phone" />
                  <span className="footer-text">+968 92 788 822</span>
                </a>
              </span>
            </div>
          </div>
          <div className="footer-right-section">
            <img
              src="/assets/images/mzad-footer-logo.png"
              alt="logo"
              width="150"
            />
            <span className="frs-cont">
              <img src="/assets/images/fb-icon.png" alt="facebook" />
              <img src="/assets/images/x-icon.png" alt="x" />
              <img src="/assets/images/insta-icon.png" alt="instagram" />
            </span>
          </div>
        </footer>
        <div className="text-center py-2 fs14 text-white bg-copyright">
          Copyright@mzadcom.com
        </div>

        <button
          className="footer-message auction-button"
          onClick={() => {
            var anchor = document.createElement("a");
            anchor.href = "https://api.whatsapp.com/send?phone=96892788822";
            anchor.target = "_blank";
            anchor.click();
          }}
        >
          <div className="image-container">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/messaging.png"}
              height="26.25"
              width="26.25"
              alt=""
            />
          </div>
          <span className="msg-label">{i18n.t("messaging")}</span>
        </button>
      </React.Fragment>
    );
  }
}
