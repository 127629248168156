import React from "react";
import { Link } from "react-router-dom";
import { updatePayment } from "../models/auctions";
import i18n from "../i18n";
import { retrieveIntent } from "../models/thawani";
import { Spinner } from "react-bootstrap";
export class WalletPaymentSuccess extends React.Component {
  constructor(props) {
    super(props);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let amount = params.amt;
    let type = params.type;
    let ptype = params.ptype;
    let is_card = params && params.card ? true : false;
    this.state = {
      amount: amount,
      type: type,
      ptype: ptype,
      is_card: is_card,
      is_error: false,
      is_loading: false,
    };
  }

  componentDidMount() {
    if (this.state.is_card) {
      this.checkPaymentIntent();
    } else {
      this.updateMzadPayment();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  async checkPaymentIntent() {
    this.setState({ is_loading: true });
    let payment_intent_id = localStorage.getItem("payment_intent_id");
    try {
      const res = await retrieveIntent(payment_intent_id);
      if (res.success && res.data && res.data.status === "succeeded") {
        this.setState({ is_error: false });
        this.updateMzadPayment();
        this.setState({ is_loading: false });
      } else {
        this.setState({ is_error: true });
        this.setState({ is_loading: false });
      }
    } catch (error) {
      console.error("Error checking payment intent:", error);
      this.setState({ is_error: true });
      this.setState({ is_loading: false });
    }
  }

  async updateMzadPayment() {
    this.setState({ is_loading: true });
    const response = await updatePayment({
      group_id: null,
      auction_id: null,
      enroll_id: null,
      gatePass: null,
      amount: this.state.amount,
      invoice: localStorage.getItem("invoice") || null,
      reference: localStorage.getItem("client_reference_id") || null,
      type: this.state.type,
      is_wallet_recharge: true,
      ptype: this.state.ptype,
    });
    if (response.success) {
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_error: true });
      this.setState({ is_loading: false });
    }
  }
  render() {
    let { is_error, is_loading } = this.state;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">
                    {is_error ? "Payment Error!!!" : "Payment Success!!!"}
                  </h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        Home
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            {is_loading && (
              <div className="container text-center">
                <Spinner animation="grow" size="lg" />
              </div>
            )}
            {!is_loading && (
              <div className="container text-center">
                <div className="display-1 mb-8"></div>
                <h1
                  className={
                    is_error ? "h2 mb-7 text-danger" : "h2 mb-7 text-success"
                  }
                >
                  {is_error ? "Payment Error" : "Payment Success"}
                </h1>
                <p
                  className={
                    is_error
                      ? "h4 font-weight-Automatic text-danger mb-8 leading-Automatic"
                      : "h4 font-weight-Automatic text-success mb-8 leading-Automatic"
                  }
                >
                  {!is_error && (
                    <span>
                      Success!!!. Now you can do bidding with available auctions
                      of same group.
                    </span>
                  )}
                  {is_error && <span>Error!!! Payment not completed!!!.</span>}
                </p>
                <Link className="btn btn-warning" to="/">
                  {i18n.t("back")}
                </Link>
                &nbsp;
                <Link className="btn btn-danger" to="/wallet">
                  {i18n.t("wallet")}
                </Link>
              </div>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
