import React from "react";
//import { Link } from "react-router-dom";
//import { SocialSignin } from "./socialSignin";
import i18n from "../../i18n";
import contryData from "../../constants/country.json";
import { otpLogin, validateOtp } from "../../models/login";
//import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import Dropdown from "react-bootstrap/Dropdown";

const codeData = [...contryData];
export class LoginOtp extends React.Component {
  constructor() {
    super();
    this.state = {
      selected_country: {},
      isCaptchaVerified: false,
      captchaToken: "",
      input: {
        dial_code: "+968",
        type: "sms",
      },
      is_otp_sent: false,
      t_c_acept: false,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendOtp = this.sendOtp.bind(this);
    this.validateOtp = this.validateOtp.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
  }

  componentDidMount() {
    var defaultCodeValue = this.getObjectByKeyValue(
      codeData,
      "dial_code",
      "+968"
    );
    this.setState({ selected_country: defaultCodeValue });
  }

  getObjectByKeyValue(jsonArray, key, value) {
    for (var i = 0; i < jsonArray.length; i++) {
      if (jsonArray[i][key] === value) {
        return jsonArray[i];
      }
    }
    return null;
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }

  handleCaptchaChange = (value) => {
    this.setState({
      isCaptchaVerified: true,
      captchaToken: value,
    });
    let { input } = this.state;
    input.captcha_token = value;
    this.setState({ input });
  };

  async sendOtp() {
    const response = await otpLogin(this.state.input);
    if (response.success) {
      this.setState({ is_otp_sent: true });
    } else {
      swal(response.message, JSON.stringify(response.data), "error");
    }
  }

  async validateOtp() {
    const response = await validateOtp(this.state.input);
    if (!response.success) {
      swal(response.message, response.data, "error");
      return;
    }
    if ("token" in response.data) {
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal(response.message, JSON.stringify(response.data), "error");
    }
  }

  render() {
    let selected_img =
      this.state.selected_country && this.state.selected_country.code
        ? this.state.selected_country.code.toLowerCase()
        : "";
    return (
      <React.Fragment>
        <div id="page-container-otp-login">
          <div className="divider"></div>
          <div className="content">
            <div className="main-container">
              <div className="container-header">
                <img src="./assets/images/login-icon.png" alt="login" />
                <span className="m-1">{i18n.t("login_using_otp")}</span>
              </div>
              <div className="inner-container">
                <div className="left-section">
                  <form id="login" className="card-body">
                    {!this.state.is_otp_sent && (
                      <div>
                        <div className="col-md-12">
                          <br></br>
                          <div style={{ width: "90%", margin: "auto" }}>
                            <label for="Name">
                              <span className="text-black">*</span>
                              {i18n.t("phone_number")}
                            </label>
                          </div>
                          <br></br>
                          <div style={{ width: "90%", margin: "auto" }}>
                            <Dropdown className="flag-dd">
                              <Dropdown.Toggle variant="country">
                                {this.state.selected_country && (
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/flags/" +
                                        selected_img +
                                        ".svg"
                                      }
                                      alt=""
                                      style={{ width: "25px" }}
                                    />
                                    {/* {this.state.selected_country.label} */}
                                  </div>
                                )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {codeData.map((item, i) => {
                                  let img = item.code.toLowerCase();
                                  return (
                                    <Dropdown.Item
                                      onClick={() => {
                                        let { input } = this.state;
                                        input.dial_code = item.dial_code;
                                        this.setState({
                                          input: item,
                                          selected_country: item,
                                        });
                                      }}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/images/flags/" +
                                          img +
                                          ".svg"
                                        }
                                        alt=""
                                        style={{ width: "25px" }}
                                      />{" "}
                                      {item.label}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                            <input
                              type="text"
                              style={{ "z-index": 0 }}
                              name="phone"
                              value={this.state.input.phone}
                              onChange={this.handleChange}
                              className="form-control custom-input rounded-pill mob-field"
                            />
                          </div>

                          <div className="text-danger text-justify">
                            {this.state.errors.mobile}
                          </div>
                        </div>

                        <div className="input-group input-group-sm mt-3">
                          <ReCAPTCHA
                            sitekey="6LceESUpAAAAAAC7C5FaMVWVBIdBRwWzMqR8Wvq7"
                            onChange={this.handleCaptchaChange}
                            className="m-auto recaptcha"
                            size="normal"
                            style={{
                              transform: "scale(0.85)",
                              transformOrigin: "0 0",
                            }}
                          />
                        </div>

                        {/* <hr />
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-warning btn-block"
                            onClick={this.sendOtp}
                          >
                            {i18n.t("send")}
                          </button>
                        </div> */}

                        <div className="send-otp-on">
                          <span>{i18n.t("send_otp_on")}</span>
                          <div className="horizontal-line"></div>
                        </div>
                        <div className="buttons">
                          <button
                            className="sms-btn"
                            type="button"
                            onClick={() => {
                              let { input } = this.state;
                              input.type = "sms";
                              this.setState({ input });
                              this.sendOtp();
                            }}
                          >
                            <img src="./assets/images/sms.png" alt="sms" />
                            <span style={{ textWrap: "nowrap" }}>
                              {i18n.t("SMS")}
                            </span>
                          </button>
                          <button
                            type="button"
                            className="whatsapp-btn"
                            onClick={() => {
                              let { input } = this.state;
                              input.type = "whatsapp";
                              this.setState({ input });
                              this.sendOtp();
                            }}
                          >
                            <img
                              src="./assets/images/whatsapp.png"
                              alt="whatsapp"
                            />
                            <span>{i18n.t("whatsapp")}</span>
                          </button>
                        </div>
                      </div>
                    )}

                    {this.state.is_otp_sent && (
                      <div className="col-md-12 m-auto">
                        <label>{i18n.t("enter_otp")}</label>
                        <div className="passwd">
                          <input
                            type="text"
                            name="otp"
                            value={this.state.input.otp}
                            onChange={this.handleChange}
                            className="form-control custom-input rounded-pill"
                          />
                        </div>
                        <hr />
                        <div className="submit">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={this.validateOtp}
                          >
                            {i18n.t("verify_login")}
                          </button>
                        </div>
                      </div>
                    )}

                    {/* <div className="submit">
                      <Link to="/login" className="text-primary ms-1">
                        {i18n.t("login_using_email_user")}
                      </Link>
                    </div>

                    <p className="mb-2">
                      <Link to="/forgotPassword" className="text-info ms-1">
                        {i18n.t("forget_password")}
                      </Link>
                    </p>
                    <p className="text-dark mb-0">
                      {i18n.t("donthaveaccount")}
                      <Link to="/registration" className="text-dark ms-1">
                        {i18n.t("registration")}
                      </Link>
                    </p> */}
                  </form>
                </div>
                <div className="right-section d-none d-md-block">
                  <img src="./assets/images/1696936701627 1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    /*<div>
        <section>
          <div
            className="sptb-1 cover-image"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0 pb-3">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="wft">{i18n.t("login")}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb background-custom">
          <div className="container customerpage">
            <div className="row">
              <div className="single-page">
                <div className="col-lg-5 col-xl-5 col-md-6 d-block mx-auto">
                  <div className="wrapper wrapper2">
                    <form id="login" className="card-body">
                      {!this.state.is_otp_sent && (
                        <div>
                          <div className="input-group input-group-sm mt-2">
                            <span className="form-control country-flag-field">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/flags/ar.svg"
                                }
                                alt=""
                              />
                            </span>
                            <input
                              type="text"
                              className="form-control col-9"
                              style={{ zIndex: 0 }}
                              name="phone"
                              value={this.state.input.phone}
                              onChange={this.handleChange}
                            />
                            <label>{i18n.t("phone_number")}</label>
                            <div className="text-danger">
                              {this.state.errors.mobile}
                            </div>
                          </div>

                          <div className="input-group input-group-sm mt-3">
                            <ReCAPTCHA
                              sitekey="6LceESUpAAAAAAC7C5FaMVWVBIdBRwWzMqR8Wvq7"
                              onChange={this.handleCaptchaChange}
                              size="normal"
                              className="m-auto"
                            />
                          </div>

                          <hr />
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-warning btn-block"
                              onClick={this.sendOtp}
                            >
                              {i18n.t("send")}
                            </button>
                          </div>
                        </div>
                      )}

                      {this.state.is_otp_sent && (
                        <div>
                          <div className="passwd">
                            <input
                              type="text"
                              name="otp"
                              value={this.state.input.otp}
                              onChange={this.handleChange}
                            />
                            <label>{i18n.t("enter_otp")}</label>
                          </div>
                          <hr />
                          <div className="submit">
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              onClick={this.validateOtp}
                            >
                              {i18n.t("verify_login")}
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="submit">
                        <Link to="/login" className="text-primary ms-1">
                          {i18n.t("login_using_email_user")}
                        </Link>
                      </div>

                      <p className="mb-2">
                        <Link to="/forgotPassword" className="text-info ms-1">
                          {i18n.t("forget_password")}
                        </Link>
                      </p>
                      <p className="text-dark mb-0">
                        {i18n.t("donthaveaccount")}
                        <Link to="/registration" className="text-dark ms-1">
                          {i18n.t("registration")}
                        </Link>
                      </p>
                    </form>
                    <SocialSignin />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>*/
  }
}
