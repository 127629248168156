import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import { Footer } from "./components/layouts/footer";
import { Topbar } from "./components/navigation/topbar";
import { NotificationIcon } from "./components/helpers/notificationIcon";

const isGoogleBot =
  navigator.userAgent.toLowerCase().indexOf("googlebot") !== -1;
const isIE = /*@cc_on!@*/ false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;
const isOpera =
  (!!window.opr && !!window.opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(" OPR/") >= 0;
const isChrome =
  !isGoogleBot &&
  !isEdge &&
  !isOpera &&
  !!window.chrome &&
  (!!window.chrome.webstore ||
    navigator.vendor.toLowerCase().indexOf("google inc.") !== -1);
const isSafari =
  !isChrome && navigator.userAgent.toLowerCase().indexOf("safari") !== -1;

function getLoggedUser() {
  let userInfo = localStorage.getItem("mzad_user_info");
  userInfo = JSON.parse(userInfo);
  return userInfo && userInfo.length > 0 ? userInfo : false;
}
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      splash: true,
    };
    this.splashRender = this.splashRender.bind(this);
  }
  componentDidMount() {
    let lng = localStorage.getItem("I18N_LANGUAGE");
    if (lng === "ar") {
      document.body.classList.add("ar");
    }
    if (isSafari) {
      document.body.classList.add("safari");
    }
    this.splashRender();
  }

  splashRender() {
    setTimeout(() => {
      this.setState({
        splash: false,
      });
    }, 1000);
  }
  render() {
    let user = getLoggedUser() || false;
    let is_logged = localStorage.getItem("is_logged_in") ? true : false;
    return (
      <Router>
        <Topbar is_logged={is_logged} user={user} />
        <Footer />
        <NotificationIcon />
      </Router>
    );
  }
}
export default App;
