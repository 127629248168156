import React from "react";
import { Dropdown } from "react-bootstrap";
import i18n from "../../../i18n";
import { getCategories } from "../../../models/categories";

let current_lng = localStorage.getItem("I18N_LANGUAGE");

export class CategorySearch extends React.Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      cat: null,
      cat_name: i18n.t("categories"),
      input: {
        title: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.gotoAuctions = this.gotoAuctions.bind(this);
  }
  componentDidMount() {
    this.loadAllCategories();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async loadAllCategories() {
    const response = await getCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  gotoAuctions() {
    let url = `auctions?sec=main`;
    url += this.state.cat ? `&category=${this.state.cat}` : "";
    url += this.state.input.title ? `&text=${this.state.input.title}` : "";
    window.location.href = url;
  }

  render() {
    let categories = this.state.categories;
    return (
      <React.Fragment>
        <div className="navy-blue-div p-3">
          <div className="row">
            <div className="col-xs-6 col-md-6 col-xs-offset-2 m-auto">
              <div className="input-group border rounded-pill p-0 bg-white">
                {/* <div className="input-group-btn search-panel">
                  <Dropdown className="cat-search-dd d-block">
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <span className="d-none d-sm-inline d-md-inline">
                        {this.state.cat_name}
                        <i className="fa fa-search mx-1 border border-dark rounded-pill p-1" />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {categories.map((item, i) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              this.setState({
                                cat: item.id,
                                cat_name:
                                  current_lng === "ar" && item.category_name_ar
                                    ? item.category_name_ar
                                    : item.category_name,
                              });
                            }}
                            key={item.id}
                          >
                            {current_lng === "ar" && item.category_name_ar
                              ? item.category_name_ar
                              : item.category_name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                <input
                  type="hidden"
                  name="search_param"
                  value="all"
                  id="search_param"
                />
                <input
                  type="text"
                  className="form-control cat-search-text border-0 bg-none px-3"
                  placeholder={i18n.t("search_by_name_loc_seller")}
                  name="title"
                  value={this.state.input.title}
                  onChange={this.handleChange}
                />
                <span className="input-group-btn rounded-pill bg-danger px-5">
                  <button
                    className="btn btn-danger cat-search-btn"
                    type="button"
                    onClick={this.gotoAuctions}
                  >
                    {i18n.t("search")}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
