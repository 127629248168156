import React from "react";
import i18n from "../../../i18n";

const ICONS = {
  pdf: { icon: "fa fa-file-pdf-o", color: "#FF0000" },
  png: { icon: "fa fa-picture-o", color: "#FF0000" },
  jpg: { icon: "fa fa-picture-o", color: "#FF0000" },
  jpeg: { icon: "fa fa-picture-o", color: "#FF0000" },
  bmp: { icon: "fa fa-picture-o", color: "#FF0000" },
  gif: { icon: "fa fa-picture-o", color: "#FF0000" },
  doc: { icon: "fa fa-file", color: "#db9c5f" },
  docx: { icon: "fa fa-file", color: "#db9c5f" },
  xls: { icon: "fa fa-file", color: "#db9c5f" },
  xlsx: { icon: "fa fa-file", color: "#db9c5f" },
};

export class AuctionDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getFileIcon(url) {
    if (url) {
      let extension = url.split(/[#?]/)[0].split(".").pop().trim();
      return (
        ICONS[extension.toLowerCase()] || {
          icon: "fa fa-file-pdf-o",
          color: "#FF0000",
        }
      );
    }
    return { icon: "fa fa-file-pdf-o", color: "#FF0000" };
  }

  render() {
    let auction = this.props.auction;
    let lng = this.props.lng;
    let downloads = auction.downloads;
    let downloads_length =
      (auction && auction.downloads && auction.downloads.length) || 0;
    return (
      <React.Fragment>
        {downloads_length > 0 && (
          <div class="row mt-3 mb-3 documents">
            {downloads.map((item, i) => {
              let details = this.getFileIcon(item.file);
              return (
                <div class="col-12 mb-2">
                  <div class="bg-white rounded-4 border">
                    <div class="d-flex">
                      <div class=" flex-background rounded-2 p-2">
                        <i
                          className={details.icon}
                          style={{ color: details.color }}
                        />
                      </div>
                      <div class="p-2 w-100 p-1">
                        <h5 className="mb-0 fs-mob-8">
                          {i18n.t("auction_documents")}
                        </h5>
                        <span class="pt-1 d-flex text-gray">
                          {lng === "ar" && item.label_ar
                            ? item.label_ar
                            : item.label}
                        </span>
                      </div>
                      <div class="p-2 flex-shrink-1">
                        <div>
                          <a
                            class="btn button_colour mt-2 d-flex"
                            href={item.file}
                            target="_new"
                          >
                            <img
                              width="20"
                              height="20"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/material-symbols_download.png"
                              }
                              alt=""
                            />
                            <span class="ms-1 custom_text12">
                              {i18n.t("download")}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}
