import React from "react";
import { HomeBanner } from "../../components/home/homeBanner";
//import { AuctionListing } from "../../components/home/auctionListing";
//import { getLoggedUser } from "../../state/user";
// import { Testimonials } from "./testimonials";
import { BeingPart } from "./beignpart";
//import { Categories } from "./categories";
import { CategoryAuctions } from "./categoryAuctions";
//import { CharityAuctions } from "./charityAuctions";
import { CategorySearch } from "./helpers/categorySearch";
//import { ClientSection } from "./helpers/clientSection";
//import { Categories } from "./categories";
//import { Specials } from "./specials";
//import { PinnedMap } from "./pinnedMap";
//const user = getLoggedUser();
//let is_logged = localStorage.getItem("is_logged_in") ? true : false;
export class Homepage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    //let cordinates = this.state.cordinates;
    return (
      <div>
        {/* <HomeBanner /> */}
        {/*{!is_logged && <BeingPart />}
        {is_logged && <AuctionListing />} */}
        {/* <Categories /> */}
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        {/* <Categories /> */}
        <CategorySearch />
        <CategoryAuctions />
        {/* <CharityAuctions /> */}
        {/* <PinnedMap cordinates={cordinates} /> */}

        {/* <Specials /> */}
        {/* <ClientSection /> */}
        <BeingPart />
      </div>
    );
  }
}
