import React from "react";
import { AuctionRow } from "../home/helpers/auctionRow";
import i18n from "../../i18n";
import { getLikedAuctions } from "../../models/auctions";
import { SideBar } from "./sidebar";
import { Spinner } from "react-bootstrap";
import { getEnrolledGroups } from "../../models/groups";
import { HomeBanner } from "../home/homeBanner";
export class LikedAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      meta: {},
      groups: [],
      have_active_registrations: false,
      active_group: [],
      auctions: {},
      limit: 30,
      is_loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
    this.loadAllAuctions(30);
  }

  async loadAllGroups() {
    const response = await getEnrolledGroups();
    let groups = response.data;
    this.setState({ groups });
    let meta = response.meta;
    if (meta && meta.active_group) {
      let { input } = this.state;
      input.group = meta.active_group.id;
      this.setState({
        input: input,
      });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  }

  async loadAllAuctions(limit = "") {
    this.setState({ is_loading: true });
    let { input } = this.state;
    let q = "";
    if (limit) {
      q = "limit=" + limit;
    }
    if (input.group) {
      console.log(q);
      q = q ? q + `&group=${input.group}` : `?group=${input.group}`;
    }
    const response = await getLikedAuctions(q);
    if (response) {
      let auctions = response.data;
      this.setState({ auctions: auctions });
      this.setState({ is_loading: false });
    }
  }

  render() {
    let groups = this.state.groups;
    let auctions = this.state.auctions;
    let limit = this.state.limit;
    let lt_30_cls =
      limit === 30
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_50_cls =
      limit === 50
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_100_cls =
      limit === 100
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_200_cls =
      limit === 200
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_all_cls =
      limit === false
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let total_auctions = this.state.meta.total;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 dash-container">
                <div className="card mt-3">
                  <div className="card-header dash-container-head">
                    <h3 className="card-title">{i18n.t("liked_auctions")}</h3>
                  </div>
                  {auctions.length === 0 && !this.state.is_loading && (
                    <div className="card-body">
                      <div className="alert alert-danger">
                        {i18n.t("no_data_found")}
                      </div>
                    </div>
                  )}

                  <div className="card-body">
                    {/* <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-select select fs14 text-black"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">
                              --{i18n.t("auction_group")}--
                            </option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            class="btn btn-warning rounded-5 px-4"
                            onClick={() => {
                              this.loadAllAuctions(this.state.limit);
                            }}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div> */}

                    {this.state.is_loading && (
                      <div
                        className="text-center m-3 text-dark"
                        style={{ textAlign: "center" }}
                      >
                        <Spinner animation="border" />
                      </div>
                    )}

                    {auctions.length > 0 && !this.state.is_loading && (
                      <div className="row">
                        <div>
                          <div className="bg-white p-1 item2-gl-nav">
                            <div className="d-sm-flex">
                              <label
                                className="font-1vw"
                                style={{
                                  float: "left",
                                  width: "auto",
                                  margin: "10px 0px",
                                }}
                              >
                                {!this.state.limit && (
                                  <div style={{ marginRight: "5px" }}>
                                    {i18n.t("showing")} {i18n.t("all")}{" "}
                                    {i18n.t("entries")}
                                  </div>
                                )}
                                {this.state.limit && (
                                  <div style={{ marginRight: "5px" }}>
                                    {i18n.t("showing")} 1 {i18n.t("to")}{" "}
                                    {this.state.limit} {i18n.t("of")}{" "}
                                    {total_auctions} {i18n.t("entries")}{" "}
                                  </div>
                                )}
                              </label>
                              <button
                                className={lt_30_cls}
                                onClick={() => {
                                  this.setState({ limit: 30 });
                                  this.loadAllAuctions(30);
                                }}
                              >
                                30
                              </button>
                              <button
                                className={lt_50_cls}
                                onClick={() => {
                                  this.setState({ limit: 50 });
                                  this.loadAllAuctions(50);
                                }}
                              >
                                50
                              </button>
                              <button
                                className={lt_100_cls}
                                onClick={() => {
                                  this.setState({ limit: 100 });
                                  this.loadAllAuctions(100);
                                }}
                              >
                                100
                              </button>
                              <button
                                className={lt_200_cls}
                                onClick={() => {
                                  this.setState({ limit: 200 });
                                  this.loadAllAuctions(200);
                                }}
                              >
                                200
                              </button>
                              <button
                                className={lt_all_cls}
                                onClick={() => {
                                  this.setState({ limit: false });
                                  this.loadAllAuctions();
                                }}
                              >
                                {i18n.t("all")}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive auctions-list">
                          <div className="bg-white p-1 mb-9">
                            <div className="table-responsive-sm table-condensed">
                              {auctions.length > 0 && (
                                <table
                                  className="table table-sm table-striped font-1vw"
                                  style={{ fontSize: "0.9rem" }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        className="text-center font-75rem text-center"
                                        style={{ width: "50px" }}
                                      >
                                        {i18n.t("#")}
                                      </th>
                                      {/* <th className="font-75rem nowrap">
                                        {i18n.t("image")}
                                      </th> */}
                                      <th className="font-75rem">
                                        {i18n.t("auction_name")}
                                      </th>
                                      <th className="font-75rem">
                                        {i18n.t("client")}
                                      </th>
                                      <th className="font-75rem">
                                        {i18n.t("end_date")}
                                      </th>
                                      <th className="text-center d-none-mob font-75rem">
                                        {i18n.t("start_bid_amount")}
                                      </th>
                                      <th className="text-center font-75rem">
                                        {i18n.t("current_amount")}
                                      </th>
                                      <th className="font-75rem">
                                        {i18n.t("bid_count")}
                                      </th>
                                      <th
                                        className="font-75rem"
                                        style={{ width: "5rem" }}
                                      >
                                        {i18n.t("rank")}
                                      </th>
                                      <th style={{ width: "10rem" }}></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {auctions.map((item, i) => {
                                      let key = parseInt(i) + 1;
                                      return (
                                        <AuctionRow
                                          keys={key}
                                          auction={item}
                                          screen="dashboad"
                                        />
                                      );
                                    })}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
