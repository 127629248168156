import React from "react";
import i18n from "../../i18n";
import { Link, NavLink } from "react-router-dom";
import { getLoggedUser } from "../../state/user";
import { Clock } from "../helpers/clock";
import { getCategories } from "../../models/categories";
import { SidebarHomeMob } from "./sidebar-home-mob";

const user = getLoggedUser();
const roles = {
  A: i18n.t("admin"),
  C: i18n.t("client"),
  CE: i18n.t("ceo"),
  GM: i18n.t("gm"),
  F: i18n.t("finance"),
  HSE: i18n.t("hse"),
  M: i18n.t("manager"),
  PM: "PM",
  OM: i18n.t("OM"),
  U: i18n.t("bidder"),
};

const changeLanguage = (ln) => {
  i18n.changeLanguage(ln);
  window.location.reload();
};

function logout() {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("token");
  localStorage.removeItem("mzad_user_info");
  window.location.href = "/";
}

export class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    this.loadAllCategories();
  }
  async loadAllCategories() {
    const response = await getCategories();
    let categories = response.data;
    this.setState({ categories });
  }
  render() {
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let { wallet_amount, is_loading, is_logged_in } = this.props;
    return (
      <React.Fragment>
        <div className="header-main">
          <div className="logo-div">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo-org.png"}
                alt="mzadcom-logo"
                width="130"
              />
            </Link>
          </div>
          <div className="header-nav-menu">
            <ul className="nav-list">
              <li>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/menu-home-icon.png"
                  }
                  alt="home"
                />
                <Link to="/">{i18n.t("home")}</Link>
              </li>
              <li>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/menu-who-we-are-icon.png"
                  }
                  alt="who-we-are"
                />
                <Link to="/about">{i18n.t("whoweare")}</Link>
              </li>
              <li>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/menu-auction-icon.png"
                  }
                  alt="auction-calendar"
                />
                <Link to="/auc-calendar">{i18n.t("auction_calendar")}</Link>
              </li>
              <li>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/menu-help-icon.png"
                  }
                  alt="help"
                />
                <Link to="/contact">{i18n.t("contactus")}</Link>
              </li>
            </ul>
          </div>
          <div className="header-buttons">
            <div className="header-date-lang">
              {is_logged_in && (
                <Link
                  to="/dashboard"
                  className="btn btn-outline-danger text-danger"
                >
                  <i className="fa fa-dashboard"></i>&nbsp;
                  <span>{i18n.t("dashboard")}</span>
                </Link>
              )}
              <div className="noti-guest">
                <div className="notification">
                  <Link to="/notifications" className="">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/bell-icon.png"
                      }
                      alt="notification"
                    />
                  </Link>
                </div>
                <button className="nav-btn welcome-staff btn border-0">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/welcome-staff-icon.png"
                    }
                    alt="staff"
                  />
                  <span style={{ width: "141px" }}>
                    {i18n.t("welcome")}{" "}
                    {user && user.name ? user.name : i18n.t("guest")}
                  </span>
                </button>
                {is_logged_in && (
                  <Link to="/dashboard" className="nav-btn btn border-0">
                    <span>
                      <i className="fa fa-id-badge" />
                      &nbsp;
                      {i18n.t("role")} :{" "}
                      {user && user.role ? roles[user.role] : "Guest"}
                    </span>
                  </Link>
                )}
              </div>
              <div className="header-date mt-1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/clock.png"}
                  alt="country"
                  width="15"
                />
                <span>
                  <Clock lang={current_lng} />
                </span>
              </div>

              <div className="header-lang" style={{ cursor: "pointer" }}>
                {/* <img src={process.env.PUBLIC_URL + "/assets/images/eng-icon.png"} alt="lang" />
                <span>EN</span> */}
                {current_lng === "ar" && (
                  <a href={true} onClick={(e) => changeLanguage("en")}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/flags/en.svg"
                      }
                      height="20"
                      alt=""
                    />{" "}
                    EN{" "}
                  </a>
                )}
                {current_lng === "en" && (
                  <a href={true} onClick={(e) => changeLanguage("ar")}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/flags/ar.svg"
                      }
                      height="20"
                      alt=""
                    />{" "}
                    عربي{" "}
                  </a>
                )}
              </div>
            </div>
            {/* <div className="country-notif-guest">
              <div className="country">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/oman-icon-sm.png"
                  }
                  alt="country"
                />
                <div className="country-name">
                  <span>Country</span>
                  <span>Oman</span>
                </div>
              </div> 
            </div> */}
            <div className="search-login-reg-div">
              {/* <div className="header-search">
                <form action="./auctions" method="GET">
                  <input type="hidden" name="sec" value="main" />
                  <input
                    type="text"
                    name="text"
                    placeholder={i18n.t("search")}
                  />
                  <button type="submit" className="header-search-btn">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/search-trans-icon.png"
                      }
                      alt="search"
                      width="30"
                    />
                  </button>
                </form>
              </div> */}
              {is_logged_in && (
                <NavLink
                  exact="true"
                  to={true}
                  onClick={logout}
                  className="login-btn"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/offcanvas-logout.png"
                    }
                    alt="logout"
                    width="15"
                    style={{ marginRight: "10px" }}
                  />
                  <span>{i18n.t("logout")}</span>
                </NavLink>
              )}
              {!is_logged_in && (
                <NavLink className="login-btn" exact="true" to="/login">
                  <span className="mdi--login"></span>
                  <span>{i18n.t("login")}</span>
                </NavLink>
              )}
              {!is_logged_in && (
                <NavLink
                  className="reg-btn"
                  exact="true"
                  to="/comp-registration"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/register-icon.png"
                    }
                    alt="register"
                  />
                  <span>{i18n.t("registration")}</span>
                </NavLink>
              )}
              {is_logged_in && (
                <React.Fragment>
                  <Link to="/wallet" className="nav-btn wallet-btn color-2">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/wallet-icon.png"
                      }
                      alt="wallet"
                    />
                    <span>
                      {is_loading && <span>..</span>}
                      {!is_loading && (
                        <span>
                          {i18n.t("wallet")} : {i18n.t("omr")} {wallet_amount}
                        </span>
                      )}
                    </span>
                  </Link>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="header-tab-mobile">
          <div className="date-country-lang mb-0">
            <div className="header-date">
              <Link to="/">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/oman-icon-big.png"
                  }
                  alt="country"
                  width="15"
                />
              </Link>
              &nbsp;
              <span>
                <Clock lang={current_lng} />
              </span>
            </div>
            {/* <div className="country">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/oman-icon-sm.png"}
                alt="country"
              />
              <div className="country-name">
                <span>Country</span>
                <span>Oman</span>
              </div>
            </div> */}
            {/* <div className="header-lang">
              {current_lng === "ar" && (
                <a href={true} onClick={(e) => changeLanguage("en")}>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/flags/en.svg"}
                    height="20"
                    alt=""
                  />{" "}
                  EN{" "}
                </a>
              )}
              {current_lng === "en" && (
                <a href={true} onClick={(e) => changeLanguage("ar")}>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/flags/ar.svg"}
                    height="20"
                    alt=""
                  />{" "}
                  عربي{" "}
                </a>
              )}
            </div> */}
          </div>

          <div className="logo-noti-menu">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo-org.png"}
                alt=""
                width="70"
              />
            </Link>
            <div className="noti-menu">
              <Link to="/notifications" className="">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/bell-icon.png"}
                  alt="notification"
                />
              </Link>
              <div className="dropdown">
                <button className="dropbtn">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/burger-menu-black.png"
                    }
                    alt="burger-menu"
                  />
                </button>
                <div className="dropdown-content">
                  <div className="dropdown-items">
                    <div className="header-search">
                      <input type="text" placeholder={i18n.t("search")} />
                      <button className="header-search-btn">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/search-trans-icon.png"
                          }
                          alt="search"
                          width="30"
                        />
                      </button>
                    </div>
                    <div className="mobile-welcome-guest-role">
                      <button className="nav-btn welcome-staff">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/welcome-staff-icon.png"
                          }
                          alt="staff"
                        />
                        <span>
                          {i18n.t("welcome")}{" "}
                          {user && user.name ? user.name : i18n.t("guest")}
                        </span>
                      </button>
                      <button className="nav-btn">
                        {i18n.t("role")}:{" "}
                        <span>
                          {user && user.role ? roles[user.role] : "Guest"}
                        </span>
                      </button>
                    </div>
                    <div className="header-nav-menu">
                      <ul className="nav-list">
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/menu-home-icon.png"
                            }
                            alt="home"
                          />
                          <a href={false}>{i18n.t("home")}</a>
                        </li>
                        {is_logged_in && (
                          <li>
                            <Link to="/dashboard" className="text-danger">
                              <span>{i18n.t("dashboard")}</span>
                            </Link>
                          </li>
                        )}
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/menu-who-we-are-icon.png"
                            }
                            alt="who-we-are"
                          />
                          <a href={false}>{i18n.t("whoweare")}</a>
                        </li>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/menu-auction-icon.png"
                            }
                            alt="auction-calendar"
                          />
                          <a href={false}>{i18n.t("auction_calendar")}</a>
                        </li>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/menu-help-icon.png"
                            }
                            alt="help"
                          />
                          <a href={false}>{i18n.t("contactus")}</a>
                        </li>
                      </ul>
                    </div>

                    {!is_logged_in && (
                      <div className="mobile-login-reg">
                        <NavLink
                          exact="true"
                          to="/login"
                          className="login-btn"
                          style={{ borderRadius: "0" }}
                          onClick={(event) => {
                            this.hideSideBar();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/login-icon.png"
                            }
                            alt="login"
                          />
                          <span>{i18n.t("login")}</span>
                        </NavLink>

                        <NavLink
                          className="reg-btn"
                          exact="true"
                          to="/comp-registration"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/register-icon.png"
                            }
                            alt="register"
                          />
                          <span>{i18n.t("registration")}</span>
                        </NavLink>
                      </div>
                    )}

                    {is_logged_in && (
                      <div className="mobile-wallet">
                        <Link to="/wallet" className="nav-btn wallet-btn">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/wallet-icon.png"
                            }
                            alt="wallet"
                          />
                          <span>
                            {is_loading && <span>..</span>}
                            {!is_loading && (
                              <span>
                                {i18n.t("wallet")} : {i18n.t("omr")}{" "}
                                {wallet_amount}
                              </span>
                            )}
                          </span>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <SidebarHomeMob {...this.props} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/*
<div className="header-main" id="main-top-header">
<div className="horizontal-header clearfix">
  <div className="container">
    <a
      id="horizontal-navtoggle"
      className="animated-arrow"
      href={true}
    >
      <span />
    </a>
    <span className="smllogo">
      <Link to="/" className="logo-responsive">
        <img src={logo} alt="" className="logo-img-white" />
      </Link>
    </span>
  </div>
</div>
<div className="horizontal-main bg-dark-transparent clearfix">
  <div className="horizontal-mainwrapper container clearfix">
    <div className="topbar-custom-items">
      <div className="btn-custom-1 mb-2 d-mob-none">
        <span>
          {i18n.t("welcome")}{" "}
          {user && user.name ? user.name : i18n.t("guest")}
        </span>
      </div>

      <Link
        to={is_logged_in ? "/wallet" : "/login"}
        className="btn-custom-1 d-mob-none"
      >
        <span>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/wallet.jpg"}
            alt=""
            style={{ width: "20px" }}
          />{" "}
          {i18n.t("wallet")} :{" "}
          {this.state.is_loading && <span>..</span>}
          {!this.state.is_loading && (
            <span>
              {i18n.t("omr")} {this.state.wallet_amount}
            </span>
          )}
        </span>
      </Link>
    </div>

    <nav className="horizontalMenu clearfix d-md-flex">
      <ul className="horizontalMenu-list">
        <li className="show-mob">
          <a
            className="menu-close-icon"
            href={true}
            onClick={(event) => {
              this.hideSideBar();
            }}
          >
            <i className="fa fa-times" />
          </a>
        </li>
        <li
          style={{
            padding: current_lng === "ar" ? "0.5rem 0" : "1.5rem 0",
          }}
        >
          <Link to="/" style={{ textAlign: "center" }}>
            <img src={logo} alt="" className="logo-img-white" />
          </Link>
        </li>
        <li className="mob-avtr-area show-mob">
          <span>
            {i18n.t("welcome")}{" "}
            {user && user.name ? user.name : i18n.t("guest")}
          </span>
        </li>
        <li>
          {is_logged_in && (
            <NavLink
              exact="true"
              to={true}
              className="border-end"
              onClick={logout}
            >
              <span style={{ fontSize: "1.3rem" }}>
                <i
                  className="fa fa-sign-out me-1"
                  style={{
                    fontSize: "1.3rem",
                    margin: "0",
                    padding: "0",
                  }}
                />
              </span>
              <span style={{ padding: "0px 15px" }}>
                {i18n.t("logout")}
              </span>
            </NavLink>
          )}
          {!is_logged_in && (
            <NavLink
              exact="true"
              to="/login"
              className="border-end"
              style={{ borderRadius: "0" }}
              onClick={(event) => {
                this.hideSideBar();
              }}
            >
              <span>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/icons/login.png"
                  }
                  style={{ width: "25px", height: "25px" }}
                  alt=""
                />
              </span>
              <span style={{ padding: "0px 15px" }}>
                {i18n.t("login")}
              </span>
            </NavLink>
          )}
        </li>
        {is_logged_in && (
          <li>
            <NavLink
              exact="true"
              to="/dashboard"
              className="border-end"
            >
              <span style={{ fontSize: "1.3rem" }}>
                <i
                  className="fa fa-home me-1"
                  style={{
                    fontSize: "1.3rem",
                    margin: "0",
                    padding: "0",
                  }}
                />
              </span>
              <span style={{ padding: "0px 15px" }}>
                {i18n.t("dashboard")}
              </span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            exact="true"
            to="/"
            activeClassName="active"
            onClick={(event) => {
              this.hideSideBar();
            }}
          >
            {i18n.t("home")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/auctions"
            activeClassName="active"
            onClick={(event) => {
              this.hideSideBar();
            }}
          >
            {i18n.t("auctions")}{" "}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            activeClassName="active"
            onClick={(event) => {
              this.hideSideBar();
            }}
          >
            {i18n.t("aboutus")}{" "}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            activeClassName="active"
            onClick={(event) => {
              this.hideSideBar();
            }}
          >
            {i18n.t("contactus")}{" "}
          </NavLink>
        </li>

        {is_logged_in && (
          <li className="show-mob">
            <NavLink to="/wallet">
              <span>{i18n.t("wallet")}</span>
            </NavLink>
          </li>
        )}

        <li className="show-mob">
          {current_lng === "ar" && (
            <a href={true} onClick={(e) => changeLanguage("en")}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/flags/en.svg"
                }
                height="20"
                alt=""
              />{" "}
              English{" "}
            </a>
          )}
          {current_lng === "en" && (
            <a href={true} onClick={(e) => changeLanguage("ar")}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/flags/ar.svg"
                }
                height="20"
                alt=""
              />{" "}
              عربي{" "}
            </a>
          )}
        </li>
        <li className="show-mob">
          {user && user.id && (
            <div className="text-danger text-justify p-3">
              <div className="">{i18n.t("mzadcom_id")} : {user.id}</div>
              <div className="">
                Role :{" "}
                {user && user.role ? roles[user.role] : "Client"}
              </div>
            </div>
          )}
        </li>
      </ul>
    </nav>
  </div>
</div>
</div>
*/
