import React from "react";
import MapPicker from "react-google-map-picker";

export class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultLocation: props.defaultLocation,
      zoom: 10,
      lat: 23.588,
      lng: 58.3829,
      start: false,
    };
    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.handleChangeZoom = this.handleChangeZoom.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ start: true });
    }, 3000);
  }

  handleChangeLocation(lat, lng) {
    this.setState({ lat: lat, lng: lng });
    this.sendLocationToParent();
  }

  sendLocationToParent() {
    let lat = this.state.lat;
    let lng = this.state.lng;
    console.log(lat, lng);
    this.props.getLocation(lat, lng);
  }

  handleChangeZoom(newZoom) {
    this.setState({ zoom: newZoom });
  }

  render() {
    //console.log("Map", this.state.defaultLocation);
    return (
      <React.Fragment>
        <div id="map">
          {this.state.defaultLocation && this.state.start && (
            <MapPicker
              id="map"
              defaultLocation={this.state.defaultLocation}
              zoom={this.state.zoom}
              mapTypeId="roadmap"
              style={{ height: "50vh" }}
              onChangeLocation={this.handleChangeLocation}
              onChangeZoom={(event) => {
                this.handleChangeZoom(event);
              }}
              apiKey="AIzaSyCtGIYq4ASmgZhxWY8VadcPd5XXXSofK5Q"
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

//   const DefaultLocation = { lat: 23.588, lng: 58.3829 };
// const DefaultZoom = 10;

// const App = () => {
//   const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

//   const [location, setLocation] = useState(defaultLocation);
//   const [zoom, setZoom] = useState(DefaultZoom);

//   function handleChangeLocation(lat, lng) {
//     setLocation({ lat: lat, lng: lng });
//   }

//   function handleChangeZoom(newZoom) {
//     setZoom(newZoom);
//   }

//     function handleResetLocation() {
//       setDefaultLocation({ ...DefaultLocation });
//       setZoom(DefaultZoom);
//     }

//   return (
//     <div>
//        <button onClick={handleResetLocation}>Reset Location</button>
//       <label>Latitute:</label>
//       <input type="text" value={location.lat} disabled />
//       <label>Longitute:</label>
//       <input type="text" value={location.lng} disabled />
//       <label>Zoom:</label>
//       <input type="text" value={zoom} disabled />
//       <MapPicker
//         defaultLocation={defaultLocation}
//         zoom={zoom}
//         mapTypeId="roadmap"
//         style={{ height: "40vh" }}
//         onChangeLocation={handleChangeLocation}
//         onChangeZoom={handleChangeZoom}
//         apiKey="AIzaSyArsLUNJoAtcrca3TNfdfjhmZrT6FBeNfI"
//       />
//     </div>
//   );
// };

// export default App;
