import swal from "sweetalert";
import { thawaniUrl, thawaniConfig } from "../constants/global";
import axios from "axios";

async function createThawaniCustomer(payload) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.post(
      thawaniUrl + "customers",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function createThawaniSession(payload) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.post(
      thawaniUrl + "checkout/session",
      payload,
      config
    );
    return response;
  } catch (error) {
    /**
     * `Some errors occured. The field UnitAmount must be between 1 and ${
        5000000 / 1000
      }`,
     */
    swal(
      "Error",
      `Some errors occured. The field Amount must be between 1 and ${
        5000000 / 1000
      }`,
      "error"
    );
  }
}

async function getPaymentsFromThawani(reference) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.get(
      thawaniUrl + "payments?limit=1&skip=0&checkout_invoice=" + reference,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function createRefund(payload) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.post(
      thawaniUrl + "refunds",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getPaymentMethods(id) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.get(
      thawaniUrl + `payment_methods?customer_id=${id}`,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function deleteSavedCard(id) {
  try {
    let config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.delete(
      thawaniUrl + `payment_methods/${id}`,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function createIntent(payload) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.post(
      thawaniUrl + "payment_intents",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function confirmIntent(pid) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.post(
      thawaniUrl + `payment_intents/${pid}/confirm`,
      {},
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function retrieveSession(id) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.get(
      thawaniUrl + `checkout/session/${id}`,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function retrieveSessionByClientRef(id) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.get(
      thawaniUrl + `checkout/reference/${id}`,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function retrieveIntent(id) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.get(
      thawaniUrl + `payment_intents/${id}`,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export {
  createThawaniCustomer,
  createThawaniSession,
  getPaymentsFromThawani,
  createRefund,
  getPaymentMethods,
  deleteSavedCard,
  createIntent,
  confirmIntent,
  retrieveSession,
  retrieveSessionByClientRef,
  retrieveIntent,
};
