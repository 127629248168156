import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const user = getLoggedUser();

async function getDashboardData(payload) {
  return fetch(apiUrl + "dashboard", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getDashboardAuctionsList(payload) {
  return fetch(apiUrl + "dashboard_auc_list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getLiveActivity(payload) {
  return fetch(apiUrl + "live_activities", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

export { getDashboardData, getLiveActivity, getDashboardAuctionsList };
