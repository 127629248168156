import { apiUrl } from "../constants/global";

var header = {
  "Content-Type": "application/json",
};
async function getServerTime() {
  return fetch(apiUrl + "server-time", {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

export default getServerTime;
