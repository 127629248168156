import React from "react";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export class WalletPaymentCancelled extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">Payment Cancelled</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      <Link exact="true" to="/wallet-manage">
                        {i18n.t("wallet")}
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="container text-center">
              <div className="display-1 mb-5"></div>
              <h1 className="h2 mb-3">Payment Cancelled</h1>
              <p className="h4 font-weight-Automatic mb-8 leading-Automatic">
                Oops!!!! Your payment process is cancelled
              </p>
              <Link className="btn btn-warning" to="/">
                {i18n.t("back")}
              </Link>
              <p className="h4 font-weight-Automatic mb-8 leading-Automatic">
                Plase try once again with enrolling
              </p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
