import React from "react";
import i18n from "../../../i18n";
//import { Link } from "react-router-dom";

const locale = localStorage.getItem("I18N_LANGUAGE");

export class TrackItem extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let { item, index } = this.props;
    let {
      auction_id,
      auction_title,
      auction_title_ar,
      site_visit,
      registration,
      auction_start,
      auction_end,
      client_approval,
      client_payment,
      completed,
    } = item;
    return (
      <React.Fragment>
        <div className="track-item">
          <h5>
            {index + 1}.{locale === "ar" ? auction_title_ar : auction_title} (
            {i18n.t("ID")} : {auction_id})
          </h5>

          <div className="row">
            <div className="col overflow-scroll">
              <div
                className="timeline-steps aos-init aos-animate p-1"
                data-aos="fade-up"
              >
                <div className="timeline-step">
                  <div
                    className="timeline-content"
                    data-toggle="popover"
                    data-trigger="hover"
                    data-placement="top"
                    title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2003"
                  >
                    <div
                      className={
                        site_visit.status === "A"
                          ? "inner-circle active"
                          : "inner-circle"
                      }
                    ></div>
                    <p className="h6 mt-3 mb-1 item-head">
                      {i18n.t("site_visit")}
                    </p>
                    <p className="h6 text-muted mb-0 mb-lg-0 item-date">
                      {site_visit.date || ""}
                    </p>
                  </div>
                </div>
                <div className="timeline-step">
                  <div
                    className="timeline-content"
                    data-toggle="popover"
                    data-trigger="hover"
                    data-placement="top"
                    title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2004"
                  >
                    <div
                      className={
                        registration.status === "A"
                          ? "inner-circle active"
                          : "inner-circle"
                      }
                    ></div>
                    <p className="h6 mt-3 mb-1 item-head">
                      {i18n.t("registration")}
                    </p>
                    <p className="h6 text-muted mb-0 mb-lg-0 item-date">
                      {registration.date || ""}
                    </p>
                  </div>
                </div>
                <div className="timeline-step">
                  <div
                    className="timeline-content"
                    data-toggle="popover"
                    data-trigger="hover"
                    data-placement="top"
                    title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2005"
                  >
                    <div
                      className={
                        auction_start.status === "A"
                          ? "inner-circle active"
                          : "inner-circle"
                      }
                    ></div>
                    <p className="h6 mt-3 mb-1 item-head">
                      {i18n.t("auction_start")}
                    </p>
                    <p className="h6 text-muted mb-0 mb-lg-0 item-date">
                      {auction_start.date || ""}
                    </p>
                  </div>
                </div>
                <div className="timeline-step">
                  <div
                    className="timeline-content"
                    data-toggle="popover"
                    data-trigger="hover"
                    data-placement="top"
                    title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2010"
                  >
                    <div
                      className={
                        auction_end.status === "A"
                          ? "inner-circle active"
                          : "inner-circle"
                      }
                    ></div>
                    <p className="h6 mt-3 mb-1 item-head">
                      {i18n.t("auction_end")}
                    </p>
                    <p className="h6 text-muted mb-0 mb-lg-0 item-date">
                      {auction_end.date || ""}
                    </p>
                  </div>
                </div>
                <div className="timeline-step">
                  <div
                    className="timeline-content"
                    data-toggle="popover"
                    data-trigger="hover"
                    data-placement="top"
                    title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2010"
                  >
                    <div
                      className={
                        client_approval.status === "A"
                          ? "inner-circle active"
                          : "inner-circle"
                      }
                    ></div>
                    <p className="h6 mt-3 mb-1 item-head">
                      {i18n.t("client_approval")}
                    </p>
                    <p className="h6 text-muted mb-0 mb-lg-0 item-date">
                      {client_approval.date || ""}
                    </p>
                  </div>
                </div>
                <div className="timeline-step mb-0">
                  <div
                    className="timeline-content"
                    data-toggle="popover"
                    data-trigger="hover"
                    data-placement="top"
                    title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2020"
                  >
                    <div
                      className={
                        client_payment.status === "A"
                          ? "inner-circle active"
                          : "inner-circle"
                      }
                    ></div>
                    <p className="h6 mt-3 mb-1 item-head">
                      {i18n.t("client_payment")}
                    </p>
                    <p className="h6 text-muted mb-0 mb-lg-0 item-date">
                      {client_payment.date || ""}
                    </p>
                  </div>
                </div>
                <div className="timeline-step mb-0">
                  <div
                    className="timeline-content"
                    data-toggle="popover"
                    data-trigger="hover"
                    data-placement="top"
                    title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2020"
                  >
                    <div
                      className={
                        completed.status === "A"
                          ? "inner-circle active"
                          : "inner-circle"
                      }
                    ></div>
                    <p className="h6 mt-3 mb-1 item-head">
                      {i18n.t("completed")}
                    </p>
                    <p className="h6 text-muted mb-0 mb-lg-0 item-date">
                      {completed.date || ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
