import React from "react";
import i18n from "../../i18n";
import { HomeBanner } from "../home/homeBanner";
import getAboutCounts from "../../models/about";

let lng = localStorage.getItem("I18N_LANGUAGE");

export class About extends React.Component {
  constructor() {
    super();
    this.state = {
      active_buyers: 0,
      active_clients: 0,
      lots_sold: 0,
      auctions: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCounts();
  }

  async getCounts() {
    let response = await getAboutCounts();
    if (response.success) {
      let { data } = response;
      this.setState({
        active_buyers: data.active_buyers,
        active_clients: data.active_clients,
        lots_sold: data.lots_sold,
        auctions: data.auctions,
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container bg-white" id="whoweare">
          <HomeBanner callbackForSupport={this.props.callbackForSupport} />
          <div class="container mt-5 pb-5">
            <div class="row">
              <div class="col-md-6">
                <div class="">
                  <div class="oval">
                    <span>{i18n.t("whoweare")}</span>
                  </div>
                </div>
                <div class="align_justify">
                  <div class="who_are_we mt-3">
                    {/* <span>{i18n.t("aboutUsPara2")}</span>
                    <span>{i18n.t("aboutUsPara3")}</span>
                    <span>{i18n.t("aboutUsPara4")}</span> */}
                    <span>{i18n.t("who_we_are_text")}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="align_center">
                  <img
                    src="assets/images/Rectangle 737.png"
                    class="img_size"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="story_num">
                <div class="group_img py-3 px-2">
                  <div>
                    <img src="assets/images/Group 633199.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/Group 633199.png" alt="" />
                  </div>
                </div>
                <div class="centerr">
                  <span>{i18n.t("our_story_in_number")}</span>
                </div>
                <div class="centerr">
                  <span class="our_numbers">{i18n.t("our_numbers")}</span>
                </div>
                <div></div>
                <div></div>
                <div class="group_image_sub py-5">
                  <div class="centerr desktop">
                    <span>
                      <b class="fs-2 numbers">{this.state.active_buyers}</b>
                    </span>
                    <br></br>
                    <span class="numbers">{i18n.t("active_buyers")}</span>
                  </div>
                  <div class="centerr">
                    <span>
                      <b class="fs-2 numbers">{this.state.active_clients}</b>
                    </span>
                    <br></br>
                    <span class="numbers">{i18n.t("active_clients")}</span>
                  </div>
                  <div class="centerr">
                    <span>
                      <b class="fs-2 numbers">{this.state.lots_sold}</b>
                    </span>
                    <br></br>
                    <span class="numbers">{i18n.t("lots_sold")}</span>
                  </div>
                  <div class="centerr">
                    <span>
                      <b class="fs-2 numbers">{this.state.auctions}</b>
                    </span>
                    <br></br>
                    <span class="numbers">{i18n.t("auctions")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 pt-3">
              <div class="col-md-6">
                <div class="oval">
                  <span>{i18n.t("our_mission")}</span>
                </div>
                <div class="who_are_we align_justify mt-3">
                  <span>{i18n.t("our_mission_txt")}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="oval">
                  <span>{i18n.t("our_vision")}</span>
                </div>
                <div class="who_are_we align_justify mt-3">
                  <span>{i18n.t("our_vision_txt")}</span>
                </div>
              </div>
            </div>
            <div class="row mt-5 pt-5">
              <div class="centerr">
                <h1>
                  <b>{i18n.t("offer_best_services")}</b>
                </h1>
              </div>
              <div class="card_flex mt-5">
                <div class="card">
                  <div class="card-body">
                    <img
                      src="assets/images/ri_auction-fill_blue.png"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <div class="mt-2">
                      <span class="card_text">{i18n.t("online_auction")}</span>
                    </div>
                    <div class="mt-4">
                      <span class="card_text_small">
                        {i18n.t("online_auction_text")}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <img
                      src="assets/images/maki_waste-basket.png"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <div class="mt-2">
                      <span class="card_text">
                        {i18n.t("waste_management")}
                      </span>
                    </div>
                    <div class="mt-4">
                      <span class="card_text_small">
                        {i18n.t("waste_mgmt_text")}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <img
                      src="assets/images/mdi_recycle.png"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <div class="mt-2">
                      <span class="card_text">{i18n.t("recycling")}</span>
                    </div>
                    <div class="mt-4">
                      <span class="card_text_small">
                        {i18n.t("recycling_text")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-4 mt-5">
              <div class="flex_center">
                <div class="oval">
                  <span>{i18n.t("our_team")}</span>
                </div>
              </div>
              <div class="card_flex card_flex_secondry mt-5">
                <div class="cards  d-flex flex-column rounded-4 bg-light">
                  <img
                    src="assets/images/about/chairman.jpg"
                    height="214"
                    width="288"
                    class="card-img-top rounded-4"
                    alt=""
                  />
                  <div class="card-bodys">
                    <span class="card-text">{i18n.t("suleym_junaibi")}</span>
                    <br></br>
                    <span class="card-text_sub">{i18n.t("chairman")}</span>
                  </div>
                </div>
                <div class="cards rounded-4 bg-light">
                  <img
                    src="assets/images/about/ceo.jpg"
                    height="214"
                    width="288"
                    class="card-img-top rounded-4"
                    alt=""
                  />
                  <div class="card-bodys">
                    <span class="card-text">{i18n.t("ibrahim_junaibi")}</span>
                    <br></br>
                    <span class="card-text_sub">{i18n.t("ceo")}</span>
                  </div>
                </div>
                <div class="cards rounded-4 bg-bg-white">
                  <img
                    src="assets/images/about/gm.png"
                    height="214"
                    width="288"
                    class="card-img-top"
                    alt=""
                  />
                  <div class="card-bodys bg-light">
                    <span class="card-text">{i18n.t("ahmed_alsaadi")}</span>
                    <br></br>
                    <span class="card-text_sub">{i18n.t("gm")}</span>
                  </div>
                </div>
              </div>
              {/* <div class="card_flex card_flex_secondry mt-5">
                <div class="cards">
                  <img
                    src="assets/images/image 44.png"
                    height="214"
                    width="288"
                    class="card-img-top"
                    alt=""
                  />
                  <div class="card-bodys">
                    <span class="card-text">Ahmed</span>
                    <br></br>
                    <span class="card-text_sub">Member</span>
                  </div>
                </div>
                <div class="cards">
                  <img
                    src="assets/images/image 40.png"
                    height="214"
                    width="288"
                    class="card-img-top"
                    alt=""
                  />
                  <div class="card-bodys">
                    <span class="card-text">Ahmed kabeer</span>
                    <br></br>
                    <span class="card-text_sub">Member</span>
                  </div>
                </div>
                <div class="cards">
                  <img
                    src="assets/images/image 43.png"
                    height="214"
                    width="288"
                    class="card-img-top"
                    alt=""
                  />
                  <div class="card-bodys">
                    <span class="card-text">Mohammad Salman</span>
                    <br></br>
                    <span class="card-text_sub">Member</span>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div class="row pt-3 mt-3">
              <div class="centerr">
                <h2>{i18n.t("our_client_feedback")}</h2>
              </div>
              <div class="user_card_flex mt-3">
                <div class="user_cards p-3 ">
                  <div class="user_rate_flex">
                    <img src="assets/images/Rectangle 729.png" alt="" />
                    <div>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                    </div>
                  </div>
                  <div>
                    <div class="mt-1">
                      <span class="user_rate_name">Floyd Miles</span>
                    </div>
                    <div class="mt-1">
                      <span class="user_rate_name_body">
                        Amet minim mollit non deserunt ullamco est sit aliqua
                        dolor do amet sint. Velit officia consequat duis enim
                        velit mollit. Exercitation veniam consequat sunt nostrud
                        amet. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis
                        enim velit mollit. Exercitation veniam consequat sunt
                        nostrud amet.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="user_cards p-3 ">
                  <div class="user_rate_flex">
                    <img src="assets/images/Rectangle 729__.png" alt="" />
                    <div>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                    </div>
                  </div>
                  <div>
                    <div class="mt-1">
                      <span class="user_rate_name">Ronald Richards</span>
                    </div>
                    <div class="mt-1">
                      <span class="user_rate_name_body">
                        ullamco est sit aliqua dolor do amet sint. Velit officia
                        consequat duis enim velit mollit. Exercitation veniam
                        consequat sunt nostrud amet.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="user_cards p-3 ">
                  <div class="user_rate_flex">
                    <img src="assets/images/Rectangle 729___.png" alt="" />
                    <div>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                  </div>
                  <div>
                    <div class="mt-1">
                      <span class="user_rate_name">Savannah Nguyen</span>
                    </div>
                    <div class="mt-1">
                      <span class="user_rate_name_body">
                        Amet minim mollit non deserunt ullamco est sit aliqua
                        dolor do amet sint. Velit officia consequat duis enim
                        velit mollit. Exercitation veniam consequat sunt nostrud
                        amet. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis
                        enim velit mollit. Exercitation veniam consequat sunt
                        nostrud amet.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
