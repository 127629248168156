import React from "react";
import i18n from "../../i18n";
//import Countdown from "react-countdown";
//import { Link } from "react-router-dom";
//import i18n from "../../i18n";
//import { Modal } from "react-bootstrap";
//import { Spinner } from "react-bootstrap";
//import { BiddingModal } from "./biddingModal";

export class SliderContent extends React.Component {
  constructor() {
    super();
    this.state = {
      wishlist: false,
      like: false,
      open_popup: false,
      toggleArrow: false,
      bid_increment: 2000,
      current_bid_amount: 18000,
      user_bid_amount: 18000,
      left_button_disable: true,
      is_bid_sending: false,
      bid_place_message: "",
    };
    this.openBidPopup = this.openBidPopup.bind(this);
    this.incrementNumber = this.incrementNumber.bind(this);
    this.decrementNumber = this.decrementNumber.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.bidNow = this.bidNow.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ open_popup: false });
  }
  openBidPopup() {
    this.setState({ open_popup: true });
  }

  handleChange(event) {
    if (event.target.name === "bid_increment") {
      this.setCurrentBidAmount(this.state.bid_increment, event.target.value);
    }
    let state = this.state;
    state[event.target.name] = event.target.value;
    this.setState({ state });
  }

  setCurrentBidAmount(old_incr, new_incr) {
    let user_bid_amount = this.state.user_bid_amount;
    let current_amount = parseFloat(this.state.current_bid_amount);
    user_bid_amount = parseFloat(current_amount) + parseFloat(new_incr);
    this.setState({ user_bid_amount });
  }

  decrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) - parseFloat(bid_increment);
    let left_button_disable = false;
    let minAmount = parseFloat(current_amount) + parseFloat(bid_increment);
    if (user_bid_amount <= minAmount) {
      left_button_disable = true;
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      left_button_disable: left_button_disable,
    });
  }

  incrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) + parseFloat(bid_increment);
    let left_button_disable = true;
    if (user_bid_amount > current_amount) {
      left_button_disable = false;
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      left_button_disable: left_button_disable,
    });
  }

  bidNow() {
    this.setState({ is_bid_sending: true });
    setTimeout(() => {
      this.setState({
        is_bid_sending: false,
      });
      setTimeout(() => {
        this.setState({
          bid_place_message: i18n.t("success"),
        });
      }, 0);
    }, 5000);

    setTimeout(() => {
      this.setState({
        bid_place_message: "",
      });
    }, 6000);
  }

  render() {
    let mainClasses = "header-text d-md-flex bg-banner";
    if (this.props.index % 2 === 1) {
      mainClasses = "header-text d-md-flex bg-banner-alt";
    }

    //let isImage = this.props.img.split(".");

    return (
      <React.Fragment>
        <div className={mainClasses} key={this.props.key}>
          <img src={this.props.img} alt="" className="banner-image-cust" />
        </div>
      </React.Fragment>
    );

    // if (isImage[1] && isImage[1] === "jpg") {
    //   if (this.props.isAuction === false) {
    //     return (
    //       <React.Fragment>
    //         <div className={mainClasses} key={this.props.key}>
    //           {/* process.env.PUBLIC_URL + "/images/auctions/dummy/" + */}
    //           <img src={this.props.img} alt="" className="banner-image-cust" />
    //           {this.props.title && (
    //             <h2
    //               className="banner-btn banner-btn-title"
    //               style={{
    //                 left: "35%",
    //                 top: "35%",
    //                 width: "50%",
    //                 fontSize: "3rem",
    //               }}
    //             >
    //               {this.props.title}
    //             </h2>
    //           )}

    //           {this.props.show_store_buttos && (
    //             <div className="text-center banner-btn banner-apps-btn">
    //               <a href={true} className="btn bg-store fs-16">
    //                 <i className="fa fa-apple me-2"></i> App Store
    //               </a>
    //               <a href={true} className="btn bg-store fs-16">
    //                 <i className="fa fa-android me-2"></i> Google Play
    //               </a>
    //             </div>
    //           )}
    //         </div>
    //       </React.Fragment>
    //     );
    //   } else {
    //     return (
    //       <React.Fragment>
    //         <div className={mainClasses} key={this.props.key}>
    //           <img
    //             src={
    //               process.env.PUBLIC_URL +
    //               "/images/auctions/dummy/" +
    //               this.props.img
    //             }
    //             alt=""
    //             className="banner-image-cust"
    //           />
    //           <div
    //             className="text-center banner-btn-title banner-btn"
    //             style={{
    //               left: "57%",
    //               top: "30%",
    //             }}
    //           >
    //             <h2
    //               style={{
    //                 fontSize: "3rem",
    //               }}
    //             >
    //               {this.props.title}
    //             </h2>
    //           </div>
    //           <div className="text-center banner-btn banner-details-btn">
    //             <br />

    //             <Link
    //               to={"/auction-details/" + this.props.auctionId}
    //               className="btn btn-warning btn-md banner-btn-item"
    //               style={{ background: "#ff881959 !important" }}
    //             >
    //               <i className="fa fa-info-circle"></i> {i18n.t("view_details")}
    //             </Link>

    //             {/* <button
    //               className="btn btn-warning banner-btn-item"
    //               onClick={() => {
    //                 this.setState({ open_popup: true });
    //               }}
    //             >
    //               <i className="fa fa-gavel"></i> {i18n.t("bid_now")}
    //             </button> */}
    //           </div>
    //         </div>
    //         <BiddingModal
    //           open_popup={this.state.open_popup}
    //           closeModal={this.closeModal}
    //         />
    //       </React.Fragment>
    //     );
    //   }
    // } else {
    //   return (
    //     <React.Fragment>
    //       <div className={mainClasses} key={this.props.key}>
    //         <video
    //           width="100%"
    //           height="500"
    //           controls
    //           muted={true}
    //           autoPlay={true}
    //         >
    //           <source
    //             src={
    //               process.env.PUBLIC_URL +
    //               "/images/auctions/dummy/" +
    //               this.props.img
    //             }
    //             type={"video/mp4"}
    //           />
    //         </video>
    //       </div>
    //     </React.Fragment>
    //   );
    // }
  }
}
