import React, { Component } from "react";

class Pagination extends Component {
  render() {
    const { currentPage, totalPages, onPageChange } = this.props;
    const maxPagesToShow = 5; // Maximum number of pages to display

    // Generate page numbers
    const pages = [];
    let startPage = 1;
    let endPage = totalPages;

    // Adjust startPage and endPage if there are too many pages
    if (totalPages > maxPagesToShow) {
      const halfMaxPages = Math.floor(maxPagesToShow / 2);
      startPage = Math.max(currentPage - halfMaxPages, 1);
      endPage = Math.min(currentPage + halfMaxPages, totalPages);

      if (endPage - startPage < maxPagesToShow - 1) {
        if (currentPage <= halfMaxPages) {
          endPage = maxPagesToShow;
        } else if (currentPage >= totalPages - halfMaxPages) {
          startPage = totalPages - maxPagesToShow + 1;
        }
      }
    }

    // Add ellipsis at the beginning if necessary
    if (startPage > 1) {
      pages.push(
        <li key="start-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    // Generate page numbers within the range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <a href={false} className="page-link" onClick={() => onPageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    // Add ellipsis at the end if necessary
    if (endPage < totalPages) {
      pages.push(
        <li key="end-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    return (
      <nav aria-label="" className="d-flex justify-content-end mt-2">
        <ul className="pagination justify-content-center d-flex">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a
              href={false}
              className="page-link"
              onClick={() => onPageChange(currentPage - 1)}
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          {pages}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <a
              href={false}
              className="page-link"
              onClick={() => onPageChange(currentPage + 1)}
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Pagination;
