import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import i18n from "../../i18n";
import DataTable from "react-data-table-component";
import { getAllGroups, createGroup, deleteGroup } from "../../models/groups";
import { getUsersByRoles } from "../../models/users";
import { MzadLoader } from "../helpers/mzadLoader";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { HomeBanner } from "../home/homeBanner";
import FileInput from "../helpers/fileInput";
import { getAllOrganization } from "../../models/organization";
import ToggleButton from "react-toggle-button";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

const tooltip = (
  <Tooltip id="tooltip">
    Linked to an auction. <strong>Can't delete.</strong>
  </Tooltip>
);
const tooltip2 = <Tooltip id="tooltip">Delete</Tooltip>;

export class Groups extends React.Component {
  constructor() {
    super();
    this.state = {
      hideSidebar: false,
      is_loading: false,
      input: {
        is_extra_time: false,
        extra_time: 0,
      },
      totalRows: 0,
      currentPage: 1,
      list: {
        page: 1,
        limit: 15,
      },
      errors: {},
      groups: [],
      managers: [],
      hse_users: [],
      organizations: [],
      collapse: true,
      enquiries: [{ label: "", value: "", label_ar: "", value_ar: "" }],
      file_group_image: null,
      file_terms: null,
      file_payment_terms: null,
      columns: [
        {
          name: i18n.t("ID"),
          sortable: true,
          width: "70px",
          cell: (row) => row.id,
        },
        {
          name: "",
          width: "70px",
          cell: (row) => {
            return (
              <a href={row.image} target="_new">
                <img
                  src={row.image}
                  style={{ width: "auto", height: "30px" }}
                  alt=""
                />
              </a>
            );
          },
        },
        {
          name: i18n.t("client"),
          sortable: true,
          cell: (row) =>
            row.organization_info
              ? row.organization_info.organization_name
              : "",
        },
        {
          name: i18n.t("auctions"),
          width: "70px",
          cell: (row) => `(${row.total_auctons})`,
        },
        {
          name: i18n.t("group_name"),
          sortable: true,
          cell: (row) => row.group_name,
        },
        {
          name: `${i18n.t("group_name")}(${i18n.t("AR")})`,
          sortable: true,
          cell: (row) => row.group_name_ar,
        },
        {
          name: i18n.t("reg_start_date"),
          sortable: true,
          cell: (row) => row.reg_start_date_formatted,
        },
        {
          name: i18n.t("reg_end_date"),
          sortable: true,
          cell: (row) => row.reg_end_date_formatted,
        },
        {
          name: i18n.t("start_date"),
          sortable: true,
          cell: (row) => row.start_date_formatted,
        },
        {
          name: i18n.t("end_date"),
          sortable: true,
          cell: (row) => row.end_date_formatted,
        },
        {
          name: i18n.t(""),
          cell: (row) => {
            return (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Edit</Tooltip>}
              >
                <Link
                  className="btn btn-warning btn-sm text-white m-1"
                  to={"/edit_group/" + row.id}
                >
                  <i className="fa fa-pencil"></i>
                </Link>
              </OverlayTrigger>
            );
          },
        },
        {
          cell: (row) => {
            if (row.can_delete) {
              return (
                <div style={{ textAlign: "center" }}>
                  <OverlayTrigger placement="top" overlay={tooltip2}>
                    <button
                      className="btn btn-danger btn-sm text-white m-1"
                      onClick={(e) => {
                        this.deleteRow(row.id);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              );
            }
            if (!row.can_delete) {
              return (
                <div style={{ textAlign: "center" }}>
                  <OverlayTrigger placement="top" overlay={tooltip}>
                    <button className="btn btn-info btn-sm text-white m-1">
                      <i className="fa fa-info-circle" />
                    </button>
                  </OverlayTrigger>
                </div>
              );
            }
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
    this.toggleGrouped = this.toggleGrouped.bind(this);
    this.toggleEnrollGrouped = this.toggleEnrollGrouped.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    let { list } = this.state;
    let qstring = new URLSearchParams(list).toString();
    this.loadAllGroups(qstring);
    this.loadUsersByRoles();
    this.loadAllOrganisation();
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let organizations = response.data;
    this.setState({ organizations });
  }

  handlePageChange = (page) => {
    let { list } = this.state;
    let currentPage = page;
    list["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ list: list });
    let qstring = new URLSearchParams(list).toString();
    this.setState({ query: qstring });
    this.loadAllGroups(qstring);
  };

  async loadUsersByRoles() {
    let q = `roles=PM,HSE&is_role_wise=${true}`;
    const response = await getUsersByRoles(q);
    let managers = response.data["PM"];
    let hse_users = response.data["HSE"];
    this.setState({ managers, hse_users });
  }

  async loadAllGroups(qstring) {
    this.setState({ is_loading: true });
    const response = await getAllGroups(qstring);
    if (response.success) {
      let groups = response.data;
      let meta = response.meta;
      this.setState({ groups });
      this.setState({ totalRows: meta.total });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  toggleChange = () => {
    let input = this.state.input;
    input.is_extra_time = !this.state.input.is_extra_time;
    this.setState({ input });
  };

  toggleGrouped = () => {
    let input = this.state.input;
    input.is_auctions_grouped = !this.state.input.is_auctions_grouped;
    this.setState({
      input,
    });
  };

  toggleEnrollGrouped = () => {
    let input = this.state.input;
    input.is_grouped_enroll = !this.state.input.is_grouped_enroll;
    this.setState({
      input,
    });
  };

  onFileChange = (event) => {
    if (event.target.name === "file_group_image") {
      this.setState({ file_group_image: event.target.files[0] });
    }
    if (event.target.name === "file_terms") {
      this.setState({ file_terms: event.target.files[0] });
    }
    if (event.target.name === "file_payment_terms") {
      this.setState({ file_payment_terms: event.target.files[0] });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();

    let payload = this.state.input;
    if (
      !payload.group_name ||
      !payload.manager ||
      !payload.hse ||
      !payload.organization ||
      !payload.start_date ||
      !payload.end_date ||
      !payload.reg_start_date ||
      !payload.reg_end_date
    ) {
      swal("Failed", "Missing mandatory fields!!!", "error");
      return;
    }
    payload.enquiries = this.state.enquiries;

    const formData = new FormData();
    formData.append("organization", this.state.input.organization);
    formData.append("group_name", this.state.input.group_name);
    formData.append("group_name_ar", this.state.input.group_name_ar);
    formData.append("manager", this.state.input.manager);
    formData.append("hse", this.state.input.hse);
    formData.append("description", this.state.input.description);
    formData.append("description_ar", this.state.input.description_ar);
    formData.append("start_date", this.state.input.start_date);
    formData.append("end_date", this.state.input.end_date);
    formData.append("reg_start_date", this.state.input.reg_start_date);
    formData.append("reg_end_date", this.state.input.reg_end_date);
    formData.append(
      "is_auctions_grouped",
      this.state.input.is_auctions_grouped
    );
    formData.append("is_grouped_enroll", this.state.input.is_grouped_enroll);
    formData.append("terms", this.state.input.terms);
    formData.append("terms_arabic", this.state.input.terms_arabic);
    formData.append("amount", this.state.input.amount);
    formData.append("visit_amount", this.state.input.visit_amount);
    formData.append("payment_type", this.state.input.payment_type);
    formData.append("enquiries", JSON.stringify(this.state.enquiries));
    formData.append("file_group_image", this.state.file_group_image);
    formData.append("file_terms", this.state.file_terms);
    formData.append("file_payment_terms", this.state.file_payment_terms);
    formData.append("is_extra_time", this.state.input.is_extra_time);
    formData.append("has_auto_bidding", this.state.input.has_auto_bidding);
    formData.append("extra_time", this.state.input.extra_time);
    formData.append("vat", this.state.input.vat);
    formData.append("service_charge", this.state.input.service_charge);
    formData.append(
      "client_service_charge",
      this.state.input.client_service_charge
    );

    const response = await createGroup(formData);
    if (response.success) {
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", response.message, "error");
      });
      let { list } = this.state;
      let qstring = new URLSearchParams(list).toString();
      this.loadAllGroups(qstring);
    } else {
      let error_message = response.message;
      let resObject = response.data;
      for (const key in resObject) {
        if (Object.hasOwnProperty.call(resObject, key)) {
          let element = resObject[key];
          error_message += "\n" + element;
        }
      }
      swal("Failed", error_message, "error");
    }
  }

  deleteRow(id) {
    swal({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this group.?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = deleteGroup(id);
          console.log(response);
          swal(i18n.t("success"), "Item deleted", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((response) => {
              let { list } = this.state;
              let qstring = new URLSearchParams(list).toString();
              this.loadAllGroups(qstring);
            })
            .catch((response) => {
              swal("Failed", "Something went wrong", "error");
            });
        } catch (error) {
          console.error("Error deleting saved card:", error);
        }
      }
    });
  }

  appendData(e) {
    e.preventDefault();
    let enquiries = this.state.enquiries;
    enquiries.push({ label: "", value: "" });
    this.setState({ enquiries: enquiries });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  render() {
    let managers = this.state.managers;
    let hse_users = this.state.hse_users;
    let groups = this.state.groups;
    let enquiries = this.state.enquiries;
    let organizations = this.state.organizations;
    return (
      <React.Fragment>
        <HomeBanner />
        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <p style={{ textAlign: "right" }}>
                  <button
                    className="btn btn-primary"
                    onClick={(event) => {
                      this.setState({ collapse: !this.state.collapse });
                    }}
                  >
                    {this.state.collapse
                      ? i18n.t("add_new_group")
                      : i18n.t("hide_details")}
                  </button>
                </p>
                <div className={this.state.collapse ? "collapse" : ""}>
                  <form
                    id="UpdateProfile"
                    className="card mb-0"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="card-header">
                      <h3 className="card-title">
                        <span
                          style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                          onClick={() => {
                            this.setState({
                              hideSidebar: !this.state.hideSidebar,
                            });
                          }}
                          className="d-mob-none"
                        >
                          {this.state.hideSidebar && (
                            <i className="icon-size-actual"></i>
                          )}
                          {!this.state.hideSidebar && (
                            <i className="icon-size-fullscreen"></i>
                          )}
                        </span>
                        Create Group
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("organization")}{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <select
                              className="form-control"
                              data-placeholder={i18n.t("organization")}
                              name="organization"
                              value={this.state.input.organization}
                              onChange={this.handleChange}
                            >
                              <option value="">
                                --{i18n.t("select_client")}--
                              </option>
                              {organizations.length > 0 &&
                                organizations.map((item, i) => {
                                  return (
                                    <option value={item.id}>
                                      {item.organization_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("group_name")}{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="text"
                              name="group_name"
                              className="form-control"
                              placeholder={i18n.t("group_name")}
                              value={this.state.input.group_name}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("group_name")}({i18n.t("arabic")}){" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="text"
                              style={{ direction: "rtl" }}
                              name="group_name_ar"
                              className="form-control"
                              placeholder={i18n.t("group_name")}
                              value={this.state.input.group_name_ar}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("manager")}{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <select
                              className="form-control"
                              data-placeholder={i18n.t("manager")}
                              name="manager"
                              value={this.state.input.manager}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              {managers.map((item, i) => {
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("hse")}{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <select
                              className="form-control"
                              data-placeholder={i18n.t("hse")}
                              name="hse"
                              value={this.state.input.hse}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              {hse_users.map((item, i) => {
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("start_date")}{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="datetime-local"
                              name="start_date"
                              className="form-control"
                              placeholder={i18n.t("start_date")}
                              value={this.state.input.start_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("end_date")}{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="datetime-local"
                              name="end_date"
                              className="form-control"
                              placeholder={i18n.t("end_date")}
                              value={this.state.input.end_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("reg_start_date")}{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="datetime-local"
                              name="reg_start_date"
                              className="form-control"
                              placeholder={i18n.t("reg_start_date")}
                              value={this.state.input.reg_start_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("reg_end_date")}{" "}
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="datetime-local"
                              name="reg_end_date"
                              className="form-control"
                              placeholder={i18n.t("reg_end_date")}
                              value={this.state.input.reg_end_date}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">Enroll Amount</label>
                            <input
                              type="text"
                              name="amount"
                              className="form-control"
                              placeholder="Enroll Amount"
                              value={this.state.input.amount}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("visit_amount")}
                            </label>
                            <input
                              type="text"
                              name="visit_amount"
                              className="form-control"
                              placeholder={i18n.t("visit_amount")}
                              value={this.state.input.visit_amount}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("vat")}(%)
                            </label>
                            <input
                              type="text"
                              name="vat"
                              className="form-control"
                              placeholder={i18n.t("vat")}
                              value={this.state.input.vat}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("service_charge")}(%)
                            </label>
                            <input
                              type="text"
                              name="service_charge"
                              className="form-control"
                              placeholder={i18n.t("service_charge")}
                              value={this.state.input.service_charge}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("client_service_charge")}(%)
                            </label>
                            <input
                              type="text"
                              name="client_service_charge"
                              className="form-control"
                              placeholder={i18n.t("client_service_charge")}
                              value={this.state.input.client_service_charge}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-sm-4 col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("pay_type")}
                            </label>
                            <select
                              className="form-control"
                              data-placeholder="Select Group"
                              name="payment_type"
                              value={this.state.input.payment_type}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              <option value="Online">{i18n.t("online")}</option>
                              <option value="Offline">
                                {i18n.t("offline")}
                              </option>
                              <option value="Both">
                                Supports both Online & Offline
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-2">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="is_auctions_grouped"
                              type="checkbox"
                              checked={this.state.input.is_auctions_grouped}
                              onChange={this.toggleGrouped}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Auctions Grouped.?
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-2">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <input
                              name="is_grouped_enroll"
                              type="checkbox"
                              checked={this.state.input.is_grouped_enroll}
                              onChange={this.toggleEnrollGrouped}
                              style={{ float: "left", margin: "4px" }}
                            />
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Grouped Enroll.?
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <label className="form-label">
                              <input
                                name="is_extra_time"
                                type="checkbox"
                                checked={this.state.input.is_extra_time}
                                value={this.state.input.is_extra_time}
                                onChange={this.toggleChange}
                              />
                              &nbsp;
                              {i18n.t("extra_time")}
                            </label>

                            <input
                              name="extra_time"
                              type="number"
                              className="form-control text-center"
                              onChange={this.handleChange}
                              placeholder={i18n.t("in_minutes")}
                              value={this.state.input.extra_time}
                              disabled={!this.state.input.is_extra_time}
                            />
                          </div>
                        </div>

                        <div className="col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              Upload group image
                            </label>
                            <FileInput
                              placeholder={i18n.t("choose_file")}
                              className="form-control"
                              name="file_group_image"
                              onChange={this.onFileChange}
                              value={this.state.file_vat_certificate}
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            ></small>
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              Upload Terms & Conditions
                            </label>
                            <FileInput
                              placeholder={i18n.t("choose_file")}
                              className="form-control"
                              name="file_terms"
                              accept="application/pdf"
                              onChange={this.onFileChange}
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            ></small>
                          </div>
                        </div>
                        <div className="col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              Upload Payment Terms
                            </label>
                            <FileInput
                              wrapperClass=""
                              placeholder={i18n.t("choose_file")}
                              id="file_payment_terms"
                              name="file_payment_terms"
                              onChange={this.onFileChange}
                              accept="application/pdf"
                              className="form-control"
                            />
                            <small className="form-text text-muted"></small>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <div className="form-group">
                            <label className="form-label">Auto Bidding.?</label>
                            <ToggleButton
                              inactiveLabel={"No"}
                              activeLabel={"Yes"}
                              value={this.state.input.has_auto_bidding}
                              className="form-control m-auto"
                              onToggle={(value) => {
                                let { input } = this.state;
                                input.has_auto_bidding = !value;
                                this.setState({
                                  input,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("description")}
                            </label>
                            <textarea
                              className="form-control"
                              name="description"
                              value={this.state.input.description}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("description")}({i18n.t("arabic")})
                            </label>
                            <textarea
                              style={{ direction: "rtl" }}
                              className="form-control"
                              name="description_ar"
                              value={this.state.input.description_ar}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("terms_conditions")}
                            </label>
                            <textarea
                              className="form-control"
                              name="terms"
                              value={this.state.input.terms}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("terms_conditions")} (Arabic)
                            </label>
                            <textarea
                              style={{ direction: "rtl" }}
                              className="form-control"
                              name="terms_arabic"
                              value={this.state.input.terms_arabic}
                              onChange={this.handleChange}
                              rows={5}
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <h5>{i18n.t("enquiry")}</h5>
                          {enquiries.length > 0 &&
                            enquiries.map((item, i) => {
                              return (
                                <div className="row" key={i}>
                                  <div className="col-sm-3 col-md-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Label
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonLabel"
                                        className="form-control"
                                        placeholder="Label"
                                        value={item.label || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "label",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-2 col-md-2">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Value
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonValue"
                                        className="form-control"
                                        placeholder="Value"
                                        value={item.value || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "value",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Label(Arabic)
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonLabel"
                                        className="form-control"
                                        style={{ direction: "rtl" }}
                                        placeholder="Label(Arabic)"
                                        value={item.label_ar || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "label_ar",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-2 col-md-2">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Value(Arabic)
                                      </label>
                                      <input
                                        type="text"
                                        name="jsonValue"
                                        className="form-control"
                                        style={{ direction: "rtl" }}
                                        placeholder="Value"
                                        value={item.value_ar || ""}
                                        onChange={(event) => {
                                          this.setEnquiryJson(
                                            "value_ar",
                                            event,
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {i > 0 && (
                                    <div className="col-sm-2 col-md-2">
                                      <div className="form-group">
                                        <label className="form-label">
                                          &nbsp;
                                        </label>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          key={i}
                                          onClick={(event) => {
                                            this.removeEnquiryData(i);
                                          }}
                                        >
                                          <i className="fa fa-minus"></i>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div
                            className="form-group"
                            style={{ textAlign: "right" }}
                          >
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={this.appendData}
                              style={{ marginRight: "20px" }}
                            >
                              <i className="fa fa-plus"></i>
                              <span>Add New</span>
                            </button>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <hr />
                          <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                              Create Group
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={(event) => {
                                this.setState({
                                  collapse: !this.state.collapse,
                                });
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <hr />
                </div>

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                        className="d-mob-none"
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      Groups
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      <MzadLoader
                        animation="grow"
                        size="md"
                        loading={this.state.is_loading}
                      />

                      {!this.state.is_loading && groups.length < 1 && (
                        <div>{i18n.t("no_data_found")}</div>
                      )}

                      {!this.state.is_loading && groups.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            fixedHeader
                            columns={this.state.columns}
                            data={groups}
                            customStyles={customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            paginationPerPage={this.state.list.limit}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: i18n.t("groups_per_page"),
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
