import { apiUrl } from "../constants/global";
import { getCurrentLocale, getLoggedUser } from "../state/user";
const user = getLoggedUser();
const lang = getCurrentLocale();

async function getAllCalendarData() {
  if (user && user.id) {
    return fetch(apiUrl + "auc_calendar?locale=" + lang, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
    }).then((data) => data.json());
  }
  return;
}

export { getAllCalendarData };
