import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import submitContact from "../models/contact";
import i18n from "../i18n";
import swal from "sweetalert";
import { HomeBanner } from "./home/homeBanner";
export class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      is_submitted: false,
      is_progress: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitContact = this.submitContact.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async submitContact() {
    let input = this.state.input;
    let errors = this.state.errors;
    if (!input.your_name) {
      errors.your_name = "Your name is mandatory";
      this.setState({ errors });
      return;
    }
    if (!input.email) {
      errors.email = "Your email is mandatory";
      this.setState({ errors });
      return;
    }
    if (!input.message) {
      errors.message = "Must include your query";
      this.setState({ errors });
      return;
    }

    this.setState({ is_submitted: true });
    this.setState({ is_progress: true });
    const response = await submitContact(this.state.input);
    if (response.success) {
      swal(i18n.t("success"), "Message sent successfully!!", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          this.resetForm();
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
      this.setState({ is_progress: false });
    } else {
      let error_message = response.message;
      let resObject = response.data;
      for (const key in resObject) {
        if (Object.hasOwnProperty.call(resObject, key)) {
          let element = resObject[key];
          error_message += "\n" + element;
        }
      }
      swal("Failed", error_message, "error");
      this.setState({ is_submitted: false, is_progress: false });
    }
  }

  resetForm() {
    let input = {};
    input["your_name"] = "";
    input["email"] = "";
    input["message"] = "";
    this.setState({ input: input });
  }

  render() {
    return (
      <div className="page-container">
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <div className="blue-menu-bg py-4"></div>
        <div className="content-bg">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-lg-6 col-lg-3">
                <div className="w-85 mx-auto">
                  <div className="blue-container">
                    <div className="mx-3 mb-3 mobile_size">
                      <img src="./assets/images/phone.png" alt="" />
                      <span className="custom-text5 ms-1 text-white">
                        {i18n.t("contactus")}
                      </span>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="bg-white user-brd rounded-4 px-0">
                          <div className="d-flex gap-0 contact-wrapper">
                            <div className="col ps-4 pt-4 pb-4 px-4">
                              <div className="contact-description">
                                <h2>{i18n.t("say_hello")}</h2>
                                <p className="mt-5">{i18n.t("say_hello2")}</p>
                                <p className="mt-5">{i18n.t("need_help")}</p>
                                <div className="mb-3">
                                  <small className="text-muted">
                                    {i18n.t("feel_like_talking")}
                                  </small>
                                  <p>
                                    <a
                                      href="tel:+96892788822"
                                      className="mb-0 fs-16 font-weight-bold text-dark"
                                    >
                                      +968 92 788 822
                                    </a>
                                  </p>
                                </div>
                                <div className="mb-3">
                                  <small className="text-muted">
                                    {i18n.t("or")}
                                  </small>
                                  <p>
                                    <a
                                      href="mailto:info@mzadcom.om"
                                      className="mb-0 fs-16 font-weight-bold text-dark"
                                    >
                                      {i18n.t("email")} : info@mzadcom.om
                                    </a>
                                  </p>
                                </div>

                                <small className="text-muted">
                                  {i18n.t("social_share")}
                                </small>
                                <ul className="list-unstyled list-inline mt-3 mb-5">
                                  <li className="list-inline-item">
                                    <a
                                      href="https://www.facebook.com/profile.php?id=100074132232182"
                                      target="_blank"
                                      className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                                      rel="noreferrer"
                                    >
                                      <i className="fa fa-facebook text-info"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item">
                                    <a
                                      href="https://twitter.com/mzadcom.om"
                                      className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light text-center"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/x-icon.png"
                                        }
                                        alt="x-twitter"
                                        className="mt-1"
                                        style={{
                                          width: "13px",
                                          filter: "brightness(0)",
                                        }}
                                      />
                                    </a>
                                  </li>
                                  <li className="list-inline-item">
                                    <a
                                      href="https://www.instagram.com/mzadcomom"
                                      className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <i className="fa fa-linkedin text-primary"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col ps-4 pt-4 pb-4 px-4">
                              <div className="single-page">
                                <div className="col-lg-12 col-md-12 mx-auto d-block">
                                  <div className="wrapper wrapper2">
                                    <div className="card box-shadow-0 mb-0">
                                      <div className="card-body">
                                        <div className="form-group mb-3">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="your_name"
                                            name="your_name"
                                            value={this.state.input.your_name}
                                            onChange={this.handleChange}
                                            placeholder={i18n.t("name")}
                                          />
                                          <div className="text-danger">
                                            {this.state.errors.your_name}
                                          </div>
                                        </div>
                                        <div className="form-group mb-3">
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={this.state.input.email}
                                            onChange={this.handleChange}
                                            placeholder={i18n.t("email")}
                                          />
                                          <div className="text-danger">
                                            {this.state.errors.email}
                                          </div>
                                        </div>
                                        <div className="form-group mb-3">
                                          <textarea
                                            className="form-control"
                                            rows="6"
                                            name="message"
                                            value={this.state.input.message}
                                            onChange={this.handleChange}
                                            placeholder={i18n.t("message")}
                                          ></textarea>
                                          <div className="text-danger">
                                            {this.state.errors.message}
                                          </div>
                                        </div>
                                        {!this.state.is_progress &&
                                          !this.state.is_submitted && (
                                            <div className="d-grid gap-2">
                                              <button
                                                type="button"
                                                className="btn custom_btn-color rounded-pill"
                                                onClick={this.submitContact}
                                              >
                                                <span className="custom-text">
                                                  {i18n.t("send_message")}
                                                </span>
                                              </button>
                                            </div>
                                          )}
                                        {this.state.is_progress &&
                                          this.state.is_submitted && (
                                            <Spinner animation="border text-primary" />
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
