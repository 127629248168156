import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import ar from "./locales/ar.json";

const resources = {
  en: { translation: en },
  ar: { translation: ar },
};

const options = {
  order: ["querystring", "navigator", "localStorage"],
  lookupQuerystring: "lng",
  lookupLocalStorage: "I18N_LANGUAGE",
  caches: ["localStorage"],
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "ar",
    detection: options,
    fallbackLng: "ar",
    keySeparator: false,
    supportedLngs: ["en", "ar"],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;
