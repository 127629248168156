import React from "react";
import i18n from "../../i18n";
import GoogleSignin from "./googleSignin";
import { FacebookSignin } from "./facebookSignin";
export class SocialSignin extends React.Component {
  googleResponseSuccess = (response) => {
    console.log("success", response);
  };

  googleResponseError = (response) => {
    console.log("error", response);
  };

  render() {
    return (
      <React.Fragment>
        <GoogleSignin />
        <FacebookSignin />
      </React.Fragment>
    );
  }
}
