import React from "react";
import i18n from "../../../../i18n";
import Chart from "react-apexcharts";
import { Spinner } from "react-bootstrap";

export class SalesChart extends React.Component {
  constructor() {
    super();
    this.state = {
      salesData: "monthly",
      data_panel: false,
    };
  }

  componentDidMount() {}

  render() {
    let { data_panel } = this.state;
    let salesMonthlyOptions = this.props.monthlySales.options;
    let salesMonthlySeries = this.props.monthlySales.series;
    let salesYearlyOptions = this.props.yearlySales.options;
    let salesYearlySeries = this.props.yearlySales.series;
    let data_panel_cls = data_panel ? "card-body text-center pt-2" : "collapse";

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="card">
              <div
                className="card-header d-flex justify-content-between"
                onClick={() => {
                  this.setState({
                    data_panel: !this.state.data_panel,
                  });
                }}
                role="button"
              >
                <h3 className="card-title ">
                  {this.state.salesData === "monthly" && (
                    <span>{i18n.t("sales_last_12_months")}</span>
                  )}
                  {this.state.salesData === "yearly" && (
                    <span>{i18n.t("sales_last_5_years")}</span>
                  )}
                </h3>
                <i
                  className={data_panel ? "fa fa-angle-up" : "fa fa-angle-down"}
                />
              </div>

              <div className={data_panel_cls}>
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    className={
                      this.state.salesData === "monthly"
                        ? "btn btn-dark btn-sm"
                        : "btn btn-light btn-sm"
                    }
                    onClick={() => {
                      this.setState({
                        salesData: "monthly",
                      });
                    }}
                  >
                    {i18n.t("monthly")}
                  </button>
                  <button
                    type="button"
                    className={
                      this.state.salesData === "daily"
                        ? "btn btn-dark btn-sm"
                        : "btn btn-light btn-sm"
                    }
                    onClick={() => {
                      this.setState({
                        salesData: "yearly",
                      });
                    }}
                  >
                    {i18n.t("yearly")}
                  </button>
                </div>

                {this.state.is_loading_4 && (
                  <div className="m-5">
                    <Spinner animation="border" size="lg" />
                  </div>
                )}

                {!this.state.is_loading_4 &&
                  this.state.salesData === "monthly" &&
                  typeof salesMonthlyOptions !== "undefined" &&
                  typeof salesMonthlySeries !== "undefined" && (
                    <Chart
                      options={salesMonthlyOptions}
                      series={salesMonthlySeries}
                      type="area"
                      height={320}
                    />
                  )}

                {!this.state.is_loading_4 &&
                  this.state.salesData === "yearly" &&
                  typeof salesYearlyOptions !== "undefined" &&
                  typeof salesYearlySeries !== "undefined" && (
                    <Chart
                      options={salesYearlyOptions}
                      series={salesYearlySeries}
                      type="area"
                      height={320}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
