import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
//import ReactTooltip from "react-tooltip";
import { AuctionShare } from "./auctionShare";
import { formatCurrency } from "../../../state/utils";
import { CustomEnroll } from "../../helpers/customEnroll";
import { VisitNow } from "./visitNow";

//const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
//let lng = localStorage.getItem("I18N_LANGUAGE");
const FinishBid = () => (
  <div className="p-3 text-center text-danger">{i18n.t("auction_end")}</div>
);
const rendererEnds = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div className="outer-div m-auto mt-1">
        <div className="first d-flex gap-1">
          <span>{i18n.t("ends_after")}</span>{" "}
          <span className="d-flex">
            <span>{days}:</span>
            <span>{hours}:</span>
            <span>{minutes}:</span>
            <span>{seconds}</span>
          </span>
        </div>
        <div className="second text-danger">
          {/* timer-label-container */}
          <div className="inner-section text-danger">
            <span className="text-danger lbl_day">{i18n.t("lbl_day")}</span>
          </div>
          <div className="inner-section text-danger">
            <span className="text-danger lbl_hour">{i18n.t("lbl_hour")}</span>
          </div>
          <div className="inner-section text-danger">
            <span className="text-danger lbl_minute">
              {i18n.t("lbl_minute")}
            </span>
          </div>
          <div className="inner-section text-danger">
            <span className="text-danger lbl_second">
              {i18n.t("lbl_second")}
            </span>
          </div>
        </div>
      </div>
    );
  }
};
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return "";
  } else {
    // Render a countdown
    return (
      <div className="outer-div m-auto mt-1">
        <div className="first d-flex gap-1">
          <span>{i18n.t("starts_after")}</span>{" "}
          <span className="d-flex">
            <span>{days}:</span>
            <span>{hours}:</span>
            <span>{minutes}:</span>
            <span>{seconds}</span>
          </span>
        </div>
        <div className="second">
          {/* timer-label-container */}
          <div className="inner-section">
            <span className="lbl_day">{i18n.t("lbl_day")}</span>
          </div>
          <div className="inner-section">
            <span className="lbl_hour">{i18n.t("lbl_hour")}</span>
          </div>
          <div className="inner-section">
            <span className="lbl_minute">{i18n.t("lbl_minute")}</span>
          </div>
          <div className="inner-section">
            <span className="lbl_second">{i18n.t("lbl_second")}</span>
          </div>
        </div>
      </div>
    );
  }
};

export class GroupCard extends React.Component {
  constructor() {
    super();
    this.state = {
      bidTimeStopped: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.incrementTime = this.incrementTime.bind(this);
  }

  componentDidMount() {
    if (this.props.group) {
      setInterval(this.incrementTime, 1000);
    }
  }

  incrementTime() {
    let { group } = this.props;
    if (!group) {
      console.error("group is undefined in props");
      return;
    }
    let dt = new Date(group.server_time);
    dt.setSeconds(dt.getSeconds() + 1);
    group.server_time = dt;
    this.setState({ group });
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let group = this.props.group;
    let img_src = group.group_image;
    let startDateAr = group.start_date_ar;
    let endDateAr = group.end_date_ar;

    // let auctionImageStyle = {};
    // if (this.props.isDetailsPage) {
    //   auctionImageStyle = { height: "90%" };
    // }
    let statusAr = group.status_label;
    let disp_status = "";
    let statusClass = "";
    let statusClasses = {
      E: "btn btn-label expired",
      F: "btn btn-label featured",
      A: "btn btn-label active",
      U: "btn btn-label upcoming",
    };
    if (statusAr && statusAr["status"]) {
      statusClass = statusClasses[statusAr["status"]];
      disp_status = current_lng === "ar" ? statusAr["ar"] : statusAr["en"];
    }

    let now = this.state.now;
    let endTime = new Date(this.createDate(group.start_date)).getTime();
    let aucEndTime = new Date(this.createDate(group.end_date)).getTime();

    let end_time = endDateAr && endDateAr["time"] ? endDateAr["time"] : "";
    end_time = end_time.replace("PM", i18n.t("PM"));
    end_time = end_time.replace("AM", i18n.t("AM"));
    let start_time =
      startDateAr && startDateAr["time"] ? startDateAr["time"] : "";
    start_time = start_time.replace("PM", i18n.t("PM"));
    start_time = start_time.replace("AM", i18n.t("AM"));

    // let orgImg = "";
    // if (
    //   group.organizationDetails &&
    //   group.organizationDetails.file_organization_image_full
    // ) {
    //   orgImg = group.organizationDetails.file_organization_image_full;
    // }

    let amount_label = "";
    if (this.props.type === "upcoming") {
      amount_label = i18n.t("start_bid_amount");
    } else if (this.props.type === "previous") {
      amount_label = i18n.t("last_bid_amount");
    } else {
      amount_label = i18n.t("current_amount");
    }
    let orgImage = "";
    if (
      group &&
      group.organizationDetails &&
      group.organizationDetails.file_organization_image_full
    ) {
      orgImage = group.organizationDetails.file_organization_image_full;
    }

    let auction_started = false;
    let groupStartDate = new Date(this.createDate(group.start_date));
    if (groupStartDate < now()) {
      auction_started = true;
    }
    return (
      <React.Fragment key={this.props.key}>
        <div className="col-md-4 col-sm-6 col-xs-12 pt-3 auc-card-container m-auto">
          <button className={statusClass}>{disp_status}</button>
          <div className="auction-card-img-cont">
            <img
              src={img_src}
              className="img-fluid"
              width="333px"
              height="auto"
              alt=""
            />
          </div>
          <div className="content-div">
            <div className="flex-fill auc-title-cust" style={{ width: "100%" }}>
              <a href={false}>
                {current_lng === "ar" && group.group_name_ar
                  ? group.group_name_ar
                  : group.group_name}
              </a>
              {/* <img
                src="./assets/images/br23.png"
                height="35"
                width="37"
                alt=""
              /> */}
            </div>

            <div className="container interactions">
              <div className="row">
                <button type="button" className="btn btn-circle btn-sm">
                  {this.props.total_views} &nbsp;
                  <i className="icon icon-eye" style={{ fontSize: "12px" }}></i>
                </button>
                <button
                  type="button"
                  className="btn  btn-circle btn-sm"
                  onClick={(event) => {
                    this.addWishList(!this.state.wishlist);
                  }}
                >
                  <i
                    style={{ fontSize: "12px" }}
                    className={
                      this.state.wishlist ? "fa fa-heart" : "fa fa-heart-o"
                    }
                  ></i>
                </button>
                <button
                  type="button"
                  className="btn  btn-circle btn-sm"
                  onClick={(event) => {
                    this.addLike(!this.state.like);
                  }}
                >
                  <i
                    style={{ fontSize: "12px" }}
                    className={
                      this.state.like ? "fa fa-thumbs-up" : "fa fa-thumbs-o-up"
                    }
                  ></i>
                </button>
                <button type="button" className="btn  btn-circle btn-sm count">
                  {i18n.t("bid_counr")}
                  {"#:"}
                  {group.bid_count}
                </button>
              </div>
            </div>

            {group.status_dis !== "A" && (
              <div className="outer-div m-auto mt-1">
                <div className="p-1 text-center text-danger">
                  {i18n.t("bid_time_ends")}
                </div>
              </div>
            )}
            {group.is_bid_time_ends && (
              <div className="outer-div m-auto mt-1">
                <div className="p-1 text-center text-danger">
                  {i18n.t("bid_time_ends")}
                </div>
              </div>
            )}
            {group.status_dis === "A" &&
              !group.is_bid_time_ends &&
              group.end_date && (
                <Countdown
                  date={endTime}
                  renderer={renderer}
                  onComplete={() => {
                    this.setState({ bidTimeStopped: true });
                  }}
                  now={() => new Date(group.server_time)}
                />
              )}

            {group.status_dis === "A" &&
              !group.is_bid_time_ends &&
              group.end_date &&
              auction_started && (
                <Countdown
                  date={aucEndTime}
                  renderer={rendererEnds}
                  onComplete={() => {
                    this.setState({ bidTimeStopped: true });
                  }}
                  now={() => new Date(group.server_time)}
                />
              )}

            <div className="container pt-3 contaiiner-amt">
              <div className="row">
                <div className="col col-one" id="div1">
                  <span className="span-1">
                    <strong>
                      {i18n.t("start_bid_amount")} :
                      {formatCurrency(group.start_amount)} {i18n.t("omr")}
                    </strong>
                  </span>
                  <span className="span-1">
                    <strong>{i18n.t("gurantee_amount")}: N/A</strong>
                  </span>
                  {Number(group.visit_amount) > 0 && (
                    <span className="span-1">
                      <strong>
                        {i18n.t("visit_amount")}:{" "}
                        {formatCurrency(group.visit_amount)}
                        {i18n.t("omr")}
                      </strong>
                      <span>
                        {group.is_visit_active && (
                          <VisitNow
                            amount={group.visit_amount}
                            type="sitevisit"
                            id={null}
                            gid={group.group}
                            is_group={true}
                            main_class="btn btn-n1"
                          />
                        )}
                      </span>
                    </span>
                  )}
                  {Number(group.visit_amount) < 1 && (
                    <span className="span-1">
                      <strong>{i18n.t("visit_amount")}: N/A</strong>
                    </span>
                  )}
                  <span className="span-1">
                    <strong>
                      {i18n.t("vat")}: {group.vat || "0"} {i18n.t("%")}
                    </strong>
                  </span>
                  <span className="span-1">
                    <strong>
                      {amount_label}: {formatCurrency(group.current_amount)}{" "}
                      {i18n.t("omr")}
                    </strong>
                  </span>
                </div>

                <div className="col col-two" id="div2">
                  <div>
                    <img src={orgImage} alt="" height="35" width="45" />
                  </div>
                  <span className="span-1">
                    <strong>
                      {startDateAr && startDateAr["day"]
                        ? i18n.t(startDateAr["day"])
                        : ""}
                    </strong>
                  </span>
                  <span className="span-2">
                    <strong>{i18n.t("start_date")}</strong>
                  </span>
                  <div className="div-1">
                    <span className="date">
                      <strong>
                        {startDateAr && startDateAr["date"]
                          ? startDateAr["date"]
                          : ""}
                      </strong>
                    </span>
                    <span className="time">
                      <strong>({start_time})</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="container container-dates">
              <div className="row">
                <div className="col col-one" id="div2">
                  <div className="row">
                    <div className="col-6 col-times" id="div21">
                      <span className="">
                        <strong>{i18n.t("time")}</strong>
                      </span>
                      <span>
                        <strong>({end_time})</strong>
                      </span>
                    </div>

                    <div className="col-6 col-dates" id="div22">
                      <span>
                        <strong>{i18n.t("end_date")}</strong>
                      </span>
                      <span>
                        <strong>
                          {endDateAr && endDateAr["date"]
                            ? endDateAr["date"]
                            : ""}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col col-cont-details" id="div2">
                  <div className="row d-flex align-items-center justify-content-between mt-2">
                    <div className="col-6 cont-tot-auc mx-1" id="div21">
                      <img
                        src="./assets/images/total_auc.png"
                        width="33"
                        height="29"
                        alt=""
                      />
                      <p>{group.auctions_count}</p>
                    </div>

                    <div className="col-6 col-cal-cont d-block" id="div21">
                      <div className="d-flex justify-content-between gap-2">
                        <img
                          src="./assets/images/solar_calendar-bold-duotone.png"
                          width="15"
                          height="15"
                          alt=""
                          className="mt-1"
                        />
                        <p>{group.days_remaining}</p>
                      </div>
                      <p
                        className="text-center mb-0"
                        style={{ fontSize: "0.5rem" }}
                      >
                        {i18n.t("days_of_auction")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="d-flex align-items-center justify-content-center mt-2">
                      <button
                        type="submit"
                        className="btn btn-color rounded-pill d-flex align-items-center justify-content-center text-center btn-final-aaproval"
                      >
                        {i18n.t("final_approval_lbl")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col cont-list-auc" id="div2">
                  <div className="row d-flex align-items-center justify-content-center mt-2 gap-2">
                    <div className="col-md-6 col-sm-12 btn-cont" id="div21">
                      <Link
                        to={
                          "/grouped-auctions/" +
                          group.group +
                          "?type=" +
                          this.props.type
                        }
                      >
                        <i className="fa fa-info-circle"></i>&nbsp;
                        {i18n.t("list_auctions")}
                      </Link>
                    </div>
                    &nbsp;
                    {this.props.can_bid &&
                      group.status_dis === "A" &&
                      !group.is_enrolled &&
                      group.first_auction_id &&
                      group.is_grouped === 1 &&
                      group.is_grouped_enroll === 1 && (
                        <div
                          className="col-md-6 col-sm-12 btn-cont-2"
                          id="div21"
                        >
                          <Link
                            to={
                              !is_logged
                                ? "/login"
                                : "/enroll/" + group.first_auction_id
                            }
                            className="d-flex"
                          >
                            <img
                              src="./assets/images/user.png"
                              width="10"
                              height="10"
                              alt=""
                            />
                            &nbsp;
                            <span className="d-none-mob px-1">
                              {i18n.t("enroll")}
                            </span>
                          </Link>
                        </div>
                      )}
                    {this.props.can_bid &&
                      group.status_dis === "A" &&
                      group.first_auction_id &&
                      group.is_grouped === 1 &&
                      group.is_grouped_enroll === 0 && (
                        <div
                          className="col-md-6 col-sm-12 btn-cont-2"
                          id="div21"
                        >
                          <CustomEnroll
                            group={group.group}
                            className="btn btn-warning rounded-5 py-1 text-white"
                          />
                        </div>
                      )}
                  </div>
                </div>

                <div className="social-icons-container">
                  <div className="social-icons">
                    <AuctionShare auction={group} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
