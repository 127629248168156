import React from "react";
import i18n from "../../i18n";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getClients } from "../../models/organization";

let current_lng = localStorage.getItem("I18N_LANGUAGE");

var settings = {
  className: "partner-images",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 3,
  speed: 5000,
  rows: 1,
  slidesPerRow: 3,
  dots: false,
  arrows: false,
  slidesToScroll: 0,
  autoplay: true,
  autoplaySpeed: 2000,
  lazyLoad: true,
  cssEase: "linear",
  pauseOnHover: false,
  rtl: current_lng === "ar" ? true : false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export class BeingPart extends React.Component {
  constructor() {
    super();
    this.state = {
      organizations: [],
    };
  }
  componentDidMount() {
    this.loadAllOrganizations();
  }
  async loadAllOrganizations() {
    const response = await getClients();
    let organizations = response.data;
    this.setState({ organizations });
  }

  render() {
    let organizations = this.state.organizations;
    return (
      <React.Fragment>
        <section className="our-partners mb-9 mt-5">
          <div className="container-w100">
            <div className="section-title center-block text-center mb-5">
              <span className="our-partners-heading">
                {i18n.t("our_partners")}
              </span>
            </div>
            <div className="row">
              <Marquee
                speed={70}
                direction={current_lng === "ar" ? "right" : "left"}
                style={{ direction: "ltr" }}
                className="logos"
              >
                {organizations.map((item, i) => {
                  return (
                    <img
                      src={item.file_organization_image}
                      alt="img"
                      className="rounded-circle mx-4"
                      style={{
                        width: "88px",
                        minWidth: "88px",
                        height: "88px",
                      }}
                    />
                  );
                })}
              </Marquee>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
