import React from "react";
import i18n from "../../i18n";
import { getGroupedAuctions } from "../../models/auctions";
import { Modal } from "react-bootstrap";
import { MzadLoader } from "./mzadLoader";

let lng = localStorage.getItem("I18N_LANGUAGE");

export class CustomEnroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      group_id: this.props.group,
      auctions: [],
      select_type: "all",
      selected_ids: [],
      open_popup: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {}

  async loadGroupedAuctions() {
    this.setState({ is_loading: true });
    this.setState({ isAllChecked: false, isChecked: false, selected_ids: [] });
    let { group_id } = this.state;
    let q = `lang=${lng}&is_multiple=1`;
    if (group_id) {
      q += `&group=${group_id}`;
    }

    const response = await getGroupedAuctions(q);
    if (response.success) {
      let auctions = response.data;
      let meta = response.meta;
      if (auctions) {
        this.setState({ auctions: auctions, meta, loading: false });
      }
      if (meta.group) {
        this.setState({ gpData: meta.group });
      }
      if (meta.organization) {
        this.setState({ orgData: meta.organization });
      }
      if (meta.enrolledUsers) {
        this.setState({ enrolledUsers: meta.enrolledUsers });
      }
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  openModal() {
    this.setState({ open_popup: true });
    this.loadGroupedAuctions();
  }
  closeModal() {
    this.setState({ open_popup: false });
  }

  handleCheckAll = () => {
    this.setState((prevState) => {
      const updatedAuctions = prevState.auctions.map((item) => ({
        ...item,
        isChecked: !prevState.isAllChecked,
      }));

      const selectedIds = updatedAuctions
        .filter((item) => item.isChecked && !item.is_enroll_requested)
        .map((item) => item.id);

      return {
        auctions: updatedAuctions,
        isAllChecked: !prevState.isAllChecked,
        selected_ids: selectedIds,
      };
    });
  };

  handleCheckboxChange = (itemId) => {
    this.setState((prevState) => {
      const updatedAuctions = prevState.auctions.map((item) =>
        item.id === itemId ? { ...item, isChecked: !item.isChecked } : item
      );

      const selectedIds = updatedAuctions
        .filter((item) => item.isChecked)
        .map((item) => item.id);

      return {
        auctions: updatedAuctions,
        selected_ids: selectedIds,
      };
    });
  };

  gotoEnrollment() {
    let { gpData, selected_ids, isAllChecked, auctions } = this.state;
    let payload = {};
    payload.group = gpData.id;
    payload.custom_enroll_ids = [];
    payload.is_individual = false;
    payload.auctionID = gpData.first_auction_id;
    if (!isAllChecked) {
      payload.custom_enroll_ids = selected_ids;
      payload.is_individual = true;
    }
    let totalPayment = 0;
    for (let auction of auctions) {
      if (auction.isChecked === true) {
        totalPayment += Number(auction.payment_amount);
      }
    }
    payload.enroll_amount = totalPayment;
    localStorage.setItem("custom_enrollment", JSON.stringify(payload));
    window.location.href = "/custom-enroll/" + gpData.first_auction_id;
  }

  render() {
    let { auctions, open_popup, is_loading, isAllChecked, selected_ids } =
      this.state;
    let className = this.props.className
      ? `${this.props.className} d-flex align-items-center gap-1 wrap-no`
      : "btn btn-warning rounded-5 text-white d-flex align-items-center gap-1 wrap-no";

    let totalPayment = 0;
    for (let auction of auctions) {
      if (auction.isChecked === true && !auction.is_enroll_requested) {
        totalPayment += Number(auction.payment_amount);
      }
    }
    return (
      <React.Fragment>
        <button
          type="button"
          className={className}
          style={{ width: "100px" }}
          onClick={() => {
            this.openModal();
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/images/user.png"}
            width="10"
            height="10"
            alt=""
          />{" "}
          {i18n.t("enroll")}
        </button>

        {open_popup && (
          <Modal
            size="md"
            show={this.openModal}
            onHide={this.closeModal}
            animation={true}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                {i18n.t("select_favourite_auction")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="border m-3 p-0 rounded-2">
              <div className="m-auto">
                <MzadLoader
                  loading={is_loading}
                  animation="grow"
                  size="sm"
                  className="m-auto"
                />
              </div>

              {!is_loading && auctions.length === 0 && (
                <div className="alert alert-danger text-center">
                  {i18n.t("no_auctions_found")}
                </div>
              )}

              {!is_loading && auctions.length > 0 && (
                <div className="d-flex bg-light rounded-2 p-1 border m-2 justify-content-between">
                  <div className="font-weight-bold">
                    {i18n.t("enroll_amount")} : {totalPayment} {i18n.t("omr")}
                  </div>
                  <div>
                    {i18n.t("all")}
                    <input
                      type="checkbox"
                      checked={isAllChecked}
                      onChange={this.handleCheckAll}
                      className="mx-3"
                    />
                  </div>
                </div>
              )}

              <div style={{ height: "65vh", overflow: "scroll" }}>
                {!is_loading &&
                  auctions.length > 0 &&
                  auctions.map((item, i) => {
                    let key = parseInt(i) + 1;
                    let images = item.images;
                    let img_src =
                      process.env.PUBLIC_URL + "/assets/images/placeholder.jpg";
                    if (images.length > 0) {
                      img_src = images[0].image;
                    }
                    return (
                      <div className="row m-2 bg-light" key={key}>
                        <div className="col-12 d-flex border rounded p-1 justify-content-between align-items-center gap-1">
                          <div className="d-flex align-items-center gap-1">
                            <div className="image">
                              <img src={img_src} alt="" width="75" />
                            </div>
                            <div className="title px-1">
                              <h6 className="h6 mb-0">
                                {lng === "ar" ? item.title_ar : item.title}
                              </h6>
                              <h6 className="h6 mb-0 font-weight-bold">
                                {item.payment_amount} {i18n.t("omr")}
                              </h6>
                            </div>
                          </div>
                          <div className="action mx-3">
                            {!item.is_enroll_requested && (
                              <input
                                type="checkbox"
                                value={item.id}
                                checked={item.isChecked}
                                onChange={() =>
                                  this.handleCheckboxChange(item.id)
                                }
                                disabled={isAllChecked}
                              />
                            )}
                            {item.is_enroll_requested && !item.is_enrolled && (
                              <span
                                className="text-danger wrap-no"
                                style={{ fontSize: "0.7rem" }}
                              >
                                {i18n.t("waiting_for_approval")}
                              </span>
                            )}
                            {item.is_enroll_requested && item.is_enrolled && (
                              <span
                                className="text-success wrap-no"
                                style={{ fontSize: "0.7rem" }}
                              >
                                {i18n.t("approved")}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {!is_loading && auctions.length > 0 && (
                <div className="d-flex bg-light rounded-2 p-1 border m-2 justify-content-between align-items-center column-direction">
                  <div className="font-weight-bold wrap-no mb-3 mt-3">
                    {i18n.t("enroll_amount")} : {totalPayment} {i18n.t("omr")}
                  </div>
                  <div
                    className="border border-warning text-warning rounded-2 p-0 px-1 m-0 mx-2 wrap-no mt-3 mb-3"
                    style={{
                      fontSize: "0.8rem",
                      height: "20px",
                    }}
                  >
                    {i18n.t("total_auctions")} : {selected_ids.length}
                  </div>
                  <button
                    className="btn btn-warning btn-sm rounded-5 btn-block"
                    disabled={selected_ids.length === 0 ? true : false}
                    onClick={() => {
                      this.gotoEnrollment();
                    }}
                  >
                    <i className="fa fa-gavel" />
                    &nbsp;<span>{i18n.t("enroll")}</span>
                  </button>
                </div>
              )}
            </Modal.Body>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
