import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import { forgotPassword } from "../../models/login";
import swal from "sweetalert";
export class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      sending: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendForgetPasswordEmail = this.sendForgetPasswordEmail.bind(this);
  }
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async sendForgetPasswordEmail() {
    this.setState({ sending: true });
    const response = await forgotPassword({ userId: this.state.input.userId });
    if (response.success) {
      this.setState({ sending: false });
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 10000,
      })
        .then((value) => {})
        .catch((response) => {
          this.setState({ sending: false });
          swal("Failed", response.message, "error");
        });
    } else {
      this.setState({ sending: false });
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="blue-menu-bg py-4"></div>
          <div className="content-bg">
            <div className="container py-5">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-lg-6 col-lg-3">
                  <div className="w-85 mx-auto">
                    <div className="blue-container">
                      <div className="mx-3 mb-3 mobile_size">
                        <img src="./assets/images/Vector_login.png" alt="" />
                        <span className="custom-text5 ms-1 text-white">
                          {i18n.t("forget_password")}
                        </span>
                      </div>

                      <div className="container m-auto p-0">
                        <div className="row">
                          <div className="bg-white user-brd rounded-4 px-0">
                            <div className="d-flex gap-5">
                              <div className="col ps-4 pt-4 pb-4 px-4">
                                <form id="forgotpsd" className="card-body mt-3">
                                  <div className="col-md-12 col-md-6 col-md-3">
                                    <div>
                                      <label for="username">
                                        {i18n.t("username_or_email")}
                                      </label>
                                    </div>
                                    <div className="mt-1">
                                      <input
                                        className="form-control custom-input rounded-pill"
                                        type="email"
                                        name="userId"
                                        value={this.state.input.userId}
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-12 col-md-6 col-md-3">
                                    <div className="text-center mt-4">
                                      <button
                                        type="button"
                                        className="btn custom_btn-color rounded-pill form-control text-white"
                                        disabled={!this.state.input.userId}
                                        onClick={this.sendForgetPasswordEmail}
                                      >
                                        {this.state.sending &&
                                          i18n.t("sending")}
                                        {!this.state.sending && i18n.t("send")}
                                      </button>
                                    </div>
                                  </div>

                                  <div className="horizontal-line"></div>

                                  <div className="text-center text-dark mb-0">
                                    Forget it,{" "}
                                    <Link to="/login" className="text-warning">
                                      send me back{" "}
                                    </Link>{" "}
                                    to the{" "}
                                    <Link to="/login" className="text-warning">
                                      sign in
                                    </Link>
                                    .
                                  </div>
                                </form>
                              </div>
                              <div className="col image-side mobile_hide">
                                <img
                                  className="img-fluid"
                                  src="./assets/images/1696936701627 1.png"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
