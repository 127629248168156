import React from "react";
import { createBulkAuction, uploadVehicles } from "../../models/auctions";
import { getAllGroups, getGroup } from "../../models/groups";
import swal from "sweetalert";
import i18n from "../../i18n";
import FileInputNew from "../helpers/fileInputNew";
import { baseUrl } from "../../constants/global";

export class CreateVehicleAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        is_extra_time: false,
        is_featured: true,
        is_zakath: false,
        is_vehicle: true,
      },
      row: [
        {
          vehicle_number: "",
          make: "",
          model: "",
          model_ar: "",
          mileage: "",
          mileage_ar: "",
          information_number: "",
          transmission_type: "",
          transmission_type_ar: "",
          extras: "",
          body_type: "",
          body_type_ar: "",
          fuel_type: "",
          fuel_type_ar: "",
          warranty: "",
          color: "",
          color_ar: "",
          engine_size: "",
          no_of_keys: "",
          document_type: "",
          start_amount: "",
          guarantee_amount: "",
          bid_increment: "",
          increment_numbers: "",
          start_date: "",
          end_date: "",
          location: "",
        },
      ],
      tempArray: {},
      errors: {},
      groups: {},
      enquiries: [{ label: "", value: "" }],
      jsonLabel: "",
      jsonValue: "",
      file_auction_uploader: null,
      //value: RichTextEditor.createEmptyValue(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.toggleZakath = this.toggleZakath.bind(this);
    this.toggleFeatured = this.toggleFeatured.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.uploadVehicleAuctions = this.uploadVehicleAuctions.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    input.category = this.props.category;
    input.vehicle_category = this.props.vehicle_category;
    input.organization = this.props.organization;
    console.log(this.props.organization);
    this.setState({ input });
    console.log(input);
    this.loadAllGroups();
  }

  onFileChange = (event) => {
    if (event.target.name === "file_auction_uploader") {
      this.setState({ file_auction_uploader: event.target.files[0] });
    }
  };

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.organization) {
      q += `organization=${input.organization}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadGroup(selected_group) {
    const response = await getGroup(selected_group);
    let result = response.data;
    let arr = this.state.row;
    for (let i = 0; i < arr.length; i++) {
      arr[i]["start_date"] = result.start_date;
      arr[i]["end_date"] = result.end_date;
      arr[i]["description"] = result.description;
      arr[i]["terms"] = result.terms;
      arr[i]["terms_arabic"] = result.terms_arabic;
      arr[i]["enquiry"] = result.enquiry;
    }

    let tempArray = this.state.tempArray;
    tempArray["start_date"] = result.start_date;
    tempArray["end_date"] = result.end_date;
    tempArray["description"] = result.description;
    tempArray["terms"] = result.terms;
    tempArray["terms_arabic"] = result.terms_arabic;
    tempArray["enquiry"] = result.enquiry;
    this.setState({ row: arr, tempArray });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.loadGroup(event.target.value);
    }
  }

  handleRowChange(event, i) {
    let row = this.state.row;
    row[i][event.target.name] = event.target.value;
    this.setState({ row });
  }

  toggleChange = () => {
    let input = this.state.input;
    input.is_extra_time = !this.state.input.is_extra_time;
    this.setState({
      input,
    });
  };

  toggleZakath = () => {
    let input = this.state.input;
    input.is_zakath = !this.state.input.is_zakath;
    this.setState({
      input,
    });
  };

  toggleFeatured = () => {
    let input = this.state.input;
    input.is_featured = !this.state.input.is_featured;
    this.setState({
      input,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.vehicle = this.state.row;
      payload.enquiries = this.state.enquiries;
      payload.auc_type = "vehicle";
      const response = await createBulkAuction(payload);
      if (response && response.data && response.data.success) {
        swal(i18n.t("success"), response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((value) => {
            window.location.href = "";
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  appendData(e) {
    e.preventDefault();
    let row = this.state.row;
    let tempArray = this.state.tempArray;
    row.push({
      vehicle_number: tempArray.vehicle_number,
      make: tempArray.make,
      model: tempArray.model,
      model_ar: tempArray.model_ar,
      mileage: tempArray.mileage,
      mileage_ar: tempArray.mileage_ar,
      information_number: tempArray.information_number,
      transmission_type: tempArray.transmission_type,
      transmission_type_ar: tempArray.transmission_type_ar,
      extras: tempArray.extras,
      body_type: tempArray.body_type,
      body_type_ar: tempArray.body_type_ar,
      fuel_type: tempArray.fuel_type,
      fuel_type_ar: tempArray.fuel_type_ar,
      warranty: tempArray.warranty,
      color: tempArray.color,
      color_ar: tempArray.color_ar,
      engine_size: tempArray.engine_size,
      no_of_keys: tempArray.no_of_keys,
      document_type: tempArray.document_type,
      start_amount: tempArray.start_amount,
      guarantee_amount: tempArray.guarantee_amount,
      bid_increment: tempArray.bid_increment,
      increment_numbers: tempArray.increment_numbers,
      start_date: tempArray.start_date,
      end_date: tempArray.end_date,
      location: tempArray.location,
    });
    this.setState({ row });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  async uploadVehicleAuctions() {
    let { input } = this.state;
    const formData = new FormData();
    formData.append("category", input.category);
    formData.append("vehicle_category", input.vehicle_category);
    formData.append("organization", input.organization);
    formData.append("group", input.group);
    if (this.state.file_auction_uploader) {
      formData.append(
        "file_auction_uploader",
        this.state.file_auction_uploader,
        this.state.file_auction_uploader.name
      );
    }
    const response = await uploadVehicles(formData);
    if (response.success) {
      swal(i18n.t("success"), "Auctions created", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          window.location.href = "";
        })
        .catch((response) => {
          swal(
            "Failed",
            "Error on auction create. Please check the data format in uploaded file",
            "error"
          );
        });
    } else {
      swal(
        "Failed",
        "Error on auction create. Please check the data format in uploaded file",
        "error"
      );
    }
  }

  render() {
    let { input } = this.state;
    input.vehicle_category = this.props.vehicle_category;
    let groups = this.state.groups;
    let rows = this.state.row;
    if (input.organization !== this.props.organization) {
      input.organization = this.props.organization;
      this.setState({ input });
      this.loadAllGroups();
    }
    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-md-12">
          <form
            id="UpdateProfile"
            className=" mb-0"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <div className="card-body" style={{ paddingTop: 0 }}>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">{i18n.t("group")}</label>
                    <select
                      className="form-control"
                      data-placeholder="Select Group"
                      name="group"
                      value={this.state.input.group}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("group")}--</option>
                      {groups.length > 0 &&
                        groups.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.group_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 d-flex align-items-center">
                  <div className="form-group d-flex">
                    <input
                      name="is_extra_time"
                      type="checkbox"
                      checked={this.state.input.is_extra_time}
                      onChange={this.toggleChange}
                    />
                    <label className="form-label">{i18n.t("extra_time")}</label>
                    {this.state.input.is_extra_time && (
                      <input
                        name="extra_time"
                        type="number"
                        className="form-control"
                        checked={this.state.input.extra_time}
                        onChange={this.handleChange}
                        placeholder={i18n.t("in_minutes")}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      name="is_featured"
                      type="checkbox"
                      checked={this.state.isFeatured}
                      onChange={this.handleChange}
                      style={{ float: "left", margin: "4px" }}
                    />
                    <label
                      className="form-label"
                      style={{ float: "left", marginRight: "1rem" }}
                    >
                      Is Featured
                    </label>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 d-flex align-items-center bg-light gap-2">
                  <div className="col-3">
                    <div className="form-group">
                      <label
                        className="form-label"
                        style={{ float: "left", marginRight: "1rem" }}
                      >
                        Use File upload to create auctions
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <FileInputNew
                      wrapperClass=""
                      className="form-control custom-input rounded-pill"
                      placeholder={i18n.t("choose_file")}
                      help_text="File should be a csv/xls/xlsx formats"
                      name="file_auction_uploader"
                      id="file_auction_uploader"
                      onChange={this.onFileChange}
                      format_url={baseUrl + "formats/auctions.csv"}
                    />
                  </div>
                  <div className="col-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-warning rounded-5 btn-block"
                      onClick={() => {
                        this.uploadVehicleAuctions();
                      }}
                    >
                      {i18n.t("upload")} & {i18n.t("submit")}
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-5">
                    <hr />
                  </div>
                  <div className="col-1 text-center">OR</div>
                  <div className="col-6">
                    <hr />
                  </div>
                </div>

                {rows.length > 0 &&
                  rows.map((item, i) => {
                    return (
                      <div className="container">
                        <div className="row bg bg-light">
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("number")} {i + 1}
                              </label>
                              <input
                                type="text"
                                required={true}
                                name="vehicle_number"
                                className="form-control"
                                placeholder={i18n.t("number") + " " + (i + 1)}
                                value={this.state.row[i].vehicle_number}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("make")}
                              </label>
                              <input
                                type="text"
                                name="make"
                                className="form-control"
                                placeholder={i18n.t("make")}
                                value={this.state.row[i].make}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("model")}
                              </label>
                              <input
                                type="text"
                                name="model"
                                className="form-control"
                                placeholder={i18n.t("model")}
                                value={this.state.row[i].model}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("model")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="model_ar"
                                className="form-control"
                                placeholder={i18n.t("model")}
                                value={this.state.row[i].model_ar}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("mileage")}
                              </label>
                              <input
                                type="text"
                                name="mileage"
                                className="form-control"
                                placeholder={i18n.t("mileage")}
                                value={this.state.row[i].mileage}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("mileage")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="mileage_ar"
                                className="form-control"
                                placeholder={i18n.t("mileage")}
                                value={this.state.row[i].mileage_ar}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("information_number")}
                              </label>
                              <input
                                type="text"
                                name="information_number"
                                className="form-control"
                                placeholder={i18n.t("information_number")}
                                value={this.state.row[i].information_number}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("transmission_type")}
                              </label>
                              <input
                                type="text"
                                name="transmission_type"
                                className="form-control"
                                placeholder={i18n.t("transmission_type")}
                                value={this.state.row[i].transmission_type}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("transmission_type")}({i18n.t("arabic")}
                                )
                              </label>
                              <input
                                type="text"
                                name="transmission_type_ar"
                                className="form-control"
                                placeholder={i18n.t("transmission_type")}
                                value={this.state.row[i].transmission_type_ar}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("extras")}
                              </label>
                              <input
                                type="text"
                                name="extras"
                                className="form-control"
                                placeholder={i18n.t("extras")}
                                value={this.state.row[i].extras}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("body_type")}
                              </label>
                              <input
                                type="text"
                                name="body_type"
                                className="form-control"
                                placeholder={i18n.t("body_type")}
                                value={this.state.row[i].body_type}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("body_type")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="body_type_ar"
                                className="form-control"
                                placeholder={i18n.t("body_type")}
                                value={this.state.row[i].body_type_ar}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("fuel_type")}
                              </label>
                              <input
                                type="text"
                                name="fuel_type"
                                className="form-control"
                                placeholder={i18n.t("fuel_type")}
                                value={this.state.row[i].fuel_type}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("fuel_type")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="fuel_type_ar"
                                className="form-control"
                                placeholder={i18n.t("fuel_type")}
                                value={this.state.row[i].fuel_type_ar}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("warranty")}
                              </label>
                              <input
                                type="text"
                                name="warranty"
                                className="form-control"
                                placeholder={i18n.t("warranty")}
                                value={this.state.row[i].warranty}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("color")}
                              </label>
                              <input
                                type="text"
                                name="color"
                                className="form-control"
                                placeholder={i18n.t("color")}
                                value={this.state.row[i].color}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("color")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                name="color_ar"
                                className="form-control"
                                placeholder={i18n.t("color")}
                                value={this.state.row[i].color_ar}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                                style={{ direction: "rtl" }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("engine_size")}
                              </label>
                              <input
                                type="text"
                                name="engine_size"
                                className="form-control"
                                placeholder={i18n.t("engine_size")}
                                value={this.state.row[i].engine_size}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("no_of_keys")}
                              </label>
                              <input
                                type="text"
                                name="no_of_keys"
                                className="form-control"
                                placeholder={i18n.t("no_of_keys")}
                                value={this.state.row[i].no_of_keys}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("document_type")}
                              </label>
                              <input
                                type="text"
                                name="document_type"
                                className="form-control"
                                placeholder={i18n.t("document_type")}
                                value={this.state.row[i].document_type}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("price")}
                              </label>
                              <input
                                type="text"
                                name="start_amount"
                                className="form-control"
                                placeholder="Price"
                                value={this.state.row[i].start_amount}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("gurantee_amount")}
                              </label>
                              <input
                                type="text"
                                name="guarantee_amount"
                                className="form-control"
                                placeholder={i18n.t("gurantee_amount")}
                                value={this.state.row[i].guarantee_amount}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("incr_amount")}
                              </label>
                              <input
                                type="text"
                                name="bid_increment"
                                className="form-control"
                                placeholder="Increment Amount"
                                value={this.state.row[i].bid_increment}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("incr_amount")}(comma seperated values)
                              </label>
                              <input
                                type="text"
                                name="increment_numbers"
                                className="form-control"
                                placeholder="eg: 100,200,300..."
                                value={this.state.row[i].increment_numbers}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("start_date")}{" "}
                              </label>
                              <input
                                type="datetime-local"
                                className="form-control"
                                placeholder="Start Date"
                                name="start_date"
                                value={this.state.row[i].start_date}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("end_date")}
                              </label>
                              <input
                                type="datetime-local"
                                name="end_date"
                                className="form-control"
                                placeholder="End date"
                                value={this.state.row[i].end_date}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("location")}
                              </label>
                              <input
                                type="text"
                                name="location"
                                className="form-control"
                                placeholder={i18n.t("location")}
                                value={this.state.row[i].location}
                                onChange={(event) => {
                                  this.handleRowChange(event, i);
                                }}
                              />
                            </div>
                          </div>
                          {rows && rows.length === i + 1 && (
                            <div className="col-sm-12 col-md-2">
                              <div
                                className="form-group"
                                style={{ textAlign: "right" }}
                              >
                                <label className="form-label">&nbsp;</label>
                                <button
                                  type="button"
                                  className="btn btn-info btn-block"
                                  onClick={this.appendData}
                                  style={{ marginRight: "20px" }}
                                >
                                  <i className="fa fa-plus"></i>
                                  <span>Add</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="card-footer" style={{ textAlign: "right" }}>
              <button type="submit" className="btn btn-primary">
                Save Auction
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
