import React from "react";
import { WalletFund } from "../helpers/walletFund";
import { HomeBanner } from "./homeBanner";
import { CustomTitle } from "../helpers/customTitle";
import i18n from "../../i18n";

export class WalletManage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <div className="row m-auto pt-3 bg-white">
          <CustomTitle
            title={i18n.t("mzad_wallet")}
            icon="wallet-icon.png"
            icon_type="img"
            need_mzad_icon={true}
          />
          <div className="col-lg-6 col-xl-6 col-md-6 m-auto">
            <WalletFund />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
