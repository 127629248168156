import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";
const user = getLoggedUser();

async function createCategory(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "categories",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllCategories(q) {
  return fetch(apiUrl + "categories?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getCategory(id) {
  return fetch(apiUrl + "categories/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateCategory(payload, id) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "categories/" + id,
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteCategory(id) {
  return fetch(apiUrl + "categories/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getCategories(q) {
  return fetch(`${apiUrl}active_categories?${q}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function getCategoryByID(id) {
  return fetch(apiUrl + "active_categories/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

export {
  createCategory,
  getAllCategories,
  getCategory,
  updateCategory,
  deleteCategory,
  getCategories,
  getCategoryByID,
};
