import { apiUrl } from "../constants/global";

var header = {
  "Content-Type": "application/json",
};
async function getAboutCounts() {
  return fetch(apiUrl + "about_counts", {
    method: "GET",
    headers: header,
  }).then((data) => data.json());
}

export default getAboutCounts;
