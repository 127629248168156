import React from "react";
import { ShowMorePanel } from "./helpers/showMorePanel";
import i18n from "../i18n";

let lng = localStorage.getItem("I18N_LANGUAGE");
export class Support extends React.Component {
  constructor() {
    super();
    this.state = {
      show_more_panel: false,
    };
  }

  handleToggle = (flag) => {
    this.setState({
      show_more_panel: flag,
    });
    this.props.callbackForMore(false);
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.show_more_panel && (
          <div className="row m-auto justify-content-center support-mai gap-3">
            <div className="col-3 user-brd margin rounded-5">
              <div className="">
                <div className="p-3 flex_column">
                  <div
                    className="border p-2 rounded-4 m-auto"
                    style={{ width: "fit-content" }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/support-1.png"
                      }
                      className="responsive_image"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="h5 text-center m-1 my-4">
                      {i18n.t("help_center")}
                    </h4>
                  </div>
                  {lng === "en" && (
                    <div>
                      <span className="model_body_content_desktop">
                        Welcome to our comprehensive Help Center, your go-to
                        resource for mastering our auction platform and
                        optimizing your bidding strategy. Whether you're a
                        seasoned bidder or new to the world of auctions, we're
                        here to empower you with step-by-step guides, frequently
                        asked questions (FAQs), and expert tips to ensure a
                        seamless and rewarding experience.
                      </span>
                      <span className="model_body_content_mobile">
                        Welcome to our comprehensive Help Center, your go-to
                        resource for mastering our auction platform and
                        optimizing your bidding strategy. Whether you're a
                        seasoned bidder or new to the world of auctions, we're
                        here to empower you with step-by-step guides, frequently
                        asked questions (FAQs), and expert tips to ensure a
                        seamless and rewarding experience.
                      </span>
                    </div>
                  )}
                  {lng === "ar" && (
                    <div>
                      <span className="model_body_content_desktop">
                        قم بزيارة مركز المساعدة الخاص بنا للحصول على المساعدة
                        خطوة بخطوة، والأسئلة الشائعة حول المنصة وذلك بتحقيق أقصى
                        استفادة من تجربة تقديم العطاءات الخاصة بك.
                      </span>
                      <span className="model_body_content_mobile">
                        قم بزيارة مركز المساعدة الخاص بنا للحصول على المساعدة
                        خطوة بخطوة، والأسئلة الشائعة حول المنصة وذلك بتحقيق أقصى
                        استفادة من تجربة تقديم العطاءات الخاصة بك.
                      </span>
                    </div>
                  )}
                  <div className="button_flex my-3">
                    <button
                      className="oval-button"
                      onClick={() => {
                        this.setState({ show_more_panel: true });
                        this.props.callbackForMore(true);
                      }}
                    >
                      <span className="button_text">{i18n.t("more_help")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 user-brd margin rounded-5">
              <div className="">
                <div className="p-3 flex_column">
                  <div
                    className="border p-2 rounded-4 m-auto"
                    style={{ width: "fit-content" }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/support-2.png"
                      }
                      className="responsive_image"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="h5 text-center m-1 my-4">
                      {i18n.t("call_us")}
                    </h4>
                  </div>
                  {lng === "en" && (
                    <div>
                      <span className="model_body_content_desktop">
                        Our dedicated support team is here to assist you. Don't
                        hesitate to contact us via our helpline for immediate
                        assistance with any inquiries you may have regarding our
                        auction services.
                      </span>
                      <span className="model_body_content_mobile">
                        Our dedicated support team is here to assist you. Don't
                        hesitate to contact us via our helpline for immediate
                        assistance with any inquiries you may have regarding our
                        auction services.
                      </span>
                    </div>
                  )}

                  {lng === "ar" && (
                    <div>
                      <span className="model_body_content_desktop">
                        لا تتردد في التواصل مع فريق الدعم المخصص لدينا من خلال
                        خط المساعدة الخاص بنا للحصول على مساعدة فورية بشأن كيفية
                        التسجيل في المنصة او دفع مبالغ الضمانات أو أسئلة أخرى قد
                        تكون لديكم بشأن كيفية إستخدام المنصة والمزايدة على
                        المزادات الموجودة.
                      </span>
                      <span className="model_body_content_mobile">
                        لا تتردد في التواصل مع فريق الدعم المخصص لدينا من خلال
                        خط المساعدة الخاص بنا للحصول على مساعدة فورية بشأن كيفية
                        التسجيل في المنصة او دفع مبالغ الضمانات أو أسئلة أخرى قد
                        تكون لديكم بشأن كيفية إستخدام المنصة والمزايدة على
                        المزادات الموجودة.
                      </span>
                    </div>
                  )}

                  <div className="button_flex my-3">
                    <a href="tel:80003030" className="oval-button">
                      <span className="button_text">{i18n.t("call_now")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 user-brd margin rounded-5">
              <div className="">
                <div className="p-3 flex_column">
                  <div
                    className="border p-2 rounded-4 m-auto"
                    style={{ width: "fit-content" }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/support-3.png"
                      }
                      className="responsive_image"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="h5 text-center m-1 my-4">
                      {i18n.t("chat_now")}
                    </h4>
                  </div>
                  {lng === "en" && (
                    <div>
                      <span className="model_body_content_desktop">
                        Experience real-time assistance and personalized
                        guidance as you navigate our auction platform and
                        immerse yourself in thrilling bidding opportunities.
                        Connect with our live chat support to get immediate
                        answers to your questions and expert advice tailored to
                        your needs.
                      </span>
                      <span className="model_body_content_mobile">
                        Experience real-time assistance and personalized
                        guidance as you navigate our auction platform and
                        immerse yourself in thrilling bidding opportunities.
                        Connect with our live chat support to get immediate
                        answers to your questions and expert advice tailored to
                        your needs.
                      </span>
                    </div>
                  )}

                  {lng === "ar" && (
                    <div>
                      <span className="model_body_content_desktop">
                        تواصل مع دعم الدردشة المباشرة لدينا للحصول على المساعدة
                        في الوقت الفعلي والتوجيه الشخصي أثناء إستخدام المنصة
                        الخاصة بنا والمشاركة في فرص المزايدة .
                      </span>
                      <span className="model_body_content_mobile">
                        تواصل مع دعم الدردشة المباشرة لدينا للحصول على المساعدة
                        في الوقت الفعلي والتوجيه الشخصي أثناء إستخدام المنصة
                        الخاصة بنا والمشاركة في فرص المزايدة .
                      </span>
                    </div>
                  )}
                  <div className="button_flex my-2">
                    <button className="oval-button">
                      <span className="button_text">{i18n.t("chat_now")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.show_more_panel && (
          <ShowMorePanel handleToggle={this.handleToggle} />
        )}
      </React.Fragment>
    );
  }
}
