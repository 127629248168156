import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "../sidebar";
import {
  getAllAuctionsByGroup,
  getAuctionBidReport,
} from "../../../models/auctions";
import { getAllGroups } from "../../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../../i18n";
import { Spinner } from "react-bootstrap";
import { apiUrl } from "../../../constants/global";
import { HomeBanner } from "../../home/homeBanner";

export class AuctionBiddingReport extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        downloadType: "pdf",
      },
      groups: {},
      titles: {},
      bidders: {},
      phone_loader: false,
      open_popup: false,
      bidder_loading: false,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e4f7ff",
            color: "#000000",
          },
        },
      },
      //actionsMemo : React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []),
      columns: [
        {
          name: "Bid ID",
          selector: "id",
          sortable: true,
          wrap: true,
        },
        {
          name: "Auction ID",
          selector: "auction_id",
          sortable: true,
          wrap: true,
        },
        {
          name: "Bid Amount",
          selector: "bid_amount",
          sortable: true,
          wrap: true,
        },
        {
          name: "User ID",
          selector: "user_id",
          sortable: true,
          wrap: true,
        },
        {
          name: "Name",
          wrap: true,
          sortable: true,
          cell: (row) => row.user.name,
        },
        {
          name: i18n.t("username"),
          wrap: true,
          sortable: true,
          cell: (row) => row.user.username,
        },
        {
          name: "Bid Date",
          selector: "created_at",
          sortable: true,
          cell: (row) => row.created_at,
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadPhoneNumbers = this.loadPhoneNumbers.bind(this);
    this.loadData = this.loadData.bind(this);
    this.downloadDoc = this.downloadDoc.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
    this.loadData();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.loadPhoneNumbers(event.target.value);
    }
  }

  downloadDoc() {
    let downloadType = this.state.input.downloadType;
    window.location.target = "_new";
    window.location.href =
      apiUrl + "auction_bid_report/export?q=" + downloadType;
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadPhoneNumbers(group) {
    if (group) {
      this.setState({ phone_loader: true });
      const response = await getAllAuctionsByGroup(group);
      if (response.data) {
        this.setState({ phone_loader: false });
        this.setState({ titles: response.data });
      }
    }
  }

  async loadData() {
    this.setState({ bidder_loading: true });
    const response = await getAuctionBidReport(this.state.input);
    if (response.data) {
      this.setState({ bidder_loading: false });
      this.setState({ bidders: response.data });
    }
  }

  render() {
    //let groups = this.state.groups;
    //let titles = this.state.titles;
    let bidders = this.state.bidders;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                {/* <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                >
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="fa fa-chevron-right"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="fa fa-chevron-left"></i>
                        )}
                      </span>
                      {i18n.t("search")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("auction")}
                          </label>

                          <div className="input-group">
                            <select
                              className="form-control"
                              data-placeholder="Select Class"
                              name="auction"
                              value={this.state.input.auction}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("auction")}--</option>
                              {titles.length > 0 &&
                                titles.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.title}
                                    </option>
                                  );
                                })}
                            </select>

                            {this.state.phone_loader && (
                              <span
                                className="input-group-addon"
                                style={{
                                  position: "absolute",
                                  marginLeft: "54%",
                                  marginTop: "10px",
                                }}
                              >
                                <i className="glyphicon glyphicon-refresh fa-spin"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.loadData}
                            disabled={!this.state.input.group}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr /> */}

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title" style={{ width: "65%" }}>
                      {i18n.t("result")}
                    </h3>
                    {bidders.length > 0 && (
                      <form
                        className="form-inline d-flex"
                        action=""
                        method="post"
                        target="_new"
                        style={{ width: "35%" }}
                        onSubmit={this.downloadDoc}
                      >
                        <div class="form-group mx-sm-3">
                          <select
                            className="form-control"
                            name="downloadType"
                            value={this.state.input.downloadType}
                            onChange={this.handleChange}
                          >
                            <option value={""}>Download As</option>
                            <option value={"pdf"}>PDF</option>
                            <option value={"excel"}>Excel</option>
                          </select>
                        </div>

                        {this.state.input.downloadType !== "" && (
                          <a
                            type="submit"
                            target="_new"
                            href={
                              apiUrl +
                              "auction_bid_report/export?q=" +
                              this.state.input.downloadType
                            }
                            className="btn btn-outline-danger"
                          >
                            {i18n.t("download")}
                          </a>
                        )}
                      </form>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.bidder_loading && (
                        <div className="text-center">
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!this.state.bidder_loading && !bidders.length && (
                        <div className="alert alert-danger">
                          {i18n.t("no_result")}
                        </div>
                      )}
                      {bidders.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            columns={this.state.columns}
                            data={bidders}
                            customStyles={this.state.customStyles}
                            highlightOnHover
                            pagination
                            paginationPerPage={15}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Bidders per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            //actions={this.state.actionsMemo}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
