import React from "react";
import { SideBar } from "./sidebar";
import { getLiveActivity } from "../../models/dashboard";
//import swal from "sweetalert";
import { Spinner } from "react-bootstrap";
import i18n from "../../i18n";
import Countdown from "react-countdown";
//import confetti from "canvas-confetti";

let lang = localStorage.getItem("I18N_LANGUAGE");

const FinishBid = () => (
  <h2 className="mb-5 font-weight-bold text-uppercase text-white">
    Congratulations for all the Winners !!!
  </h2>
);

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    //showFireWorks();
    return <FinishBid />;
  } else {
    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return (
      <div id="clock-c" className="countdown">
        <span className="">{days} : </span> {/*i18n.t("days")*/}
        <span className="">{hours} : </span> {/*i18n.t("hours")*/}
        <span className="">{minutes} : </span> {/*i18n.t("minutes")*/}
        <span className="">{seconds}</span> {/*i18n.t("seconds")*/}
      </div>
    );
  }
};

// var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
// function randomInRange(min, max) {
//   return Math.random() * (max - min) + min;
// }

// function showFireWorks() {
//   var particleCount = 100;
//   // since particles fall down, start a bit higher than random
//   confetti(
//     Object.assign({}, defaults, {
//       particleCount,
//       origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//     })
//   );
//   confetti(
//     Object.assign({}, defaults, {
//       particleCount,
//       origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//     })
//   );
// }

export class LiveActivity extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_menu: true,
      end_date: null,
      data: {},
      has_data: true,
      is_data_loading: false,
      input: {
        group: 0,
      },
    };
    this.loadLiveActivity = this.loadLiveActivity.bind(this);
  }

  componentDidMount() {
    //this.loadLiveActivity();
    setInterval(this.loadLiveActivity, 10000);
    window.scrollTo(0, 0);
    document.getElementById("main-top-header").style.display = "none";
  }

  async loadLiveActivity() {
    this.setState({ is_data_loading: true });
    const response = await getLiveActivity();
    if (response.success) {
      let data = response.data;
      let end_date = response.data.group.end_date;
      this.setState({ data: data, end_date: end_date });
      this.setState({ is_data_loading: false });
    }
    if (!response.success) {
      this.setState({ has_data: false });
      this.setState({ is_data_loading: false });
      //swal("Failed", response.message, "error");
    }
  }

  componentWillUnmount() {
    document.getElementById("main-top-header").style.display = "";
  }

  changeDateFormat(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let data = this.state.data;
    //let group = data.group;
    let top_5_biddings = data.top_5_biddings;
    let endTime = new Date(
      this.changeDateFormat(this.state.end_date)
    ).getTime();

    let bidStyle = {
      position: "absolute",
      right: "2rem",
      bottom: "0.1rem",
      fontSize: "1.5rem",
      fontWeight: "bold",
    };
    if (lang === "ar") {
      bidStyle = {
        position: "absolute",
        left: "2rem",
        bottom: "0.1rem",
        fontSize: "1.5rem",
        fontWeight: "bold",
      };
    }
    return (
      <React.Fragment>
        {/* <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("live_activity")}</h1>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {this.state.input.group && (
          <section className="mt-1 mb-1">
            <div className="floatingMenu">
              <button
                className="btn btn-danger btn-rounded btn-icon floatingMenuBtn"
                onClick={(e) => {
                  this.setState({ hide_menu: !this.state.hide_menu });
                }}
              >
                {!this.state.hide_menu && <i className="fa fa-minus"></i>}
                {this.state.hide_menu && <i className="fa fa-bars"></i>}
              </button>
            </div>

            <div className="mt-1 mb-1 p-3 d-flex justify-content-between">
              <img
                src="assets/images/mzad.png"
                style={{ width: "70px" }}
                alt=""
              />
              <img
                src="assets/images/logo-org.png"
                style={{ width: "50px" }}
                alt=""
              />
            </div>
            <div className="container">
              <div className="row">
                <div
                  className={
                    this.state.hide_menu
                      ? "d-none"
                      : "col-xl-3 col-lg-12 col-md-12"
                  }
                >
                  <SideBar />
                </div>
                {this.state.has_data && (
                  <div
                    className={
                      this.state.hide_menu
                        ? "col-xl-12 col-lg-12 col-md-12"
                        : "col-xl-9 col-lg-12 col-md-12"
                    }
                  >
                    <div className="row mb-3">
                      <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                        {/* <h2 className="mb-5 font-weight-bold text-uppercase ">
                        {group && group.group_name}
                      </h2> */}
                        <div>
                          {endTime && (
                            <Countdown date={endTime} renderer={renderer} />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="">
                          <div className="rounded bg-primary text-white shadow pt-5 pb-5 text-center mb-5">
                            <div className="row mt-5">
                              <div className="col-xl-12 col-lg-12 col-md-12 text-centered">
                                <div className="text-center">
                                  <button
                                    className="btn btn-outline-light btn-rounded btn-lg"
                                    style={{ fontSize: "2rem" }}
                                  >
                                    <b>
                                      {i18n.t("omr")}{" "}
                                      {!this.state.is_data_loading && data
                                        ? data.largest_amoount
                                        : ""}
                                      {this.state.is_data_loading && (
                                        <Spinner animation="border" size="sm" />
                                      )}
                                    </b>
                                  </button>

                                  <span className="text-white" style={bidStyle}>
                                    {!this.state.is_data_loading && data
                                      ? data.total_bids
                                      : ""}
                                    {this.state.is_data_loading && (
                                      <Spinner animation="border" size="sm" />
                                    )}{" "}
                                    {i18n.t("Bids")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row" style={{ height: "5rem" }}>
                      <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                        {this.state.is_data_loading && (
                          <div>
                            <Spinner animation="grow" variant="primary" />
                            &nbsp;
                            <Spinner animation="grow" variant="primary" />
                            &nbsp;
                            <Spinner animation="grow" variant="primary" />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="mb-7">
                          <div className="text-white shadow pt-5 pb-5 text-center mb-5">
                            {/* rounded bg-gradient-3 */}
                            <div
                              className={
                                this.state.hide_menu
                                  ? "d-flex justify-content-around"
                                  : "d-flex flex-wrap"
                              }
                            >
                              {top_5_biddings &&
                                top_5_biddings.length > 0 &&
                                top_5_biddings.map((item, index) => {
                                  return (
                                    <div className="">
                                      {/* <button className="btn btn-outline-primary btn-lg btn-rounded mb-2">
                                      {index + 1}
                                    </button> */}
                                      <button
                                        className="btn bg-primary text-white btn-rounded btn-lg"
                                        style={{ fontSize: "2rem" }}
                                      >
                                        <span>
                                          <span>
                                            <b>{item.number}</b>
                                          </span>{" "}
                                          <br />
                                          <span>
                                            {i18n.t("omr")} {item.amount}
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!this.state.has_data && (
                  <div
                    className={
                      this.state.hide_menu
                        ? "col-xl-12 col-lg-12 col-md-12"
                        : "col-xl-9 col-lg-12 col-md-12"
                    }
                  >
                    <div className="rounded bg-gradient-3 text-white shadow pt-5 pb-5 text-center mb-5">
                      <h2 className="mb-5 font-weight-bold text-uppercase">
                        {i18n.t("no_data_found")}
                      </h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
