import React from "react";
import i18n from "../../i18n";

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFileName: this.props.default_placeholder
        ? this.props.default_placeholder
        : i18n.t("no_file_choosen"),
    };
    this.fileInputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const selectedFile = event.target.files[0];
    this.setState({
      selectedFileName: this.props.default_placeholder
        ? this.props.default_placeholder
        : i18n.t("no_file_choosen"),
    });
    if (selectedFile && selectedFile.name) {
      this.setState({ selectedFileName: selectedFile.name });
    }
    this.props.onChange(event);
  }

  render() {
    let multiple = this.props.multiple ? true : false;
    let wrapperClass = `upload-btn-wrapper d-flex justify-content-between border border-grey rounded ${this.props.wrapperClass}`;
    return (
      <div class={wrapperClass}>
        <label className="mt-1 text-muted p-1 px-2">
          {this.state.selectedFileName}
        </label>
        <button className="btn btn-file rounded-0">
          <i className="fa fa-upload mx-1" />
          <span>{this.props.placeholder}</span>
        </button>
        <input
          type="file"
          ref={this.fileInputRef}
          className={this.props.className}
          placeholder={this.props.placeholder}
          id={this.props.id}
          name={this.props.name}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          multiple={multiple}
          accept={this.props.accept}
        />
      </div>
    );
  }
}

export default FileInput;
