import React from "react";
import Slider from "react-slick";
import { SliderContent } from "../helpers/sliderContent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getBanners } from "../../models/banners";
import { MzadLoader } from "../helpers/mzadLoader";
import i18n from "../../i18n";

var settings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  accessibility: true,
  autoplay: true,
  centerPadding: "0px",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
      },
    },
  ],
};
export class HomeBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      is_more_help: false,
      limit: 10,
      banners: [],
      is_loading: false,
    };
    this.loadBanners = this.loadBanners.bind(this);
  }

  componentDidMount() {
    this.loadBanners();
  }

  async loadBanners() {
    this.setState({ is_loading: true });
    const response = await getBanners();
    if (response) {
      let banners = response.data;
      this.setState({ banners: banners, is_loading: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          class="carousel slide position-relative"
          data-bs-ride="carousel"
          id="carouselExampleAutoplaying"
        >
          <MzadLoader loading={this.state.is_loading} size="md" />
          {!this.state.is_loading && (
            <Slider {...settings}>
              {this.state.banners.map((x, i) => {
                return (
                  <SliderContent
                    key={i}
                    title={x.title}
                    title_ar={"مولدات الديزل"}
                    amount={18000 + i}
                    auctionId={i + 1}
                    index={i}
                    img={x.banner}
                    isAuction={x.isAuction}
                    show_store_buttos={x.show_store_buttos}
                  />
                );
              })}
            </Slider>
          )}

          {/* Support Button */}
          <div class="support-button">
            <button
              class="btn btn-primary"
              onClick={() => {
                this.setState({ showPopup: !this.state.showPopup });
                this.props.callbackForSupport(true);
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/material-symbols_support-agent-sharp.png"
                }
                alt="Support"
              />
              <span class="ms-1">{i18n.t("support")}</span>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
