import React from "react";
import { SideBar } from "./sidebar";
import { getAuctionsTracking } from "../../models/auctions";
import i18n from "../../i18n";
import { MzadLoader } from "../helpers/mzadLoader";
import { HomeBanner } from "../home/homeBanner";
import { getAllGroups } from "../../models/groups";
import { TrackItem } from "./helper/track";
import { getOrganizations } from "../../models/organization";
import { getLoggedUser } from "../../state/user";

const user = getLoggedUser();
const objectToQueryString = (obj) => {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
};
export class AuctionTracker extends React.Component {
  constructor() {
    super();
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    this.state = {
      input: params,
      groups: [],
      auctions: [],
      clients: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { input } = this.state;
    if (typeof input.type === "undefined") {
      input.type = "";
    }
    this.loadAllAuctions(input);
    //this.loadAllGroups();
    this.loadAllOrganisation();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
    if (event.target.name === "type") {
      this.loadAllOrganisation();
    }
    this.setState({ input });
    this.loadAllAuctions(input);
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }
  async loadAllOrganisation() {
    let { input } = this.state;
    let q = "";
    if (typeof input.type !== "undefined") {
      q = `auction_type=${input.type}`;
    }
    if (user && user.id) {
      q += `&user=${user.id}`;
    }
    const response = await getOrganizations(q);
    let clients = response.data;
    this.setState({ clients });
  }

  async loadAllAuctions(params) {
    let q = "";
    if (typeof params !== undefined && params) {
      q = objectToQueryString(params);
    }
    this.setState({ is_loading: true });
    const response = await getAuctionsTracking(`?${q}`);
    if (response) {
      let auctions = response.data;
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ auctions: auctions, is_loading: false });
    }
  }

  render() {
    let { auctions, groups, clients } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between track-head">
                    <h3 className="card-title d-flex">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/gridicons_line-graph.png"
                          }
                          alt=""
                          width="25"
                        />
                      </span>
                      {i18n.t("tracking_auctions")}
                    </h3>

                    <div className="d-flex gap-1">
                      <select
                        className="form-select textbox fs15 text-gray filter-icon rounded-2 w-200"
                        data-placeholder="Select Category"
                        name="type"
                        value={this.state.input.type}
                        onChange={this.handleChange}
                      >
                        <option value="">{i18n.t("select_type")}</option>
                        <option value="active">{i18n.t("active")}</option>
                        <option value="upcoming">{i18n.t("upcoming")}</option>
                        <option value="featured">{i18n.t("featured")}</option>
                        <option value="previous">{i18n.t("previous")}</option>
                        <option value="direct">{i18n.t("direct")}</option>
                      </select>
                      <select
                        className="form-select textbox fs15 text-gray filter-icon rounded-2 w-200"
                        data-placeholder="Select Category"
                        name="client"
                        value={this.state.input.client}
                        onChange={this.handleChange}
                      >
                        <option value="">{i18n.t("select_client")}</option>
                        {clients.length > 0 &&
                          clients.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.organization_name}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        className="fform-select textbox fs15 text-gray filter-icon rounded-2 w-200"
                        data-placeholder="Select Group"
                        name="group"
                        value={this.state.input.group}
                        onChange={this.handleChange}
                      >
                        <option value="">{i18n.t("select_group")}</option>
                        {groups.length > 0 &&
                          groups.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.group_name}
                              </option>
                            );
                          })}
                      </select>
                      {(this.state.input.client ||
                        this.state.input.group ||
                        this.state.input.type) && (
                        <a
                          className="btn btn-outline-danger rounded-5 p-0 px-1"
                          href="/auction_tracker"
                        >
                          <i className="mdi mdi-close" />
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="card-body">
                    {this.state.is_loading && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(255, 255, 255, 0.7)",
                          zIndex: "99",
                        }}
                      >
                        <MzadLoader
                          animation="grow"
                          size="md"
                          loading={this.state.is_loading}
                          style={{ position: "relative" }}
                        />
                      </div>
                    )}

                    <div className="track-wrapper">
                      {auctions.length > 0 && (
                        <div className="border rounded-4 p-3">
                          {auctions.map((item, i) => {
                            return <TrackItem item={item} index={i} />;
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  {!this.state.is_loading && auctions.length === 0 && (
                    <div className="m-3 text-center p-3 text-red">
                      {i18n.t("no_auctions_found")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
