import {apiUrl} from "../constants/global";
import axios from "axios";

async function registerUser(payload){
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data: response } = await axios.post(
        apiUrl + "register",
        payload,
        config
      );
    return response;
    } catch (error) {
      console.log(error);
    }
  }

  export default registerUser;