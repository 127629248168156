import { apiUrl } from "../constants/global";
import { getCurrentLocale, getLoggedUser } from "../state/user";

const user = getLoggedUser();
const lang = getCurrentLocale();

async function getNotifications() {
  return fetch(`${apiUrl}get_user_notifications?locale=${lang}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateUserNotification(id) {
  return fetch(`${apiUrl}update_user_notification?existing_id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getAllUserNotifications(q) {
  return fetch(`${apiUrl}get_all_user_notifications?locale=${lang}${q}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function deleteUserNotification(payload) {
  return fetch(apiUrl + "delete_user_notification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

export {
  getNotifications,
  updateUserNotification,
  getAllUserNotifications,
  deleteUserNotification,
};
