import React from "react";
import { Link } from "react-router-dom";
import { AuctionCard } from "../helpers/auctionCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getDirectAuctions } from "../../../models/auctions";
import i18n from "../../../i18n";
import { GroupCard } from "../helpers/GroupCard";
import { SkeletonHomeCard } from "../../helpers/skeletons/skeletonCards";
import { NextArrow, PrevArrow } from "../../helpers/carouselArrows";

let current_lng = localStorage.getItem("I18N_LANGUAGE");

var settings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 10000,
  pauseOnHover: true,
  rtl: current_lng === "ar" ? true : false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  appendDots: (dots) => (
    <div
      style={{
        backgroundColor: "#FFF",
        borderRadius: "25px",
        padding: "10px",
      }}
    >
      <ul style={{ margin: "0px" }}>
        {dots.map((item, index) => {
          console.log(item, index);
          return (
            <li key={index} className={item.props.className}>
              {item.props.children}
            </li>
          );
        })}
      </ul>
    </div>
  ),
  customPaging: (i) => <div className="slick-custom-dots">{i + 1}</div>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export class DirectAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      limit: 10,
      auctions: [],
      is_loading: false,
    };
  }

  componentDidMount() {
    this.loadAllAuctions(this.state.limit);
  }

  async loadAllAuctions(limit) {
    let q = "";
    if (limit) {
      q = "limit=" + limit;
    }
    this.setState({ is_loading: true });
    const response = await getDirectAuctions(q);
    if (response) {
      let auctions = response.data;
      let meta = response.meta;
      this.setState({ auctions: auctions, meta, is_loading: false });
    }
  }

  sortByOrder(data) {
    let result = data;
    if (data.length) {
      result = data.sort((a, b) => a.order - b.order);
    }
    return result;
  }

  render() {
    let auctions = this.sortByOrder(this.state.auctions);
    return (
      <React.Fragment>
        <div className="row">
          {this.state.is_loading && (
            <div style={{ textAlign: "center" }}>
              <SkeletonHomeCard />
            </div>
          )}

          {auctions.length > 0 && (
            <Slider {...settings} slidesToShow={auctions.length === 1 ? 1 : 3}>
              {auctions.map((item, i) => {
                return !item.is_a_group ? (
                  <AuctionCard
                    key={i}
                    auction={item}
                    auctionId={item.id}
                    total_items={auctions.length}
                    price={item.start_amount}
                    title={item.title}
                    title_ar={item.title_ar}
                    curr_amount={item.current_amount}
                    end_date={item.end_date_formatted}
                    images={item.images}
                    total_views={item.total_views}
                    type={this.props.type}
                    can_bid={false}
                    getImagePopup={this.props.getImagePopup}
                  />
                ) : (
                  <GroupCard
                    key={i}
                    group={item}
                    total_items={auctions.length}
                    can_bid={false}
                    type={this.props.type}
                  />
                );
              })}
            </Slider>
          )}

          {auctions.length > Number(9) && (
            <div className="load-more">
              <Link to={"/auctions?sec=main&type=direct"}>
                {i18n.t("view_all")}{" "}
                {current_lng === "en" && (
                  <i className="fa fa-arrow-circle-o-right" />
                )}
                {current_lng === "ar" && (
                  <i className="fa fa-arrow-circle-o-left" />
                )}
              </Link>
            </div>
          )}

          {!this.state.is_loading && !auctions.length && (
            <div className="py-5 px-5" style={{ minHeight: "50vh" }}>
              <div
                className="border border-danger p-3 text-danger m-auto"
                style={{ height: "max-content", width: "250px" }}
              >
                {i18n.t("no_auctions_found")}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
