import React, { useEffect } from "react"; //useState
import { gapi, loadAuth2 } from "gapi-script";
import GoogleLogin from "react-google-login";
import { googleLogin } from "../../models/users";
import swal from "sweetalert";
import i18n from "../../i18n";

//import i18n from "../../i18n";
const clientId =
  "476511993809-amon7vbi4ct6680ocgm04hbu856a5prc.apps.googleusercontent.com";

function GoogleSignin() {
  //const [profile, setProfile] = useState([]);
  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: [
  //         "https://www.googleapis.com/auth/userinfo.email",
  //         "https://www.googleapis.com/auth/userinfo.profile",
  //         "openid",
  //       ],
  //     });
  //   };
  //   gapi.load("client:auth2", initClient);
  // });

  useEffect(() => {
    const setAuth2 = async () => {
      const auth2 = await loadAuth2(gapi, clientId, "email");
      if (auth2.isSignedIn.get()) {
        updateUser(auth2.currentUser.get());
      } else {
        attachSignin(document.getElementById("gButton"), auth2);
      }
    };
    setAuth2();
  }, []);

  const attachSignin = (element, auth2) => {
    auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        updateUser(googleUser);
      },
      (error) => {
        console.log(JSON.stringify(error));
      }
    );
  };
  const updateUser = (currentUser) => {
    const name = currentUser.getBasicProfile().getName();
    const profileImg = currentUser.getBasicProfile().getImageUrl();
    console.log(name, profileImg);
  };

  const onSuccess = async (credentialResponse) => {
    let { profileObj } = credentialResponse;
    let response = await googleLogin(profileObj);

    if ("token" in response.data) {
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  const onError = async (credentialResponse) => {
    console.log(credentialResponse);
  };

  return (
    <React.Fragment>
      <GoogleLogin
        className="icon-size border-none rounded-circle mx-1 shadow-none btn-lg"
        clientId={clientId}
        buttonText=""
        onSuccess={onSuccess}
        onFailure={onError}
        cookiePolicy={"single_host_origin"}
        style={{ justifyContent: "center" }}
      />
    </React.Fragment>
  );
}
export default GoogleSignin;
