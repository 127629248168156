import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export class TableSkeleton extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let baseColor = "#aeaeae";
    const rows = Array.from({ length: this.props.rows }, (_, index) => index);
    const columns = Array.from(
      { length: this.props.columns },
      (_, index) => index
    );

    return (
      <React.Fragment>
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                {rows.map((rowIndex) => (
                  <th key={rowIndex}>
                    <Skeleton
                      height={10}
                      width={70}
                      baseColor={baseColor}
                      className="rounded-5"
                      containerClassName="mx-1"
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {columns.map((columnIndex) => (
                <tr key={columnIndex}>
                  {rows.map((rowIndex) => (
                    <td key={rowIndex}>
                      <Skeleton
                        height={10}
                        width={70}
                        baseColor={baseColor}
                        className="rounded-5"
                        containerClassName="mx-1"
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}
