import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { getPayments, getUserPayments } from "../../models/payments";
import { getLoggedUser } from "../../state/user";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import { apiUrl } from "../../constants/global";
import { HomeBanner } from "../home/homeBanner";

const user = getLoggedUser();
const types = {
  enroll: i18n.t("enroll"),
  sitevisit: i18n.t("site_visit"),
};
const columns = [
  {
    name: "ID",
    selector: "id",
    sortable: true,
    wrap: true,
    width: "50px",
  },
  {
    name: i18n.t("type"),
    selector: "type",
    sortable: true,
    cell: (row) => types[row.type],
  },
  {
    name: i18n.t("user"),
    selector: "user.id",
    sortable: true,
    cell: (row) => row.user.id,
  },
  {
    name: i18n.t("name"),
    selector: "user.name",
    sortable: true,
    cell: (row) => row.user.name,
  },
  {
    name: i18n.t("group"),
    selector: "group",
    sortable: true,
    cell: (row) => row.group.group_name,
  },
  {
    name: i18n.t("price"),
    selector: "amount",
  },
  {
    name: i18n.t("payment_date"),
    selector: "payment_date",
    wrap: true,
  },
  {
    name: i18n.t("invoice"),
    selector: "reference_number",
    wrap: true,
  },
  {
    name: i18n.t("download"),
    button: true,
    cell: (row) => (
      <a
        className="btn btn-info"
        href={apiUrl + "payment/" + row.id}
        target="_blank"
        rel="noreferrer"
      >
        {i18n.t("download")}
      </a>
    ),
  },
];
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};
export class Payments extends React.Component {
  constructor() {
    super();
    this.state = {
      payments: {},
    };
  }
  componentDidMount() {
    this.loadPayments();
  }

  async loadPayments() {
    let response = null;
    if (user.role === "A") {
      response = await getPayments();
    }
    if (user.role === "U") {
      response = await getUserPayments();
    }
    let payments = response.data;
    this.setState({ payments: payments });
  }
  render() {
    let payments = this.state.payments;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("payments")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {payments.length === 0 && (
                        <div className="alert alert-danger">
                          {i18n.t("no_payments_found")}
                        </div>
                      )}
                      {payments.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            columns={columns}
                            data={payments}
                            customStyles={customStyles}
                            highlightOnHover
                            pagination
                            paginationPerPage={15}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Payments per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
