import React from "react";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import { uploadTermsFile, getAuction } from "../../models/auctions";
import { AuctionTabs } from "./helper/auction_tabs";
import { Spinner } from "react-bootstrap";
import { HomeBanner } from "../home/homeBanner";
import FileInput from "../helpers/fileInput";
import i18n from "../../i18n";
export class TermsPanel extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      auction: {},
      is_loading: false,
      is_loading_terms: false,
      is_loading_terms_pay: false,
      auctionId: auctionId,
      input: {},
      errors: {},
      file_terms: null,
      file_payment_terms: null,
    };
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount() {
    this.loadAuction();
  }

  onFileChange = (event) => {
    if (event.target.files[0].name.split(".").pop().toLowerCase() !== "pdf") {
      swal("Failed", "Wrong file format", "error");
      return;
    }
    if (event.target.name === "file_terms") {
      this.setState({ file_terms: event.target.files[0] });
      this.uploadFile(event, "T");
    }
    if (event.target.name === "file_payment_terms") {
      this.setState({ file_payment_terms: event.target.files });
      this.uploadFile(event, "P");
    }
  };

  async loadAuction() {
    this.setState({ is_loading: true });
    const response = await getAuction(this.state.auctionId);
    if (response) {
      let auction = response.data;
      this.setState({ auction });
      this.setState({ is_loading: false });
    }
  }

  async uploadFile(event, fileType) {
    event.preventDefault();
    if (!this.state.auctionId || !event.target.files[0].name) {
      swal("Failed", "File Missing", "error");
      return;
    }
    const formData = new FormData();

    if (fileType === "T") {
      this.setState({ is_loading_terms: true });
      formData.append(
        "file_terms",
        event.target.files[0],
        event.target.files[0].name
      );
    }
    if (fileType === "P") {
      this.setState({ is_loading_terms_pay: true });
      formData.append(
        "file_payment_terms",
        event.target.files[0],
        event.target.files[0].name
      );
    }

    formData.append("auction_id", this.state.auctionId);
    formData.append("type", fileType);
    const response = await uploadTermsFile(formData);
    if (response) {
      if (fileType === "P") {
        this.setState({ is_loading_terms_pay: false });
      }
      if (fileType === "T") {
        this.setState({ is_loading_terms: false });
      }
      if (response.success) {
        this.setState({ auction: response.data });
      } else {
        swal(
          "Failed",
          "Some error occured!! Please make sure that you are uploading video file.",
          "error"
        );
      }
    }
  }

  render() {
    let { auction } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div
                className="col-xl-9 col-lg-12 col-md-12"
                style={{ padding: 0 }}
              >
                <AuctionTabs activeTab="terms" />

                <div className="container mt-2 bg-white border p-2 rounded-4 m-1">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-5 border p-3 rounded-3 m-2 mx-3">
                      <div>
                        <h4 className="h5">Upload Terms and Conditions</h4>
                        <span style={{ fontSize: "0.8rem" }}>
                          (Allowed Formats : .pdf only)
                        </span>
                      </div>
                      <div className="form-group ">
                        <FileInput
                          className="form-control"
                          placeholder={i18n.t("choose_file")}
                          default_placeholder={i18n.t("choose_file")}
                          id="file_terms"
                          name="file_terms"
                          onChange={this.onFileChange}
                        />
                      </div>
                      {this.state.is_loading_terms && (
                        <div
                          style={{
                            width: "97%",
                            height: "60px",
                            background: "#f4f4f4",
                            marginTop: "0.5rem",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                            textAlign: "center",
                            padding: "1rem",
                          }}
                        >
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!this.state.is_loading_terms && auction.file_terms && (
                        <div
                          style={{
                            width: "97%",
                            height: "60px",
                            background: "#f4f4f4",
                            marginTop: "0.5rem",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                            textAlign: "center",
                            padding: "1rem",
                          }}
                        >
                          <a
                            target="_new"
                            href={auction.file_terms}
                            className="text-danger"
                          >
                            <i className="fa fa-file-o" />
                            &nbsp;
                            {i18n.t("view")}/{i18n.t("download")}
                          </a>
                        </div>
                      )}
                    </div>

                    <div className="col-5 border p-3 rounded-3 m-2 mx-3">
                      <div>
                        <h4 className="h5">Upload Payment Terms</h4>
                        <span style={{ fontSize: "0.8rem" }}>
                          (Allowed Formats : .pdf only)
                        </span>
                      </div>
                      <div className="form-group ">
                        <FileInput
                          className="form-control"
                          placeholder={i18n.t("choose_file")}
                          default_placeholder={i18n.t("choose_file")}
                          id="file_payment_terms"
                          name="file_payment_terms"
                          onChange={this.onFileChange}
                        />
                      </div>
                      {this.state.is_loading_terms_pay && (
                        <div
                          style={{
                            width: "97%",
                            height: "60px",
                            background: "#f4f4f4",
                            marginTop: "0.5rem",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                            textAlign: "center",
                            padding: "1rem",
                          }}
                        >
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!this.state.is_loading_terms_pay &&
                        auction.file_payment_terms && (
                          <div
                            style={{
                              width: "97%",
                              height: "60px",
                              background: "#f4f4f4",
                              marginTop: "0.5rem",
                              marginLeft: "0.5rem",
                              marginRight: "0.5rem",
                              textAlign: "center",
                              padding: "1rem",
                            }}
                          >
                            <a
                              target="_new"
                              href={auction.file_payment_terms}
                              className="text-danger"
                            >
                              <i className="fa fa-file-o" />
                              &nbsp;
                              {i18n.t("view")}/{i18n.t("download")}
                            </a>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
