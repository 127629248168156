import React from "react";
import i18n from "../../../i18n";
import swal from "sweetalert";

export class AuctionsDetailTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { name: i18n.t("details"), tab: "details", icon: "fa fa-info-circle" },
        {
          name: i18n.t("description"),
          tab: "description",
          icon: "fa fa-file-text",
        },
        {
          name: i18n.t("enquiry"),
          tab: "enquiry",
          icon: "fa fa-question-circle",
        },
        { name: i18n.t("video"), tab: "video", icon: "fa fa-video-camera" },
        {
          name: i18n.t("contracts"),
          tab: "contracts",
          icon: "fa fa-files-o",
        },
        { name: i18n.t("terms_conditions"), tab: "terms", icon: "fa fa-gavel" },
        // { name: i18n.t("location"), tab: "location" },
      ],
    };
  }

  render() {
    let auction = this.props.auction;
    let lng = this.props.lng;
    let vehicle = false;
    if (auction.is_vehicle) {
      vehicle = auction.vehicle_info;
    }

    let enquiries = auction.enquiry;
    let enquiry_length =
      (auction && auction.enquiry && auction.enquiry.length) || 0;

    let details = auction.details;
    let details_length =
      (auction && auction.details && auction.details.length) || 0;

    let downloads = auction.downloads;
    let downloads_length =
      (auction && auction.downloads && auction.downloads.length) || 0;

    let video = auction.video;
    let expand_class =
      "d-flex border justify-content-between rounded-5 text-dark p-2 px-3 bg-light active ad-head";
    let collapse_class =
      "d-flex border justify-content-between rounded-5 mb-3 text-dark p-2 px-3 bg-light ad-head";
    return (
      <React.Fragment>
        <div className="bg-white rounded-4 border p-3">
          <div className="">
            <div className="border-0 mb-1">
              <div className="">
                <a
                  href={true}
                  className={
                    this.state.expand_details ? expand_class : collapse_class
                  }
                  onClick={() => {
                    this.setState({
                      expand_details: !this.state.expand_details,
                    });
                  }}
                >
                  <div>
                    <span className="details-icon">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/details.png"
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                        alt=""
                      />
                    </span>
                    <span className="details-title">{i18n.t("details")}</span>
                  </div>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_details
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_details && (
                  <div className="card card-details active">
                    <div className="row">
                      <div className="col-xl-12 col-md-12">
                        <div className="table-responsive">
                          {details_length > 0 && (
                            <table className="table table-bordered border-top mb-0 rounded-0">
                              <tbody>
                                {details.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {lng === "ar" && item.label_ar
                                          ? item.label_ar
                                          : item.label}
                                      </td>
                                      <td>
                                        {lng === "ar" && item.value_ar
                                          ? item.value_ar
                                          : item.value}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                          {vehicle && auction.is_vehicle && (
                            <div>
                              <table className="table-cust">
                                <tr>
                                  <td>{i18n.t("make")}</td>
                                  <td>{vehicle.make}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("mileage")}</td>
                                  <td>{vehicle.mileage}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("model")}</td>
                                  <td>{vehicle.model}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("body_type")}</td>
                                  <td>{vehicle.body_type}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("fuel_type")}</td>
                                  <td>{vehicle.fuel_type}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("transmission_type")}</td>
                                  <td>{vehicle.transmission_type}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("color")}</td>
                                  <td>{vehicle.color}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("vehicle_number")}</td>
                                  <td>{vehicle.vehicle_number}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("warranty")}</td>
                                  <td>{vehicle.warranty}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("document_type")}</td>
                                  <td>{vehicle.document_type}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("engine_size")}</td>
                                  <td>{vehicle.engine_size}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("extras")}</td>
                                  <td>{vehicle.extras}</td>
                                </tr>

                                <tr>
                                  <td>{i18n.t("information_number")}</td>
                                  <td>{vehicle.information_number}</td>
                                </tr>
                                <tr>
                                  <td>{i18n.t("no_of_keys")}</td>
                                  <td>{vehicle.no_of_keys}</td>
                                </tr>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <a
                  href={true}
                  className={
                    this.state.expand_description
                      ? expand_class
                      : collapse_class
                  }
                  onClick={() => {
                    this.setState({
                      expand_description: !this.state.expand_description,
                    });
                  }}
                >
                  <div>
                    <span className="details-icon">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/description.png"
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                        alt=""
                      />
                    </span>
                    <span className="details-title">
                      {i18n.t("description")}
                    </span>
                  </div>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_description
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_description && (
                  <div className="card card-details active mt-2">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="col-xl-12 col-md-12"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {lng === "ar"
                            ? auction.description_ar
                            : auction.description}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <a
                  href={true}
                  className={
                    this.state.expand_enquiry ? expand_class : collapse_class
                  }
                  onClick={() => {
                    this.setState({
                      expand_enquiry: !this.state.expand_enquiry,
                    });
                  }}
                >
                  <div>
                    <span className="details-icon">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/inquiries.png"
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                        alt=""
                      />
                    </span>
                    <span className="details-title">{i18n.t("enquiry")}</span>
                  </div>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_enquiry
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_enquiry && (
                  <div className="card card-details active">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive">
                          {enquiry_length > 0 && (
                            <table className="table table-bordered border-top mb-0 rounded-0">
                              <tbody>
                                {enquiries.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {lng === "ar" && item.label_ar
                                          ? item.label_ar
                                          : item.label}
                                      </td>
                                      <td>
                                        {lng === "ar" && item.value_ar
                                          ? item.value_ar
                                          : item.value}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <a
                  href={true}
                  className={
                    this.state.expand_video ? expand_class : collapse_class
                  }
                  onClick={() => {
                    this.setState({
                      expand_video: !this.state.expand_video,
                    });
                  }}
                >
                  <div>
                    <span className="details-icon">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/video.png"
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                        alt=""
                      />
                    </span>
                    <span className="details-title">{i18n.t("video")}</span>
                  </div>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_video
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_video && (
                  <div className="card card-details active">
                    {video && (
                      <video
                        controls
                        style={{
                          width: "100%",
                          height: "auto",
                          position: "relative",
                        }}
                      >
                        <source src={video} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    )}
                  </div>
                )}

                <a
                  href={true}
                  className={
                    this.state.expand_contracts ? expand_class : collapse_class
                  }
                  onClick={() => {
                    this.setState({
                      expand_contracts: !this.state.expand_contracts,
                    });
                  }}
                >
                  <div>
                    <span className="details-icon">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/contract.png"
                        }
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                        alt=""
                      />
                    </span>
                    <span className="details-title">{i18n.t("contracts")}</span>
                  </div>
                  <span className="details-toggle-icon">
                    <i
                      className={
                        this.state.expand_contracts
                          ? "fa fa-angle-up"
                          : "fa fa-angle-down"
                      }
                    />
                  </span>
                </a>
                {this.state.expand_contracts && (
                  <div className="card card-details active rounded-0">
                    {downloads_length > 0 && (
                      <div className="row mt-3 mb-3 documents">
                        {downloads.map((item, i) => {
                          return (
                            <div className="col">
                              <div className="bg-white rounded-4 border">
                                <div className="d-flex p-2">
                                  <div
                                    className="flex-background rounded-2 p-2 overflow-hidden"
                                    style={{ height: "45px" }}
                                  >
                                    <i
                                      className="fa fa-file-o"
                                      style={{
                                        color: "#FF0000",
                                        fontSize: "1.8rem",
                                      }}
                                    />
                                  </div>
                                  <div className="p-2 w-75 p-1">
                                    <span className="custom_text9 pt-1 d-flex">
                                      {lng === "ar" && item.label_ar
                                        ? item.label_ar
                                        : item.label}
                                    </span>
                                  </div>
                                  <div className="">
                                    <div>
                                      <a
                                        className="btn btn-dark btn-block btn-sm rounded-2"
                                        href={item.file}
                                        target="_new"
                                      >
                                        <i
                                          className="fa fa-eye"
                                          style={{ fontSize: "1rem" }}
                                        />
                                        &nbsp;{i18n.t("download")}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
                {/*<div className="p-2 flex-shrink-1">
                              <div>
                                <a
                                  className="btn button_colour mt-2"
                                  href={item.file}
                                  target="_new"
                                >
                                  <img
                                    width="20"
                                    height="20"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/material-symbols_download.png"
                                    }
                                    alt=""
                                  />
                                  {lng === "ar" && item.label_ar
                                    ? item.label_ar
                                    : item.label}
                                  <span className="ms-1 custom_text12">
                                    {i18n.t("download")}
                                  </span>
                                </a>
                              </div>
                                  </div>*/}

                <div className="row">
                  <div className="col-md-6 col-sm-12 col-lg-6">
                    <a
                      href={true}
                      className={
                        this.state.expand_pay_terms
                          ? expand_class
                          : collapse_class
                      }
                      onClick={() => {
                        this.setState({
                          expand_pay_terms: !this.state.expand_pay_terms,
                        });
                      }}
                    >
                      <div>
                        <span className="details-icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/terms.png"
                            }
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            alt=""
                          />
                        </span>
                        <span className="details-title">
                          {i18n.t("terms_conditions_payment")}
                        </span>
                      </div>
                      <span className="details-toggle-icon">
                        <i
                          className={
                            this.state.expand_pay_terms
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }
                        />
                      </span>
                    </a>

                    {this.state.expand_pay_terms && (
                      <div className="card card-details active rounded-0">
                        <div className="border p-0 px-1 d-flex my-2 justify-content-between bg-white rounded-2 align-items-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              class="fa fa-file-pdf-o p-3"
                              style={{
                                color: "#FF0000",
                                fontSize: "2rem",
                                background: "#f8f9fa",
                              }}
                            ></i>
                            <span className="fs-5rem-mob">
                              {i18n.t("download_attatchment")}
                            </span>
                          </div>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm rounded-2 border"
                            onClick={() => {
                              if (!auction.file_payment_terms) {
                                swal(
                                  "Failed",
                                  "No files were found!!. Please contact mzadcom administrator",
                                  "error"
                                );
                                return;
                              }
                              if (auction.file_payment_terms) {
                                window.open(
                                  auction.file_payment_terms,
                                  "_blank"
                                );
                              }
                            }}
                          >
                            <i className="fa fa-eye" />
                            &nbsp;
                            {i18n.t("preview")}{" "}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-6 col-sm-12 col-lg-6">
                    <a
                      href={true}
                      className={
                        this.state.expand_terms ? expand_class : collapse_class
                      }
                      onClick={() => {
                        this.setState({
                          expand_terms: !this.state.expand_terms,
                        });
                      }}
                    >
                      <div>
                        <span className="details-icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/terms.png"
                            }
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            alt=""
                          />
                        </span>
                        <span className="details-title">
                          {i18n.t("terms_conditions")}
                        </span>
                      </div>
                      <span className="details-toggle-icon">
                        <i
                          className={
                            this.state.expand_terms
                              ? "fa fa-angle-up"
                              : "fa fa-angle-down"
                          }
                        />
                      </span>
                    </a>

                    {this.state.expand_terms && (
                      <div className="card card-details active rounded-0">
                        <div className="border p-0 px-1 d-flex my-2 justify-content-between bg-white rounded-2 align-items-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              class="fa fa-file-pdf-o p-3"
                              style={{
                                color: "#FF0000",
                                fontSize: "2rem",
                                background: "#f8f9fa",
                              }}
                            ></i>
                            <span className="fs-5rem-mob">
                              {i18n.t("download_attatchment")}
                            </span>
                          </div>
                          <button
                            className="btn btn-primary btn-sm rounded-2 border"
                            type="button"
                            onClick={() => {
                              if (!auction.file_terms) {
                                swal(
                                  "Failed",
                                  "No files were found!!. Please contact mzadcom administrator",
                                  "error"
                                );
                                return;
                              }
                              if (auction.file_terms) {
                                window.open(auction.file_terms, "_blank");
                              }
                            }}
                          >
                            <i className="fa fa-eye" />
                            &nbsp;
                            {i18n.t("preview")}{" "}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
