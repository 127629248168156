import React from "react";
//import { AuctionRow } from "../helpers/auctionRow";
import i18n from "../../i18n";
import { getEnrolledAuctions } from "../../models/auctions";
import { SideBar } from "./sidebar";
import { HomeBanner } from "../home/homeBanner";
import { getEnrolledGroups } from "../../models/groups";
import { getOrganizations } from "../../models/organization";
import { getLoggedUser } from "../../state/user";

const user = getLoggedUser();
let lng = localStorage.getItem("I18N_LANGUAGE");
export class EnrolledAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      auctions: {},
      limit: 30,
      input: {},
      groups: [],
      clients: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
  }

  componentDidMount() {
    this.loadAllGroups();
    this.loadAllClients();
    this.loadAllAuctions(this.state.limit);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.setState({ input });
    }
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getEnrolledGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAllClients() {
    let q = "";
    if (user && user.id) {
      q += `&user=${user.id}`;
    }
    const response = await getOrganizations(q);
    let clients = response.data;
    this.setState({ clients });
  }

  async loadAllAuctions(limit) {
    let q = "";
    if (limit) {
      q = "limit=" + limit;
    }
    let { input } = this.state;
    if (input.group) {
      q += "&group=" + input.group;
    }
    if (input.client) {
      q += "&client=" + input.client;
    }
    const response = await getEnrolledAuctions(q);
    let auctions = response.data;
    this.setState({ auctions: auctions });
  }

  render() {
    let auctions = this.state.auctions;
    let limit = this.state.limit;
    let lt_30_cls =
      limit === 30
        ? "btn btn-primary btn-sm font-1vw p-1 px-2 m-0 rounded-0"
        : "btn btn-light btn-sm font-1vw p-1 px-2 m-0 rounded-0";
    let lt_50_cls =
      limit === 50
        ? "btn btn-primary btn-sm font-1vw p-1 px-2 m-0 rounded-0"
        : "btn btn-light btn-sm font-1vw p-1 px-2 m-0 rounded-0";
    let lt_100_cls =
      limit === 100
        ? "btn btn-primary btn-sm font-1vw p-1 px-2 m-0 rounded-0"
        : "btn btn-light btn-sm font-1vw p-1 px-2 m-0 rounded-0";
    let lt_200_cls =
      limit === 200
        ? "btn btn-primary btn-sm font-1vw p-1 px-2 m-0 rounded-0"
        : "btn btn-light btn-sm font-1vw p-1 px-2 m-0 rounded-0";
    let lt_all_cls =
      limit === false
        ? "btn btn-primary btn-sm font-1vw p-1 px-2 m-0 rounded-0"
        : "btn btn-light btn-sm font-1vw p-1 px-2 m-0 rounded-0";

    let { groups, clients } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">
                      {i18n.t("enrolled_auctions")}
                    </h3>
                  </div>

                  <div className="container_grid payment-for-large">
                    <form
                      id="UpdateProfile"
                      className="mb-0"
                      autoComplete="off"
                    >
                      <div className="row px-3">
                        <div className="col-sm-3 col-xs-3 col-lg-3 col-md-3 mb-1">
                          <select
                            aria-label="Default select example"
                            className="form-select select fs14 text-black"
                            name="client"
                            value={this.state.input.client}
                            onChange={this.handleChange}
                          >
                            <option value="">{i18n.t("select_client")}</option>
                            {clients.length > 0 &&
                              clients.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.organization_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="col-sm-3 col-xs-3 col-lg-3 col-md-3 mb-1">
                          <select
                            className="form-select select fs14 text-black"
                            data-placeholder="Select Class"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">{i18n.t("select_group")}</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="col-sm-3 col-xs-3 col-lg-3 col-md-3 mb-1">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary rounded-5 p-1 px-2 border"
                              onClick={() => {
                                this.loadAllAuctions(this.state.limit);
                              }}
                            >
                              <i className="fa fa-search" />
                              &nbsp;<span>{i18n.t("search")}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  {auctions.length === 0 && (
                    <div className="card-body">
                      <div className="alert alert-danger">
                        {i18n.t("no_data_found")}
                      </div>
                    </div>
                  )}
                  {auctions.length > 0 && (
                    <div className="card-body pt-0">
                      <div className="">
                        <div className="bg-white px-2 item2-gl-nav">
                          <div className="d-flex justify-content-end align-items-center">
                            {/* <label className="">
                              {!this.state.limit && (
                                <div className="m-1">Showing all entries </div>
                              )}
                              {this.state.limit && (
                                <div style={{ marginRight: "5px" }}>
                                  Showing 1 to {this.state.limit} of 30 entries{" "}
                                </div>
                              )}
                            </label> */}
                            <button
                              className={lt_30_cls}
                              onClick={() => {
                                this.setState({ limit: 30 });
                                this.loadAllAuctions(30);
                              }}
                            >
                              30
                            </button>
                            <button
                              className={lt_50_cls}
                              onClick={() => {
                                this.setState({ limit: 50 });
                                this.loadAllAuctions(50);
                              }}
                            >
                              50
                            </button>
                            <button
                              className={lt_100_cls}
                              onClick={() => {
                                this.setState({ limit: 100 });
                                this.loadAllAuctions(100);
                              }}
                            >
                              100
                            </button>
                            <button
                              className={lt_200_cls}
                              onClick={() => {
                                this.setState({ limit: 200 });
                                this.loadAllAuctions(200);
                              }}
                            >
                              200
                            </button>
                            <button
                              className={lt_all_cls}
                              onClick={() => {
                                this.setState({ limit: false });
                                this.loadAllAuctions();
                              }}
                            >
                              All
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive ">
                        <div className="table-wrapper">
                          {auctions.length > 0 && (
                            <table className="table table-sm table-striped table-light">
                              <thead>
                                <tr>
                                  <th className="">{i18n.t("ID")}</th>
                                  <th className="font-1vw">
                                    {i18n.t("title")}
                                  </th>
                                  <th className="font-1vw">
                                    {i18n.t("latest_bid_amount")}
                                  </th>
                                  <th className="font-1vw">
                                    {i18n.t("current_amount")}
                                  </th>
                                  <th className="font-1vw">
                                    {i18n.t("end_date")}
                                  </th>
                                  <th className="font-1vw">
                                    {i18n.t("bid_count")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {auctions.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>{item.id}</td>
                                      <td>
                                        {lng === "ar" && item.title_ar
                                          ? item.title_ar
                                          : item.title}
                                      </td>
                                      <td>{item.start_amount}</td>
                                      <td>{item.current_amount}</td>
                                      <td>{item.end_date_formatted}</td>
                                      <td>{item.bid_count}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
