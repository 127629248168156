import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const user = getLoggedUser();
async function getUser() {
  return fetch(apiUrl + "profile", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
      Accept: "application/json",
    },
  }).then((data) => data.json());
}

export default getUser;
