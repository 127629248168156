import React from "react";
import { Spinner } from "react-bootstrap";
import i18n from "../../i18n";
import { getLoggedUser } from "../../state/user";
const user = getLoggedUser();

let lang = localStorage.getItem("I18N_LANGUAGE");
let refreshStyle =
  lang === "ar"
    ? { position: "absolute", left: "7px" }
    : { position: "absolute", right: "7px" };

export class BiddersPosition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMore: false,
    };
  }

  render() {
    let top_bidders = this.props.top_bidders;
    //let total_enrolls = this.props.total_enrolls;
    let user = this.props.user;
    return (
      <React.Fragment>
        <div className="card border rounded-4 pt-2">
          <div className="card-header rounded-5 pb-0 pt-1 border-0">
            <div className="d-flex justify-content-between p-1">
              <h3 className="custom_text13">{i18n.t("top_3_bidders")}</h3>
              <button
                className="btn p-0 m-0 btn btn-light border px-1 rounded-5"
                onClick={this.props.loadTopBidders}
              >
                <span className="ms-1 custom_text9">
                  {!this.props.refresh_loading && (
                    <i className="mdi mdi-refresh"></i>
                  )}
                  {this.props.refresh_loading && (
                    <i className="mdi mdi-refresh mdi-refresh-animate"></i>
                  )}
                  &nbsp;
                  {i18n.t("refresh")}
                </span>
              </button>
            </div>
          </div>
          <div className="card-body px-1 py-1">
            {top_bidders && top_bidders.length > 0 && (
              <table className="table table-stripped mb-1">
                <thead>
                  <tr>
                    <th
                      className="custom_text8 px-3"
                      style={{ textAlign: "start" }}
                    >
                      <b>
                        {user.role !== "U"
                          ? i18n.t("username")
                          : i18n.t("bidder_id")}
                      </b>
                    </th>
                    <th
                      className="custom_text8"
                      style={{ textAlign: "center" }}
                    >
                      <b>{i18n.t("rank")}</b>
                    </th>
                    <th
                      className="custom_text8"
                      style={{ textAlign: "center" }}
                    >
                      <b>{i18n.t("amount")}</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {top_bidders.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className={
                          i > 2 && !this.state.loadMore ? "d-none" : ""
                        }
                      >
                        <td style={{ textAlign: "start" }}>
                          <div
                            className={
                              item.user_id === user.id
                                ? "font-weight-bold custom_text8 px-2"
                                : "custom_text8 px-2"
                            }
                            style={{ textAlign: "start" }}
                          >
                            {user.role !== "U"
                              ? item.username
                              : item.enroll_number}
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={
                              item.user_id === user.id
                                ? "border border-warning me-2 text-warning rounded-5 px-1 font-weight-bold"
                                : "border border-info me-2 text-info rounded-5 px-1"
                            }
                          >
                            {item.user_id === user.id &&
                              i18n.t("my_rank") + " : "}

                            {i + 1}
                          </span>
                        </td>
                        <td
                          className={
                            item.user_id === user.id
                              ? "font-weight-bold custom_text8"
                              : "custom_text8"
                          }
                        >
                          {item.bid_amount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {top_bidders && top_bidders.length > 3 && !this.state.loadMore && (
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.8rem",
                  color: "#ed2e32",
                }}
              >
                <a
                  style={{ cursor: "pointer" }}
                  href={true}
                  onClick={() => {
                    this.setState({ loadMore: true });
                  }}
                >
                  {i18n.t("expand")}
                </a>
              </div>
            )}

            {this.state.loadMore && (
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.8rem",
                  color: "#ed2e32",
                }}
              >
                <a
                  style={{ cursor: "pointer" }}
                  href={true}
                  onClick={() => {
                    this.setState({ loadMore: false });
                  }}
                >
                  {i18n.t("collapse")}
                </a>
              </div>
            )}

            {top_bidders && top_bidders.length < 1 && (
              <div className="text-center text-danger border border-danger rounded-5 mb-2">
                {i18n.t("no_bidders_found")}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
