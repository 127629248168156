import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import i18n from "../../../i18n";
export class AuctionListGrouped extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let baseColor = "#1f97c5";
    return (
      <React.Fragment>
        <div className="container">
          <div className="row mob-padd-9">
            <div className="license-plate m-auto mt-5">
              <section>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/orange_bid.png"}
                  height="20"
                  width="20"
                  alt="license-plate"
                />
              </section>
              <span>{i18n.t("active_auctions")}</span>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className=" mt-5">
                <div className="">
                  <div className="item2-gl ">
                    <div className=" mb-0">
                      <div className="">
                        <div className="bg-white p-2 item2-gl-nav">
                          <div className="container">
                            <div className="d-flex justify-content-between pt-2">
                              <div class="d-none-mob">
                                <p className="mt-3 mb-0">
                                  <Skeleton
                                    height={30}
                                    width={100}
                                    baseColor={baseColor}
                                    className="card"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </p>
                              </div>
                              <div class="">
                                <p
                                  className="mt-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <span className="d-none-mob">
                                    <Skeleton
                                      height={30}
                                      width={100}
                                      baseColor={baseColor}
                                      className="card"
                                      style={{ borderRadius: "5px" }}
                                    />
                                  </span>
                                </p>
                              </div>
                              <div class="">
                                <Skeleton
                                  height={30}
                                  width={100}
                                  baseColor={baseColor}
                                  className="card"
                                  style={{ borderRadius: "5px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="container">
                      <div className="row mt-3">
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <Skeleton
                              height={30}
                              baseColor={baseColor}
                              className="card"
                              style={{ borderRadius: "5px" }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-2 col-md-2">
                          <div className="form-group">
                            <Skeleton
                              height={30}
                              baseColor={baseColor}
                              className="card"
                              style={{ borderRadius: "5px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive auctions-list">
                      <div className="bg-white p-1 mb-9">
                        <div className="table-responsive-sm table-condensed">
                          <table className="table table-sm table-striped table-primary font-1vw">
                            <thead>
                              <tr>
                                <th
                                  className="text-center font-75rem text-center"
                                  style={{ width: "50px" }}
                                >
                                  <Skeleton
                                    height={30}
                                    width={30}
                                    baseColor={"#FFF"}
                                    className="card m-1"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </th>
                                {/* <th className="font-75rem">{i18n.t("")}</th> */}
                                <th
                                  className="font-75rem"
                                  style={{ width: "300px" }}
                                >
                                  <Skeleton
                                    height={20}
                                    width={50}
                                    baseColor="#FFF"
                                    className="card p-0 m-0"
                                    style={{ borderRadius: "1px" }}
                                  />
                                </th>
                                <th className="font-75rem">
                                  <Skeleton
                                    height={20}
                                    width={50}
                                    baseColor="#FFF"
                                    className="card p-0 m-0"
                                    style={{ borderRadius: "1px" }}
                                  />
                                </th>
                                <th className="font-75rem">
                                  <Skeleton
                                    height={20}
                                    width={50}
                                    baseColor="#FFF"
                                    className="card p-0 m-0"
                                    style={{ borderRadius: "1px" }}
                                  />
                                </th>
                                <th style={{ width: "15rem" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Skeleton
                                    height={30}
                                    width={30}
                                    baseColor={baseColor}
                                    className="card m-1"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </td>
                                <td>
                                  <Skeleton
                                    height={200}
                                    width={300}
                                    baseColor={baseColor}
                                    className="card"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </td>
                                <td>
                                  <Skeleton
                                    height={30}
                                    width={100}
                                    baseColor={baseColor}
                                    className="card"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </td>
                                <td>
                                  <Skeleton
                                    height={30}
                                    width={30}
                                    baseColor={baseColor}
                                    className="card"
                                    style={{ borderRadius: "5px" }}
                                  />
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <Skeleton
                                      height={30}
                                      width={100}
                                      baseColor={baseColor}
                                      className="card"
                                      style={{ borderRadius: "5px" }}
                                    />
                                    &nbsp;
                                    <Skeleton
                                      height={30}
                                      width={100}
                                      baseColor={baseColor}
                                      className="card"
                                      style={{ borderRadius: "5px" }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
