import React from "react";
import { Routes, Route } from "react-router-dom";
//import { MarqueeComponent } from "../home/marquee";
//import { WhoWeAre } from "../about/WhoWeAre";
import { Homepage } from "../home/homepage";
import { Registration } from "../auth/registration";
import Login from "../auth/login";
import { LoginOtp } from "../auth/login_otp";
import { ForgotPassword } from "../auth/forgotPassword";
//import { CategoryAuctions } from "../categoryAuctions";
import { Contact } from "../contact";
//import $ from "jquery";
import { ChairmansMessage } from "../about/chairmansMessage";
import { CeoMessage } from "../about/ceoMessage";
import { TeamMzadcom } from "../about/teamMzadcom";
import { Enroll } from "../enroll";
import { Dashboard } from "../dashboard/dashboard";
import { Payments } from "../dashboard/payments";
import { CreateAuction } from "../dashboard/create_auction";
import { AuctionsList } from "../dashboard/auctions_list";
import { Masks } from "../dashboard/masks";
import { EditMask } from "../dashboard/edit_mask";
import { EditAuction } from "../dashboard/edit_auction";
import { NotFound } from "../notfound";
import { PaymentCancelled } from "../paymentCancelled";
import { PaymentSuccess } from "../paymentSuccess";
import { TermsConditions } from "../terms_conditions";
import { TermsConditionsPayment } from "../terms_conditions_payment";
import { UsersList } from "../dashboard/users";
import { Groups } from "../dashboard/groups";
import { EditGroup } from "../dashboard/edit_group";
import { EnrolledAuctions } from "../dashboard/enrolled_auctions";
import { getLoggedUser } from "../../state/user";
import { ResetPassword } from "../auth/resetPassword";
import { Categories } from "../dashboard/categories";
import { Organizations } from "../dashboard/organizations";
import { AuctionDetails } from "../home/auctionDetails";
import { RentalYards } from "../services/rentalYards";
import { SegreggationsEvaluation } from "../services/segreggationsEvaluation";
import { Transportation } from "../services/transportation";
import { Recycling } from "../services/recycling";
import { Auctions } from "../auctions";
import { EditCategory } from "../dashboard/edit_category";
import { EditOrganization } from "../dashboard/edit_organization";
//import { TopCategories } from "./categories";
//import { OrganizationsLinks } from "./organizations";
import { EnrolledUsers } from "../dashboard/enrolled_users";
import { ImagePanel } from "../dashboard/image_panel";
import { EnquiriesDetails } from "../dashboard/enquiries_details";
import { Contracts } from "../dashboard/contracts";
import { EditProfile } from "../dashboard/edit_profile";

import { Reports } from "../dashboard/reports/reports";
import { Winners } from "../dashboard/reports/winners";
import { AuctionBiddingReport } from "../dashboard/reports/auction_bid_report";
import { SalesReport } from "../dashboard/reports/sales_report";
import { FinalReport } from "../dashboard/reports/final_report";
import { Banners } from "../dashboard/banners";
import { WhatIsMzadcom } from "../about/whatismzadcom";
import { RefundPayment } from "../dashboard/refund";
import { PaymentDetails } from "../dashboard/payment_details";
import { AuctionsListGrouped } from "../home/AuctionsListGrouped";
import { MyAuctions } from "../dashboard/my_auctions";
import { LiveActivity } from "../dashboard/live_activity";
import { BulkMail } from "../dashboard/bulk_mail";
import { ReportsPosition } from "../dashboard/reports/reports_position";
import { WinningBids } from "../dashboard/winning_bids";
import { Wallet } from "../dashboard/wallet";
import { WalletManage } from "../home/walletManage";
import { UsersWallet } from "../dashboard/users-wallet";
import { SiteVisit } from "../dashboard/site_visit";
import { WalletPaymentSuccess } from "../walletPaymentSuccess";
import { WalletPaymentCancelled } from "../WalletPaymentCancelled";
import { WalletWithdrawals } from "../dashboard/wallet_withdrawals";
import { About } from "../about/about";
import { WithdrawaReport } from "../dashboard/reports/withdraw_report";
import { getUserInfo } from "../../models/users";
import { BlackList } from "../dashboard/blacklist";
import { Header } from "../layouts/header";
import { CompanyRegistration } from "../auth/registration_company";
import { AuctionCalendar } from "../home/aucCalendar";
import { Modal } from "react-bootstrap";
import { Support } from "../support";
import { TermsPanel } from "../dashboard/terms";
import { ClientPayment } from "../ClientPayment";
import { ClientPaymentSuccess } from "../ClientPaymentSuccess";
import { ClientPaymentCancelled } from "../ClientPaymentCancelled";
import { AdminClientPayments } from "../dashboard/client_payments";
import { ClientRegistration } from "../auth/registration_client";
import { ClientsList } from "../dashboard/clients";
import { AdditionalPanel } from "../dashboard/additional";
import { LikedAuctions } from "../dashboard/liked_auctions";
import { WishlistedAuctions } from "../dashboard/wishlist_auctions";
import { AuctionTracker } from "../dashboard/auctions_tracker";
import i18n from "../../i18n";
import { DashboardAuctionEvaluation } from "../dashboard/dashboard_auc_eval";
import { DashboardUserAnalysis } from "../dashboard/dashboard_user_analysis";
import { DashboardSalesEvaluation } from "../dashboard/dashboard_sales_eval";
import { DashboardAuctionMaterials } from "../dashboard/dashboard_auc_material";
import { AuctionApproval } from "../dashboard/auction_approval";
import { MyNotifications } from "../dashboard/notifications";
import { EnrollCustom } from "../enroll-custom";
import { ApprovedAuctions } from "../dashboard/approved_auctions";
import { PaymentBulkSuccess } from "../bulkPaymentSuccess";

const user = getLoggedUser();
export class Topbar extends React.Component {
  constructor() {
    super();
    this.state = {
      showPopup: false,
      is_more_help: false,
      is_loading: false,
      wallet_amount: 0,
    };
    this.checkUserWallet = this.checkUserWallet.bind(this);
  }

  componentDidMount() {
    let is_logged_in = this.props.is_logged;
    if (is_logged_in) {
      this.checkUserWallet();
    }
  }

  hideSideBar() {
    document.body.classList.remove("active");
  }

  async checkUserWallet() {
    this.setState({ is_loading: true });
    const response = await getUserInfo(user.id);
    if (response && response.success) {
      let data = response.data;
      this.setState({ is_loading: false });
      this.setState({ wallet_amount: data.wallet_amount });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handleCallbackForMore = (flag) => {
    this.setState({
      is_more_help: flag,
    });
  };

  handleCallbackForSupport = (flag) => {
    this.setState({
      showPopup: flag,
    });
  };

  render() {
    let is_logged_in = this.props.is_logged;
    return (
      <React.Fragment>
        <Header
          is_logged_in={is_logged_in}
          wallet_amount={this.state.wallet_amount}
          is_loading={this.state.is_loading}
          callbackForSupport={this.handleCallbackForSupport}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Homepage callbackForSupport={this.handleCallbackForSupport} />
            }
          />
          <Route path="/about" element={<About />} />
          <Route
            path="/auc-calendar"
            element={
              <AuctionCalendar
                callbackForSupport={this.handleCallbackForSupport}
              />
            }
          />
          {/* <Route path="/about/whoweare" element={<WhoWeAre />} /> */}
          <Route path="/about/what-mzadcom" element={<WhatIsMzadcom />} />
          <Route
            path="/about/message-chairman"
            element={<ChairmansMessage />}
          />
          <Route path="/about/message-ceo" element={<CeoMessage />} />
          <Route path="/about/team-mzadcom" element={<TeamMzadcom />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/terms_payment" element={<TermsConditionsPayment />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/comp-registration" element={<CompanyRegistration />} />
          <Route path="/client-registration" element={<ClientRegistration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login_otp" element={<LoginOtp />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          {/* <Route path="/category-auctions" element={<CategoryAuctions />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/password/reset/:email/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/grouped-auctions/:id"
            element={
              <AuctionsListGrouped
                callbackForSupport={this.handleCallbackForSupport}
              />
            }
          />
          <Route
            path="/auction-details/:id"
            element={
              <AuctionDetails
                callbackForSupport={this.handleCallbackForSupport}
              />
            }
          />
          <Route
            exact
            path="/auctions"
            element={
              <Auctions callbackForSupport={this.handleCallbackForSupport} />
            }
          />
          <Route path="/services" element={<RentalYards />} />
          <Route path="/services/rental-yards" element={<RentalYards />} />
          <Route
            path="/services/segreggations-evaluation"
            element={<SegreggationsEvaluation />}
          />
          <Route path="/services/transportation" element={<Transportation />} />
          <Route path="/services/recycling" element={<Recycling />} />

          {/** Protected Routes */}
          <Route
            path="/dashboard"
            element={
              localStorage.getItem("is_logged_in") ? (
                <Dashboard />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/notifications"
            element={
              localStorage.getItem("is_logged_in") ? (
                <MyNotifications />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/dashboard-auc-eval"
            element={
              localStorage.getItem("is_logged_in") ? (
                <DashboardAuctionEvaluation />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/dashboard-user-analysis"
            element={
              localStorage.getItem("is_logged_in") ? (
                <DashboardUserAnalysis />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/dashboard-sales-eval"
            element={
              localStorage.getItem("is_logged_in") ? (
                <DashboardSalesEvaluation />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/dashboard-auc-materials"
            element={
              localStorage.getItem("is_logged_in") ? (
                <DashboardAuctionMaterials />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/profile"
            element={
              localStorage.getItem("is_logged_in") ? (
                <EditProfile />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/enroll/:id"
            element={
              localStorage.getItem("is_logged_in") ? <Enroll /> : <NotFound />
            }
          />
          <Route
            path="/custom-enroll/:id"
            element={
              localStorage.getItem("is_logged_in") ? (
                <EnrollCustom />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/payments"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "C" || user.role === "M") ? (
                <Payments />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/client-payments"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "PM" ||
                user.role === "OM") ? (
                <AdminClientPayments />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/create_auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <CreateAuction />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_auction/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditAuction />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/auctions_list"
            element={
              localStorage.getItem("is_logged_in") ? (
                <AuctionsList />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/auction_approval"
            element={
              localStorage.getItem("is_logged_in") ? (
                <AuctionApproval />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/approved_auctions"
            element={
              localStorage.getItem("is_logged_in") ? (
                <ApprovedAuctions />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/groups"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Groups />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_group/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditGroup />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/masks"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Masks />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_mask/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditMask />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/categories"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Categories />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_category/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditCategory />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/organizations"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Organizations />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit_organization/:id"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EditOrganization />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/banners"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Banners />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/blacklist"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <BlackList />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/payment-success/"
            element={
              localStorage.getItem("is_logged_in") ? (
                <PaymentSuccess />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/bulk-payment-success/"
            element={
              localStorage.getItem("is_logged_in") ? (
                <PaymentBulkSuccess />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/payment-cancelled"
            element={
              localStorage.getItem("is_logged_in") ? (
                <PaymentCancelled />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/wallet-payment-success/"
            element={
              localStorage.getItem("is_logged_in") ? (
                <WalletPaymentSuccess
                  callbackForSupport={this.handleCallbackForSupport}
                />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/wallet-payment-cancelled"
            element={
              localStorage.getItem("is_logged_in") ? (
                <WalletPaymentCancelled
                  callbackForSupport={this.handleCallbackForSupport}
                />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/client-payment-success/"
            element={
              localStorage.getItem("is_logged_in") ? (
                <ClientPaymentSuccess
                  callbackForSupport={this.handleCallbackForSupport}
                />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/client-payment-cancelled/"
            element={
              localStorage.getItem("is_logged_in") ? (
                <ClientPaymentCancelled
                  callbackForSupport={this.handleCallbackForSupport}
                />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/users"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <UsersList />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/clients"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <ClientsList />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/site_visit"
            element={
              localStorage.getItem("is_logged_in") ? (
                <SiteVisit />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/users-wallet"
            element={
              localStorage.getItem("is_logged_in") &&
              ["A", "F", "CE", "GM"].includes(user.role) ? (
                <UsersWallet />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/withdraw-report"
            element={
              localStorage.getItem("is_logged_in") && user.role === "F" ? (
                <WithdrawaReport />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/withdraw-requests"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" || user.role === "F") ? (
                <WalletWithdrawals />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/enrolled_auctions"
            element={
              localStorage.getItem("is_logged_in") ? (
                <EnrolledAuctions />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/my_auctions"
            element={
              localStorage.getItem("is_logged_in") && user.role === "U" ? (
                <MyAuctions />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/auction_tracker"
            element={
              localStorage.getItem("is_logged_in") ? (
                <AuctionTracker />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/auction_liked"
            element={
              localStorage.getItem("is_logged_in") ? (
                <LikedAuctions />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/auction_wishlisted"
            element={
              localStorage.getItem("is_logged_in") ? (
                <WishlistedAuctions />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/winning_bids"
            element={
              localStorage.getItem("is_logged_in") && user.role === "U" ? (
                <WinningBids />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/live_activity"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <LiveActivity />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/auction_bid_report"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "C" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <AuctionBiddingReport />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/sales_report"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <SalesReport />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/final_report"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <FinalReport />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/position_report"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <ReportsPosition />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/winners"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "C" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <Winners />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/reports"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "C" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "M") ? (
                <Reports />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/enrolled_users"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "C" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE" ||
                user.role === "F" ||
                user.role === "M") ? (
                <EnrolledUsers />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/image_uploader/:auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <ImagePanel />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/enquiries_details/:auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <EnquiriesDetails />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/contracts/:auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <Contracts />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/terms/:auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <TermsPanel />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/additional/:auction"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <AdditionalPanel />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/refund"
            element={
              localStorage.getItem("is_logged_in") &&
              (user.role === "A" ||
                user.role === "PM" ||
                user.role === "OM" ||
                user.role === "GM" ||
                user.role === "CE") ? (
                <RefundPayment />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/composer"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <BulkMail />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/refund/:reference"
            element={
              localStorage.getItem("is_logged_in") && user.role === "A" ? (
                <PaymentDetails />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/wallet"
            element={
              localStorage.getItem("is_logged_in") ? (
                <Wallet callbackForSupport={this.handleCallbackForSupport} />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/client-payment"
            element={
              localStorage.getItem("is_logged_in") ? (
                <ClientPayment
                  callbackForSupport={this.handleCallbackForSupport}
                />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/wallet-manage"
            element={
              localStorage.getItem("is_logged_in") ? (
                <WalletManage
                  callbackForSupport={this.handleCallbackForSupport}
                />
              ) : (
                <NotFound />
              )
            }
          />

          <Route path="*" exact={true} element={<NotFound />} />
        </Routes>

        <Modal
          size="lg"
          show={this.state.showPopup}
          onHide={() => this.setState({ showPopup: false })}
          animation={true}
          dialogClassName="modal-90w"
          id="support-modal"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header className="text-dark border-0 text-center">
            <h4 className="h4 w-100 my-3">
              {this.state.is_more_help
                ? i18n.t("support_pick_cat")
                : i18n.t("support")}
            </h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => this.setState({ showPopup: false })}
            >
              <i className="fa fa-times" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="m-4">
              <Support callbackForMore={this.handleCallbackForMore} />
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
