import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const endpoint = apiUrl + "blacklist";
const user = getLoggedUser();

async function createBlacklist(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllBlacklists(q) {
  return fetch(endpoint + "?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getBlacklist(id) {
  return fetch(endpoint + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateBlacklist(payload, id) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      endpoint + "/" + id,
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteBlacklist(id) {
  return fetch(endpoint + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function checkBlackList() {
  return fetch(apiUrl + "check_blacklist", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createBlacklist,
  getAllBlacklists,
  getBlacklist,
  updateBlacklist,
  deleteBlacklist,
  checkBlackList,
};
