import React from "react";
import { Link } from "react-router-dom";
import { CarouselAuctionDetails } from "../carousel/CarouselAuctionDetails";
//import i18n from "../../i18n";
export class RentalYards extends React.Component {
  render() {
    let action_name = "Rental Yards";
    return (
      <div>
        <div>
          <div
            className="cover-image sptb-1 bg-background"
            data-image-src="../assets/images/banners/banner1.jpg"
          >
            <div className="header-text1 mb-0">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-12 col-md-12 d-block mx-auto">
                    <div className="text-center text-white ">
                      <h1 className="mb-5">{action_name}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white border-bottom">
          <div className="container">
            <div className="page-header">
              <h4 className="page-title">Rental Yards</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link exact="true" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a href={true}>Services</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Rental Yards
                </li>
              </ol>
            </div>
          </div>
        </div>
        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12">
                <div className="">
                  <div className="">
                    <div className="border-0 mb-5">
                      <div className="wideget-user-tab wideget-user-tab3">
                        <div className="tab-menu-heading">
                          <div className="tabs-menu1">
                            <ul className="nav">
                              <li className="">
                                <a
                                  href="#tab-1"
                                  className="active"
                                  data-bs-toggle="tab"
                                >
                                  Details
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#tab-2"
                                  data-bs-toggle="tab"
                                  className=""
                                >
                                  Description
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#tab-3"
                                  data-bs-toggle="tab"
                                  className=""
                                >
                                  Images
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="tab-content border-start border-end border-top br-ts-3 p-5 bg-white">
                        <div className="tab-pane active" id="tab-1">
                          <div className="row">
                            <div className="col-xl-12 col-md-12">
                              <div className="table-responsive">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia deserunt
                                  mollit anim id est laborum.
                                </p>

                                <p>
                                  Sed ut perspiciatis unde omnis iste natus
                                  error sit voluptatem accusantium doloremque
                                  laudantium, totam rem aperiam, eaque ipsa quae
                                  ab illo inventore veritatis et quasi
                                  architecto beatae vitae dicta sunt explicabo.
                                  Nemo enim ipsam voluptatem quia voluptas sit
                                  aspernatur aut odit aut fugit, sed quia
                                  consequuntur magni dolores eos qui ratione
                                  voluptatem sequi nesciunt. Neque porro
                                  quisquam est, qui dolorem ipsum quia dolor sit
                                  amet, consectetur.
                                </p>

                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia deserunt
                                  mollit anim id est laborum.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab-2">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-responsive">
                                Sed ut perspiciatis unde omnis iste natus error
                                sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa quae
                                ab illo inventore veritatis et quasi architecto
                                beatae vitae dicta sunt explicabo. Nemo enim
                                ipsam voluptatem quia voluptas sit aspernatur
                                aut odit aut fugit, sed quia consequuntur magni
                                dolores eos qui ratione voluptatem sequi
                                nesciunt. Neque porro quisquam est, qui dolorem
                                ipsum quia dolor sit amet, consectetur,
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-responsive">
                                <CarouselAuctionDetails />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Location</h3>
                  </div>
                  <div className="card-body">
                    <div className="map-header">
                      <div className="map-header-layer" id="map2">
                        <iframe
                          title="test"
                          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d234021.9164760129!2d58.284428084455875!3d23.583076633060386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3e91fc45542fb7d1%3A0xa7e7f4cd1d186390!2sAl%20Amarat%2C%20Oman!3m2!1d23.5088386!2d58.432758!5e0!3m2!1sen!2sin!4v1641883790038!5m2!1sen!2sin"
                          width="326"
                          height="240"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <a
                      href={true}
                      className="btn btn-primary btn-block text-white"
                    >
                      Open in google map
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
