import React from "react";
import { Link } from "react-router-dom";

export class AuctionTabs extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      auctionId: auctionId,
    };
  }
  render() {
    let id = this.state.auctionId;
    let activeTab = this.props.activeTab;
    return (
      <React.Fragment>
        <ul className="nav nav-tabs">
          <li className={activeTab === "details" ? "active" : ""}>
            <Link to={"/edit_auction/" + id}>
              <i className="fa fa-info" />
              &nbsp;Details
            </Link>
          </li>
          <li className={activeTab === "uploader" ? "active" : ""}>
            <Link to={"/image_uploader/" + id}>
              <i className="fa fa-image" />
              &nbsp;Media
            </Link>
          </li>
          <li className={activeTab === "enquiry" ? "active" : ""}>
            <Link to={"/enquiries_details/" + id}>
              <i className="fa fa-phone" />
              &nbsp;Enquiry & Details
            </Link>
          </li>
          <li className={activeTab === "contracts" ? "active" : ""}>
            <Link to={"/contracts/" + id}>
              <i className="fa fa-file-o" />
              &nbsp;Contracts
            </Link>
          </li>
          <li className={activeTab === "terms" ? "active" : ""}>
            <Link to={"/terms/" + id}>
              <i className="fa fa-files-o" />
              &nbsp;Terms Files
            </Link>
          </li>
          <li className={activeTab === "additional" ? "active" : ""}>
            <Link to={"/additional/" + id}>
              <i className="fa fa-file-pdf-o" />
              &nbsp;Additional Information
            </Link>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
