import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const user = getLoggedUser();
async function updateUser(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "profile",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export default updateUser;
