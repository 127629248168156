import React from "react";
import i18n from "../../i18n";
export class ShowMorePanel extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="row m-auto justify-content-center support-main">
          <div className="col-2 mb-1">
            <div className="user-brd margin rounded-5">
              <div className="p-3 flex_column">
                <div
                  className="border p-2 rounded-4 m-auto"
                  style={{ width: "fit-content" }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/support-1.png"
                    }
                    className="responsive_image"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="h6 text-center m-1 my-4 d-flex justify-content-center">
                    {i18n.t("auctions")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 mb-1">
            <div className="user-brd margin rounded-5">
              <div className="p-3 flex_column">
                <div
                  className="border p-2 rounded-4 m-auto"
                  style={{ width: "fit-content" }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/support-2.png"
                    }
                    className="responsive_image"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="h6 text-center m-1 my-3 d-flex justify-content-center">
                    {i18n.t("reg_login")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 mb-1">
            <div className="user-brd margin rounded-5">
              <div className="p-3 flex_column">
                <div
                  className="border p-2 rounded-4 m-auto"
                  style={{ width: "fit-content" }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/support-3.png"
                    }
                    className="responsive_image"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="h6 text-center m-1 my-3 d-flex justify-content-center">
                    {i18n.t("deposit")}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-2 mb-1">
            <div className="user-brd margin rounded-5">
              <div className="p-3 flex_column">
                <div
                  className="border p-2 rounded-4 m-auto"
                  style={{ width: "fit-content" }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/support-1.png"
                    }
                    className="responsive_image"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="h6 text-center m-1 my-4 d-flex justify-content-center">
                    {i18n.t("bidding")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-auto justify-content-center mt-2 support-main">
          <div className="col-2 mb-1">
            <div className="user-brd margin rounded-5">
              <div className="p-3 flex_column">
                <div
                  className="border p-2 rounded-4 m-auto"
                  style={{ width: "fit-content" }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/support-2.png"
                    }
                    className="responsive_image"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="h6 text-center m-1 my-4 d-flex justify-content-center">
                    {i18n.t("direct_sale")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 mb-1">
            <div className="user-brd margin rounded-5">
              <div className="p-3 flex_column">
                <div
                  className="border p-2 rounded-4 m-auto"
                  style={{ width: "fit-content" }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/support-3.png"
                    }
                    className="responsive_image"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="h6 text-center m-1 my-3 d-flex justify-content-center">
                    {i18n.t("direct_sale")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 mb-1">
            <div className="user-brd margin rounded-5">
              <div className="p-3 flex_column">
                <div
                  className="border p-2 rounded-4 m-auto"
                  style={{ width: "fit-content" }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/support-3.png"
                    }
                    className="responsive_image"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="h6 text-center m-1 my-3 d-flex justify-content-center">
                    {i18n.t("edit_profile")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-sm btn-outline-danger"
            onClick={() => {
              this.props.handleToggle(false);
            }}
          >
            <i className="fa fa-angle-left" />
            &nbsp;
            {i18n.t("back")}
          </button>
        </div>
      </React.Fragment>
    );
  }
}
