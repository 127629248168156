export function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span className={className} style={style} onClick={onClick}>
      <i className="fa fa-angle-right" />
    </span>
  );
}

export function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span className={className} style={style} onClick={onClick}>
      <i className="fa fa-angle-left" />
    </span>
  );
}
