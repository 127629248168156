import React from "react";
//import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import i18n from "../../i18n";
import { getGroup, deleteGroup, updateGroup } from "../../models/groups";
import { getUsersByRoles } from "../../models/users";
import { Modal } from "react-bootstrap";
import { HomeBanner } from "../home/homeBanner";
import FileInput from "../helpers/fileInput";
import { getAllOrganization } from "../../models/organization";
import ToggleButton from "react-toggle-button";
import { MzadLoader } from "../helpers/mzadLoader";

export class EditGroup extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let groupId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      is_loading: false,
      groupId: groupId,
      input: {
        is_grouped_enroll: false,
        is_auctions_grouped: false,
        is_extra_time: false,
        extra_time: 0,
        has_auction_update: true,
        auction_status: "",
      },
      errors: {},
      managers: [],
      hse_users: [],
      groups: [],
      organizations: [],
      collapse: true,
      enquiries: [{ label: "", value: "", label_ar: "", value_ar: "" }],
      file_group_image: null,
      file_terms: null,
      file_payment_terms: null,
      is_live: false,
      is_updating: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
    this.toggleGrouped = this.toggleGrouped.bind(this);
    this.toggleEnrollGrouped = this.toggleEnrollGrouped.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.toggleAuctionUpdate = this.toggleAuctionUpdate.bind(this);
  }

  componentDidMount() {
    this.loadSelectedGroup();
    this.loadUsersByRoles();
    this.loadAllOrganisation();
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let organizations = response.data;
    this.setState({ organizations });
  }

  async loadUsersByRoles() {
    let q = `roles=PM,HSE&is_role_wise=${true}`;
    const response = await getUsersByRoles(q);
    let managers = response.data["PM"];
    let hse_users = response.data["HSE"];
    this.setState({ managers, hse_users });
  }

  async loadSelectedGroup() {
    this.setState({ is_loading: true });
    const response = await getGroup(this.state.groupId);
    if (response.success) {
      let group = response.data;
      let meta = response.meta;
      let enquiries =
        group && group.enquiry ? group.enquiry : this.state.enquiries;
      this.setState({ input: group, enquiries, is_live: meta.is_live });
      this.setState({ is_loading: false });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    console.log(event.target.name + ", " + event.target.value);
    this.setState({ input });
  }

  toggleChange = () => {
    let input = this.state.input;
    input.is_extra_time = !this.state.input.is_extra_time;
    this.setState({ input });
  };

  toggleGrouped = () => {
    let input = this.state.input;
    input.is_auctions_grouped = !this.state.input.is_auctions_grouped;
    this.setState({
      input,
    });
  };

  toggleAuctionUpdate = () => {
    let input = this.state.input;
    input.has_auction_update = !this.state.input.has_auction_update;
    this.setState({
      input,
    });
  };

  toggleEnrollGrouped = () => {
    let input = this.state.input;
    input.is_grouped_enroll = !this.state.input.is_grouped_enroll;
    this.setState({
      input,
    });
  };

  onFileChange = (event) => {
    if (event.target.name === "file_group_image") {
      this.setState({ file_group_image: event.target.files[0] });
    }
    if (event.target.name === "file_terms") {
      this.setState({ file_terms: event.target.files[0] });
    }
    if (event.target.name === "file_payment_terms") {
      this.setState({ file_payment_terms: event.target.files[0] });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let { hse, manager } = this.state.input;
    if (!hse || !manager) {
      swal(
        "Failed",
        "Some fields are missing. Please check the form.",
        "error"
      );
      return;
    }
    this.setState({ is_updating: true });
    try {
      const formData = new FormData();
      formData.append("organization", this.state.input.organization);
      formData.append("group_name", this.state.input.group_name);
      formData.append("group_name_ar", this.state.input.group_name_ar);
      formData.append("manager", this.state.input.manager);
      formData.append("hse", this.state.input.hse);
      formData.append("description", this.state.input.description);
      formData.append("description_ar", this.state.input.description_ar);
      formData.append("start_date", this.state.input.start_date);
      formData.append("end_date", this.state.input.end_date);
      formData.append("reg_start_date", this.state.input.reg_start_date);
      formData.append("reg_end_date", this.state.input.reg_end_date);
      formData.append(
        "is_auctions_grouped",
        this.state.input.is_auctions_grouped
      );
      formData.append("has_auto_bidding", this.state.input.has_auto_bidding);

      let has_auc_updt =
        typeof this.state.input.has_auction_update === "undefined"
          ? false
          : this.state.input.has_auction_update;
      formData.append("has_auction_update", has_auc_updt);
      if (typeof this.state.input.auction_status !== "undefined") {
        formData.append("auction_status", this.state.input.auction_status);
      }
      formData.append("is_grouped_enroll", this.state.input.is_grouped_enroll);
      formData.append("terms", this.state.input.terms);
      formData.append("terms_arabic", this.state.input.terms_arabic);
      formData.append("amount", this.state.input.amount);
      formData.append("visit_amount", this.state.input.visit_amount);
      formData.append("payment_type", this.state.input.payment_type);
      formData.append("enquiries", JSON.stringify(this.state.enquiries));
      formData.append("is_extra_time", this.state.input.is_extra_time);
      formData.append("extra_time", this.state.input.extra_time);
      formData.append("vat", this.state.input.vat);
      formData.append("service_charge", this.state.input.service_charge);
      formData.append(
        "client_service_charge",
        this.state.input.client_service_charge
      );
      if (this.state.file_group_image) {
        formData.append("file_group_image", this.state.file_group_image);
      }
      if (this.state.file_terms) {
        formData.append("file_terms", this.state.file_terms);
      }
      if (this.state.file_payment_terms) {
        formData.append("file_payment_terms", this.state.file_payment_terms);
      }
      formData.append("_method", "patch");
      const response = await updateGroup(formData, this.state.groupId);
      if (response && response.data && response.data.id) {
        this.setState({ is_updating: false });
        swal(i18n.t("success"), response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });

        if (response.data) {
          let input = response.data;
          console.log(input);
          this.setState({ input });
        }
      } else {
        this.setState({ is_updating: false });
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteRow() {
    const response = deleteGroup(this.state.groupId);
    console.log(response);
    swal(i18n.t("success"), "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        window.location.href = "/groups";
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  appendData(e) {
    e.preventDefault();
    let enquiries = this.state.enquiries;
    enquiries.push({ label: "", value: "" });
    this.setState({ enquiries: enquiries });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  render() {
    let managers = this.state.managers;
    let hse_users = this.state.hse_users;
    let enquiries = this.state.enquiries;
    let organizations = this.state.organizations;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 dashboard-wrapper">
                <div className="">
                  {this.state.is_loading && (
                    <div className="card">
                      <div className="card-body">
                        <MzadLoader loading={this.state.is_loading} />
                      </div>
                    </div>
                  )}

                  {!this.state.is_loading && (
                    <form
                      id="UpdateProfile"
                      className="card mb-0"
                      autoComplete="off"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="card-header">
                        <h3 className="card-title">Edit Group</h3>
                      </div>
                      <div className="card-body">
                        {this.state.is_live && (
                          <div className="row">
                            <div className="alert alert-danger">
                              {i18n.t("ongoing_alert")}
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("organization")}
                              </label>
                              <select
                                className="form-control"
                                data-placeholder={i18n.t("organization")}
                                name="organization"
                                value={this.state.input.organization}
                                onChange={this.handleChange}
                              >
                                <option value="">
                                  --{i18n.t("select_client")}--
                                </option>
                                {organizations.length > 0 &&
                                  organizations.map((item, i) => {
                                    return (
                                      <option value={item.id}>
                                        {item.organization_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("group_name")}
                              </label>
                              <input
                                type="text"
                                name="group_name"
                                className="form-control"
                                placeholder={i18n.t("group_name")}
                                value={this.state.input.group_name}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("group_name")}({i18n.t("arabic")})
                              </label>
                              <input
                                type="text"
                                style={{ direction: "rtl" }}
                                name="group_name_ar"
                                className="form-control"
                                placeholder={i18n.t("group_name")}
                                value={this.state.input.group_name_ar}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("manager")}
                              </label>
                              <select
                                className="form-control"
                                data-placeholder={i18n.t("manager")}
                                name="manager"
                                value={this.state.input.manager}
                                onChange={this.handleChange}
                              >
                                <option value="">--Select--</option>
                                {managers.map((item, i) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("hse")}
                              </label>
                              <select
                                className="form-control"
                                data-placeholder={i18n.t("hse")}
                                name="hse"
                                value={this.state.input.hse}
                                onChange={this.handleChange}
                              >
                                <option value="">--Select--</option>
                                {hse_users.map((item, i) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("start_date")}
                                {/* ({this.state.input.start_date}) */}
                              </label>
                              <input
                                type="datetime-local"
                                name="start_date"
                                className="form-control"
                                placeholder={i18n.t("start_date")}
                                value={this.state.input.start_date}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("end_date")}
                                {/* ({this.state.input.end_date}) */}
                              </label>
                              <input
                                type="datetime-local"
                                name="end_date"
                                className="form-control"
                                placeholder={i18n.t("end_date")}
                                value={this.state.input.end_date}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("reg_start_date")}
                              </label>
                              <input
                                type="datetime-local"
                                name="reg_start_date"
                                className="form-control"
                                placeholder={i18n.t("reg_start_date")}
                                value={this.state.input.reg_start_date}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("reg_end_date")}
                              </label>
                              <input
                                type="datetime-local"
                                name="reg_end_date"
                                className="form-control"
                                placeholder={i18n.t("reg_end_date")}
                                value={this.state.input.reg_end_date}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-2">
                            <div className="form-group">
                              <label className="form-label">
                                {" "}
                                Is Auctions Grouped.?
                              </label>
                              {/* <input
                              name="is_auctions_grouped"
                              type="checkbox"
                              checked={this.state.input.is_auctions_grouped}
                              onChange={this.toggleGrouped}
                              style={{ float: "left", margin: "4px" }}
                            /> */}
                              <ToggleButton
                                value={this.state.input.is_auctions_grouped}
                                className="form-control"
                                onToggle={(value) => {
                                  let { input } = this.state;
                                  input.is_auctions_grouped = !value;
                                  this.setState({
                                    input,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-2">
                            <div className="form-group">
                              <label className="form-label">
                                Is Grouped Enroll.?
                              </label>
                              {/* <input
                              name="is_grouped_enroll"
                              type="checkbox"
                              checked={this.state.input.is_grouped_enroll}
                              onChange={this.toggleEnrollGrouped}
                              style={{ float: "left", margin: "4px" }}
                            /> */}
                              <ToggleButton
                                value={this.state.input.is_grouped_enroll}
                                className="form-control"
                                onToggle={(value) => {
                                  let { input } = this.state;
                                  input.is_grouped_enroll = !value;
                                  this.setState({
                                    input,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-2">
                            <div className="form-group">
                              <label className="form-label">
                                Enroll Amount
                              </label>
                              <input
                                type="text"
                                name="amount"
                                className="form-control"
                                placeholder="Enroll Amount"
                                value={this.state.input.amount}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-2">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("visit_amount")}
                              </label>
                              <input
                                type="text"
                                name="visit_amount"
                                className="form-control"
                                placeholder={i18n.t("visit_amount")}
                                value={this.state.input.visit_amount}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("vat")}(%)
                              </label>
                              <input
                                type="text"
                                name="vat"
                                className="form-control"
                                placeholder={i18n.t("vat")}
                                value={this.state.input.vat}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("service_charge")}{" "}
                                <span
                                  onClick={() => {
                                    this.setState({ service_info_popup: true });
                                  }}
                                >
                                  <i className="fa fa-info-circle text-info"></i>
                                </span>
                              </label>
                              <input
                                type="text"
                                name="service_charge"
                                className="form-control"
                                placeholder={i18n.t("service_charge")}
                                value={this.state.input.service_charge}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("client_service_charge")}(%)
                              </label>
                              <input
                                type="text"
                                name="client_service_charge"
                                className="form-control"
                                placeholder={i18n.t("client_service_charge")}
                                value={this.state.input.client_service_charge}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("pay_type")}
                              </label>
                              <select
                                className="form-control"
                                data-placeholder="Select Group"
                                name="payment_type"
                                value={this.state.input.payment_type}
                                onChange={this.handleChange}
                              >
                                <option value="">--Select--</option>
                                <option value="Online">
                                  {i18n.t("online")}
                                </option>
                                <option value="Offline">
                                  {i18n.t("offline")}
                                </option>
                                <option value="Both">
                                  Supports both Online & Offline
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-2 col-md-2">
                            <div className="form-group">
                              <label className="form-label">
                                <input
                                  name="is_extra_time"
                                  type="checkbox"
                                  checked={this.state.input.is_extra_time}
                                  value={this.state.input.is_extra_time}
                                  onChange={this.toggleChange}
                                />
                                &nbsp;
                                {i18n.t("extra_time")}
                              </label>

                              <input
                                name="extra_time"
                                type="number"
                                className="form-control text-center"
                                onChange={this.handleChange}
                                placeholder={i18n.t("in_minutes")}
                                value={this.state.input.extra_time}
                                disabled={!this.state.input.is_extra_time}
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                Upload new image
                              </label>
                              <div className="d-flex">
                                <FileInput
                                  placeholder={i18n.t("choose_file")}
                                  className="form-control"
                                  name="file_group_image"
                                  onChange={this.onFileChange}
                                  value={this.state.file_category_image}
                                />
                                <a
                                  href={this.state.input.image}
                                  target="_new"
                                  className="border rounded-3 p-1 mx-1"
                                >
                                  <img
                                    src={this.state.input.image}
                                    alt=""
                                    className=""
                                    style={{ height: "35px", width: "auto" }}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                Upload new terms & conditions
                              </label>
                              <div className="d-flex">
                                <FileInput
                                  placeholder={i18n.t("choose_file")}
                                  className="form-control"
                                  name="file_terms"
                                  accept="application/pdf"
                                  onChange={this.onFileChange}
                                  value={this.state.file_category_image}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <label className="form-label">
                                Upload new payment terms
                              </label>
                              <div className="d-flex">
                                <FileInput
                                  placeholder={i18n.t("choose_file")}
                                  className="form-control"
                                  name="file_payment_terms"
                                  accept="application/pdf"
                                  onChange={this.onFileChange}
                                  value={this.state.file_category_image}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                Auto Bidding.?
                              </label>
                              <ToggleButton
                                inactiveLabel={"No"}
                                activeLabel={"Yes"}
                                value={this.state.input.has_auto_bidding}
                                className="form-control m-auto"
                                onToggle={(value) => {
                                  let { input } = this.state;
                                  input.has_auto_bidding = !value;
                                  this.setState({
                                    input,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("description")}
                              </label>
                              <textarea
                                className="form-control"
                                name="description"
                                value={this.state.input.description}
                                onChange={this.handleChange}
                                rows={5}
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("description")}({i18n.t("arabic")})
                              </label>
                              <textarea
                                className="form-control"
                                name="description_ar"
                                value={this.state.input.description_ar}
                                onChange={this.handleChange}
                                rows={5}
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("terms_conditions")}
                              </label>
                              <textarea
                                className="form-control"
                                name="terms"
                                value={this.state.input.terms}
                                onChange={this.handleChange}
                                rows={5}
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("terms_conditions")} (Arabic)
                              </label>
                              <textarea
                                style={{ direction: "rtl" }}
                                className="form-control"
                                name="terms_arabic"
                                value={this.state.input.terms_arabic}
                                onChange={this.handleChange}
                                rows={5}
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                            <h5>{i18n.t("enquiry")}</h5>
                            {enquiries.length > 0 &&
                              enquiries.map((item, i) => {
                                return (
                                  <div className="row" key={i}>
                                    <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Label
                                        </label>
                                        <input
                                          type="text"
                                          name="jsonLabel"
                                          className="form-control"
                                          placeholder="Label"
                                          value={item.label || ""}
                                          onChange={(event) => {
                                            this.setEnquiryJson(
                                              "label",
                                              event,
                                              i
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Value
                                        </label>
                                        <input
                                          type="text"
                                          name="jsonValue"
                                          className="form-control"
                                          placeholder="Value"
                                          value={item.value || ""}
                                          onChange={(event) => {
                                            this.setEnquiryJson(
                                              "value",
                                              event,
                                              i
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Label(Arabic)
                                        </label>
                                        <input
                                          type="text"
                                          name="jsonLabel"
                                          className="form-control"
                                          style={{ direction: "rtl" }}
                                          placeholder="Label(Arabic)"
                                          value={item.label_ar || ""}
                                          onChange={(event) => {
                                            this.setEnquiryJson(
                                              "label_ar",
                                              event,
                                              i
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Value(Arabic)
                                        </label>
                                        <input
                                          type="text"
                                          name="jsonValue"
                                          className="form-control"
                                          style={{ direction: "rtl" }}
                                          placeholder="Value"
                                          value={item.value_ar || ""}
                                          onChange={(event) => {
                                            this.setEnquiryJson(
                                              "value_ar",
                                              event,
                                              i
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>

                                    {i > 0 && (
                                      <div className="col-sm-2 col-md-2">
                                        <div className="form-group">
                                          <label className="form-label">
                                            &nbsp;
                                          </label>
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            key={i}
                                            onClick={(event) => {
                                              this.removeEnquiryData(i);
                                            }}
                                          >
                                            <i className="fa fa-minus"></i>
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                          <div className="col-sm-12 col-md-12">
                            <div
                              className="form-group"
                              style={{ textAlign: "right" }}
                            >
                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={this.appendData}
                                style={{ marginRight: "20px" }}
                              >
                                <i className="fa fa-plus"></i>
                                <span>Add New</span>
                              </button>
                            </div>
                          </div>

                          <hr />
                          <div className="row">
                            <div className="col-sm-6 col-md-6">
                              <div className="form-group d-flex">
                                <label className="form-label pt-1">
                                  &nbsp;Does the auctions need an update?
                                </label>
                                <span className="mt-1">
                                  <ToggleButton
                                    value={this.state.input.has_auction_update}
                                    onToggle={(value) => {
                                      let { input } = this.state;
                                      input.has_auction_update = !value;
                                      this.setState({
                                        input,
                                      });
                                    }}
                                  />
                                </span>
                                {/* <input
                                name="has_auction_update"
                                type="checkbox"
                                checked={this.state.input.has_auction_update}
                                onChange={this.toggleAuctionUpdate}
                              /> */}
                              </div>
                            </div>

                            <div className="col-sm-3 col-md-3">
                              <div className="">
                                <select
                                  className="form-control"
                                  name="auction_status"
                                  value={this.state.input.auction_status}
                                  onChange={this.handleChange}
                                  disabled={
                                    !this.state.input.has_auction_update
                                  }
                                >
                                  <option value="">Auction status</option>
                                  <option value="E">{i18n.t("expired")}</option>
                                  <option value="A">{i18n.t("active")}</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-sm-3 col-md-3">
                              <div className="">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                  disabled={this.state.is_updating}
                                >
                                  {this.state.is_updating
                                    ? "Updating.."
                                    : "Update Group"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}

                  <hr />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.service_info_popup}
          onHide={() => this.setState({ service_info_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            When using a percentage (%) in the service charge, it will be
            calculated based on the percentage of the total amount. Otherwise,
            it will be considered as a fixed amount.
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
