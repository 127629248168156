import React from "react";
import i18n from "../../../../i18n";
import Chart from "react-apexcharts";
import { Spinner } from "react-bootstrap";
import { getLoggedUser } from "../../../../state/user";

const user = getLoggedUser();

export class AuctionsChart extends React.Component {
  constructor() {
    super();
    this.state = {
      data_panel: true,
    };
  }

  componentDidMount() {}

  render() {
    let { main_auctions, is_loading, auctions } = this.props;
    let { data_panel } = this.state;
    let data_panel_cls = data_panel ? "card-body text-center pt-2" : "d-none";
    return (
      <React.Fragment>
        <div className="container p-0 m-0">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="card">
                <div
                  className="card-header d-flex justify-content-between"
                  onClick={() => {
                    this.setState({
                      data_panel: !data_panel,
                    });
                  }}
                  role="button"
                >
                  <h3 className="card-title">{i18n.t("auctions")}</h3>
                  <i
                    className={
                      data_panel ? "fa fa-angle-up" : "fa fa-angle-down"
                    }
                  />
                </div>
                <div className={data_panel_cls}>
                  {is_loading && <Spinner animation="border" size="lg" />}
                  {!is_loading && (
                    <div>
                      <Chart
                        options={auctions.options}
                        series={auctions.series}
                        type="donut"
                        height={320}
                      />

                      {user.role !== "A" && (
                        <div>
                          <button
                            className="btn btn-sm"
                            style={{
                              background: "#008ffb",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[0]}
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm"
                            style={{
                              background: "#00e396",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[1]}
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm"
                            style={{
                              background: "#fdb01a",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[2]}
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm"
                            style={{
                              background: "#ff4560",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[3]}
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-sm"
                            style={{
                              background: "#765dcf",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[4]}
                          </button>
                        </div>
                      )}
                      {user.role === "A" && (
                        <div>
                          <a
                            href="auctions_list?type=active&section=main"
                            className="btn btn-sm"
                            style={{
                              background: "#008ffb",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[0]}
                          </a>
                          &nbsp;
                          <a
                            href="auctions_list?type=upcoming&section=main"
                            className="btn btn-sm"
                            style={{
                              background: "#00e396",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[1]}
                          </a>
                          &nbsp;
                          <a
                            href="auctions_list?type=previous&section=main"
                            className="btn btn-sm"
                            style={{
                              background: "#fdb01a",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[2]}
                          </a>
                          &nbsp;
                          <a
                            href="auctions_list?type=featured&section=main"
                            className="btn btn-sm"
                            style={{
                              background: "#ff4560",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[3]}
                          </a>
                          &nbsp;
                          <a
                            href="auctions_list?type=direct&section=main"
                            className="btn btn-sm"
                            style={{
                              background: "#765dcf",
                              color: "#FFF",
                            }}
                          >
                            {main_auctions[4]}
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
