import React from "react";
import i18n from "../../i18n";
export class ChairmansMessage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">{i18n.t("chairmans_message")}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container mb-8">
            <div className="text-justify">
              <h2 className="mb-7">{i18n.t("chairmans_message")}</h2>
              <div className="row">
                <div
                  className="col-xl-4 col-lg-4 col-md-12"
                  style={{ textAlign: "center" }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/about/chairman.jpg"
                    }
                    style={{ borderRadius: "50%", border: "3px solid #554c99" }}
                    alt=""
                  />
                  <br />
                  <h4 style={{ textAlign: "center", marginTop: "1rem" }}>
                    {i18n.t("suleym_junaibi")}
                  </h4>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12">
                  <p className="leading-Automatic">{i18n.t("cm_para_1")}</p>
                  <p className="leading-Automatic mb-4">
                    {i18n.t("cm_para_2")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
