import React from "react";
import { getLoggedUser } from "../../state/user";
//import { Link } from "react-router-dom";
import {
  getNotifications,
  updateUserNotification,
} from "../../models/notification";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";

const user = getLoggedUser();
export class NotificationIcon extends React.Component {
  constructor() {
    super();
    this.audioRef = React.createRef();
    this.state = {
      title: "",
      description: "",
      open_popup: false,
      view_button: false,
      existing_id: false,
    };
    this.notificationCheck = this.notificationCheck.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  playSound = () => {
    const audio = this.audioRef.current;
    if (audio.paused || audio.currentTime === 0) {
      audio.play().catch((error) => {
        console.error("Error playing audio:", error.message);
      });
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  componentDidMount() {
    this.notificationInterval = setInterval(() => {
      if (user && user.id) {
        this.notificationCheck();
      }
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.notificationInterval);
  }

  async notificationCheck() {
    if (!this.state.open_popup) {
      try {
        let response = await getNotifications();
        let data = response.data;

        if (response.success) {
          this.setState({
            title: data.title,
            description: data.description,
            view_button: data.url,
            existing_id: data.existing_id,
          });
          this.setState({ open_popup: true });
          this.playSound();
        }
      } catch (error) {
        console.error("Error checking notifications:", error);
      }
    }
  }

  closeModal() {
    this.setState({ open_popup: false });
    this.viewNotification(false);
  }

  openModal() {
    this.setState({ open_popup: true });
  }

  async viewNotification(redirect = true) {
    let { existing_id, view_button } = this.state;
    const response = await updateUserNotification(existing_id);
    if (response.success && redirect) {
      window.location.href = "/" + view_button;
    }
  }

  render() {
    let { title, description, open_popup, view_button } = this.state;
    return (
      <React.Fragment>
        {open_popup && (
          <Modal
            size="md"
            show={this.openModal}
            onHide={this.closeModal}
            animation={true}
            dialogClassName=""
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                {i18n.t("notification")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>{title}</h5>
              <div>{description}</div>
            </Modal.Body>
            <Modal.Footer>
              {view_button && (
                <button
                  className="btn btn-success btn-sm rounded-5 mx-1"
                  onClick={() => {
                    this.viewNotification(true);
                  }}
                >
                  {i18n.t("view")}
                </button>
              )}
              <button
                className="btn btn-danger btn-sm rounded-5 mx-1"
                onClick={this.closeModal}
              >
                {i18n.t("cancel")}
              </button>
            </Modal.Footer>
          </Modal>
        )}

        <audio ref={this.audioRef}>
          <source
            src={process.env.PUBLIC_URL + "/assets/audio/notification.wav"}
            type="audio/wav"
          />
          Your browser does not support the audio tag.
        </audio>
      </React.Fragment>
    );
  }
}
