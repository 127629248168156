import React from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { facebookLogin } from "../../models/users";
import swal from "sweetalert";
import i18n from "../../i18n";

export class FacebookSignin extends React.Component {
  onSuccess = async (res) => {
    let response = await facebookLogin(res);
    if ("token" in response.data) {
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  render() {
    return (
      <React.Fragment>
        <FacebookLogin
          appId="462331852452465"
          onSuccess={(response) => {
            console.log("Login Success!", response);
          }}
          onFail={(error) => {
            console.log("FB Login Failed!", error);
          }}
          onProfileSuccess={this.onSuccess}
          render={({ onClick }) => (
            <button
              className="icon-size border-none rounded-circle mx-1 shadow-none btn btn-lg"
              onClick={onClick}
            >
              <img
                className="icon-size"
                src="./assets/images/icons8-facebook-48.png"
                alt=""
              />
            </button>
          )}
        />
      </React.Fragment>
    );
  }
}
