import React from "react";
export class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleString(),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    let lng = this.props.lang;
    let ts = lng === "ar" ? "ar-OM" : "en-US";
    let time = new Date().toLocaleTimeString(ts, { timeZone: "Asia/Muscat" });
    let dt = new Date().toISOString(ts).split("T")[0];

    var fdt = dt.split("-");
    let newDt = fdt[2] + "-" + fdt[1] + "-" + fdt[0] + " " + time;

    this.setState({
      time: newDt,
    });
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }

  render() {
    return this.state.time;
  }
}
