import React from "react";
import { SideBar } from "./sidebar";
import i18n from "../../i18n";
import { getAuctionChartData, getSalesChartData } from "../../models/auctions";
import { getUserChartData } from "../../models/users";
import { getDashboardData } from "../../models/dashboard";
import { getLoggedUser } from "../../state/user";
import { DashboardIcons } from "./helper/dashboard_icons";
import { HomeBanner } from "../home/homeBanner";
import { DashboardUserData } from "./helper/dashboard_user_data";
import { DashboardAuctionEvaluationData } from "./helper/dashboard_auc_eval_data";
import { DashboardWalletData } from "./helper/dashboard_wallet_data";
import { AuctionsChart } from "./helper/charts/auctions_chart";
import { SalesChart } from "./helper/charts/sales_chart";
const user = getLoggedUser();

export class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      first_panel: false,
      second_panel: true,
      third_panel: false,
      forth_panel: false,
      input: {},
      is_loading_1: false,
      is_loading_2: false,
      is_loading_3: false,
      is_loading_4: false,
      main_auctions: [],
      charity_auctions: [],
      auctions: {
        series: [0, 0, 0, 0, 0],
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "70%",
              },
            },
          },
          labels: [
            i18n.t("active"),
            i18n.t("upcoming"),
            i18n.t("previous"),
            i18n.t("featured"),
            i18n.t("direct"),
          ],
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
      charity: {
        series: [0, 0, 0, 0, 0],
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "100%",
              },
            },
          },
          labels: ["Active", "Upcoming", "Previous", "Featured", "Direct"],
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
      userData: "monthly",
      daily: {},
      monthly: {},
      salesData: "monthly",
      yearlySales: {},
      monthlySales: {},
      active_auctions: 0,
      active_bidders: 0,
      total_bidders: 0,
      total_bids: 0,
      maximum_bid_amount: 0,
      total_amount: 0,
      auction_in_bidding: 0,
      dd_loading: false,
      group: false,
      is_side_bar: false,
      wallet_data: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadDashboardData = this.loadDashboardData.bind(this);
    this.hideSideBar = this.hideSideBar.bind(this);
  }

  componentDidMount() {
    this.loadAuctionsData();
    this.loadCharityAuctionsData();
    this.loadUserChartData();
    this.loadSalesChartData();
    this.loadDashboardData();
    if (user.role === "A" || user.role === "M") {
      setInterval(this.loadDashboardData, 5000);
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async loadDashboardData(data = false) {
    this.setState({ dd_loading: true });
    let { client, group, auction } = data;
    let input = this.state.input;
    input.client = typeof client !== "undefined" ? client : input.client;
    input.group = typeof group !== "undefined" ? group : input.group;
    input.auction = typeof auction !== "undefined" ? auction : input.auction;
    if (typeof input.group === "undefined") {
      //this.setState({ dd_loading: false });
      //return;
    }
    let response = await getDashboardData(input);
    if (response.success) {
      let { data } = response;
      this.setState({
        active_auctions: data.active_auctions,
        active_bidders: data.active_bidders,
        total_bidders: data.total_bidders,
        maximum_bid_amount: data.maximum_bid_amount,
        total_amount: data.total_amount,
        auction_in_bidding: data.auction_in_bidding,
        total_bids: data.total_bids,
        wallet_data: data.wallet_data,
      });
      this.setState({ dd_loading: false });
    }
  }

  async loadAuctionsData() {
    this.setState({ is_loading_1: true });
    let auctions = this.state.auctions;
    const response = await getAuctionChartData("main");
    let data = response.data;
    if (response) {
      auctions.series = [
        data.active,
        data.upcoming,
        data.previous,
        data.featured,
        data.direct,
      ];
      this.setState({
        is_loading_1: false,
        auctions,
        main_auctions: auctions.series,
      });
    }
  }

  async loadCharityAuctionsData() {
    this.setState({ is_loading_2: true });
    let charity = this.state.charity;
    const response = await getAuctionChartData("charity");
    let data = response.data;
    if (response) {
      charity.series = [
        data.active,
        data.upcoming,
        data.previous,
        data.featured,
        data.direct,
      ];
      this.setState({
        is_loading_2: false,
        charity,
        charity_auctions: charity.series,
      });
    }
  }

  async loadUserChartData() {
    this.setState({ is_loading_3: true });
    let daily = this.state.daily;
    let monthly = this.state.monthly;
    const response = await getUserChartData();
    let data = response.data;
    if (response) {
      this.setState({ is_loading_3: false });
      daily.options = {
        chart: { id: "chart-userdata" },
        xaxis: {
          categories: data.dates,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      daily.series = [
        {
          name: "Total Users",
          data: data.values,
        },
      ];

      monthly.options = {
        chart: { id: "chart-userdata" },
        xaxis: {
          categories: data.months,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      monthly.series = [
        {
          name: "Total Users",
          data: data.month_values,
        },
      ];

      this.setState({ daily, monthly });
    }
    //console.log(this.state.users);
  }

  async loadSalesChartData() {
    this.setState({ is_loading_4: true });
    let yearly = this.state.yearlySales;
    let monthly = this.state.monthlySales;
    const response = await getSalesChartData();
    let data = response.data;
    if (response) {
      this.setState({ is_loading_4: false });
      yearly.options = {
        chart: { id: "chart-salesdata" },
        xaxis: {
          categories: data.years,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      yearly.series = [
        {
          name: "Total Sales",
          data: data.year_values,
        },
      ];

      monthly.options = {
        chart: { id: "chart-salesdata" },
        xaxis: {
          categories: data.months,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      monthly.series = [
        {
          name: "Total Sales",
          data: data.month_values,
        },
      ];

      this.setState({ yearlySales: yearly, monthlySales: monthly });
    }
    //console.log(this.state.users);
  }

  hideSideBar(is_side_bar) {
    this.setState({
      is_side_bar: is_side_bar,
    });
  }

  render() {
    let { wallet_data, auctions, monthlySales, yearlySales } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <section className="sptb mt-3 mx-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.is_side_bar && <SideBar />}
              </div>
              <div
                className={
                  this.state.is_side_bar
                    ? "col-xl-12 col-lg-12 col-md-12 mt-7 dash-container"
                    : "col-xl-9 col-lg-12 col-md-12 mt-7 dash-container"
                }
              >
                <div class="d-flex justify-content-between mt-3 dash-container-head">
                  <h3 class="font-weight-bold">{i18n.t("live_dashboard")}</h3>
                </div>

                {!["CE", "GM"].includes(user.role) && (
                  <div class="bg-white rounded-4 hide_div mb-3">
                    <div class="p-3">
                      <div class="p-2 user-brd rounded-4">
                        <div class="grid_container ">
                          <div class="outer_round1 flex_container">
                            <div class="inner_round">
                              <span>{this.state.main_auctions[0] || 0}</span>
                            </div>
                            <div class=" outer_text lh20">
                              <span>{i18n.t("active")}</span>
                              {i18n.t("auctions")}
                            </div>
                          </div>
                          <div class="outer_round2 flex_container">
                            <div class="inner_round">
                              <span>{this.state.main_auctions[1] || 0}</span>
                            </div>
                            <div class=" outer_text lh20">
                              <span>{i18n.t("upcoming")}</span>
                              {i18n.t("auctions")}
                            </div>
                          </div>
                          <div class="outer_round3 flex_container">
                            <div class="inner_round">
                              <span>{this.state.main_auctions[2] || 0}</span>
                            </div>
                            <div class=" outer_text lh20">
                              <span>{i18n.t("previous")}</span>
                              {i18n.t("auctions")}
                            </div>
                          </div>
                          <div class="outer_round4 flex_container">
                            <div class="inner_round">
                              <span>{this.state.main_auctions[3] || 0}</span>
                            </div>
                            <div class=" outer_text lh20">
                              <span>{i18n.t("featured")}</span>
                              {i18n.t("auctions")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {["CE", "GM"].includes(user.role) && (
                  <>
                    <div className="row">
                      <div className="col-xl-6 col-lg-12 col-md-6">
                        <DashboardAuctionEvaluationData />
                      </div>
                      <div className="col-xl-6 col-lg-12 col-md-6">
                        <AuctionsChart
                          main_auctions={this.state.main_auctions}
                          is_loading={this.state.is_loading_1}
                          auctions={auctions}
                          is_opened={true}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      {["A", "M", "CE", "GM"].includes(user.role) && (
                        <DashboardIcons
                          active_auctions={this.state.active_auctions}
                          active_bidders={this.state.active_bidders}
                          total_bidders={this.state.total_bidders}
                          maximum_bid_amount={this.state.maximum_bid_amount}
                          total_amount={this.state.total_amount}
                          auction_in_bidding={this.state.auction_in_bidding}
                          total_bids={this.state.total_bids}
                          dd_loading={this.state.dd_loading}
                          is_side_bar={this.state.is_side_bar}
                          loadByGroup={this.loadDashboardData}
                          hideSideBar={this.hideSideBar}
                        />
                      )}

                      {["CE", "GM"].includes(user.role) && (
                        <>
                          <DashboardWalletData wallet_data={wallet_data} />
                          <DashboardUserData />
                          <SalesChart
                            monthlySales={monthlySales}
                            yearlySales={yearlySales}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
