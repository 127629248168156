import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import {
  getOganization,
  deleteOrganization,
  updateOrganization,
} from "../../models/organization";
import i18n from "../../i18n";
import { HomeBanner } from "../home/homeBanner";
import FileInput from "../helpers/fileInput";
export class EditOrganization extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let organizationId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      organizationId: organizationId,
      input: {
        password: "",
      },
      errors: {},
      groups: [],
      collapse: true,
      file_organization_image: null,
      organization_image_new: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleClient = this.toggleClient.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadSelectedOrganisation();
  }

  toggleClient = () => {
    let input = this.state.input;
    input.is_client = !this.state.input.is_client;
    this.setState({
      input,
    });
  };

  async loadSelectedOrganisation() {
    const response = await getOganization(this.state.organizationId);
    let org = response.data;
    if (org.is_client === 1) {
      org.is_client = true;
    }
    this.setState({ input: org });

    if (org.user) {
      let user = org.user_info;
      let input = this.state.input;
      input.username = user.username;
      input.email = user.email;
      input.mobile = user.mobile;
      input.password = "";
      this.setState({ input: input });
    }
  }

  onFileChange = (event) => {
    if (event.target.name === "file_organization_image") {
      this.setState({ organization_image_new: event.target.files[0] });
    }
  };

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();

      formData.append("organization_name", this.state.input.organization_name);
      formData.append(
        "organization_name_ar",
        this.state.input.organization_name_ar
      );
      formData.append("description", this.state.input.description);
      formData.append("description_ar", this.state.input.description_ar);
      if (this.state.organization_image_new) {
        formData.append(
          "file_organization_image",
          this.state.organization_image_new
        );
      }
      let client = this.state.input.is_client ? 1 : 0;
      formData.append("is_client", client);
      if (
        client === 1 &&
        (!this.state.input.username ||
          !this.state.input.email ||
          !this.state.input.mobile)
      ) {
        swal("Failed", "All fields are mandatory. Please fill them.", "error");
        return;
      }
      formData.append("username", this.state.input.username);
      if (this.state.input.password !== "") {
        formData.append("password", this.state.input.password);
      }
      formData.append("email", this.state.input.email);
      formData.append("mobile", this.state.input.mobile);

      formData.append("terms", null);
      formData.append("_method", "patch");
      const response = await updateOrganization(
        formData,
        this.state.organizationId
      );
      if (response && response.data && response.data.id) {
        this.setState({ input: response.data });
        swal(i18n.t("success"), response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((response) => {})
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {}
  };

  deleteRow() {
    const response = deleteOrganization(this.state.organizationId);
    if (response.success) {
      swal(i18n.t("success"), "Item deleted", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          window.location.href = "/organization";
        })
        .catch((response) => {
          swal("Failed", "Something went wrong", "error");
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="">
                  <form
                    id="UpdateProfile"
                    className="card mb-0"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="card-header">
                      <h3 className="card-title">Edit Organization</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Title</label>
                            <input
                              type="text"
                              name="organization_name"
                              className="form-control"
                              placeholder="Name"
                              value={this.state.input.organization_name}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Title (Arabic)</label>
                            <input
                              type="text"
                              name="organization_name_ar"
                              className="form-control"
                              placeholder="Organization Name"
                              value={this.state.input.organization_name_ar}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Description</label>
                            <input
                              type="text"
                              name="description"
                              className="form-control"
                              placeholder="Description"
                              value={this.state.input.description}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Description (Arabic)
                            </label>
                            <input
                              type="text"
                              name="description_ar"
                              className="form-control"
                              placeholder="Description"
                              value={this.state.input.description_ar}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Organization Image
                            </label>
                            <FileInput
                              placeholder={i18n.t("choose_file")}
                              className="form-control"
                              name="file_organization_image"
                              onChange={this.onFileChange}
                              value={this.state.file_organization_image}
                            />
                            {
                              <img
                                src={this.state.input.file_organization_image}
                                width="100"
                                height="100"
                                alt=""
                              />
                            }
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <label
                              className="form-label"
                              style={{ float: "left", marginRight: "1rem" }}
                            >
                              Is Client? &nbsp;
                              <input
                                name="is_client"
                                type="checkbox"
                                checked={this.state.input.is_client}
                                onChange={this.toggleClient}
                                style={{ float: "left", margin: "4px" }}
                              />
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12 bg-light">
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("username")}
                                </label>
                                <input
                                  type="text"
                                  name="username"
                                  className="form-control"
                                  placeholder={i18n.t("username")}
                                  value={this.state.input.username}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label className="form-label">Password</label>
                                <input
                                  type="text"
                                  name="password"
                                  className="form-control"
                                  placeholder="Password"
                                  value={this.state.input.password}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label className="form-label">Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  className="form-control"
                                  placeholder="Email"
                                  value={this.state.input.email}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label className="form-label">Mobile</label>
                                <input
                                  type="text"
                                  name="mobile"
                                  className="form-control"
                                  placeholder="Mobile"
                                  value={this.state.input.mobile}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                          <hr />
                          <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                              Update Organization
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <hr />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
