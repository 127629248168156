import React from "react";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export class TermsConditionsPayment extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">{i18n.t("terms_conditions_payment")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        {i18n.t("home")}
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("terms_conditions_payment")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="text-justify">
              <h2 className="mb-8">{i18n.t("terms_conditions_payment")}</h2>
              <p className="leading-Automatic">
                {i18n.t("terms_payment_text_1")}
              </p>
              <p className="leading-Automatic">
                {i18n.t("terms_payment_text_2")}
              </p>
              <p className="leading-Automatic">
                {i18n.t("terms_payment_text_3")}
              </p>
              <p className="leading-Automatic mb-8">
                {i18n.t("terms_payment_text_4")}
              </p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
