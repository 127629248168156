import React from "react";
import { baseUrl } from "../../../constants/global";
//import { Link } from "react-router-dom";
//import ReactTooltip from "react-tooltip";
import {
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

let path = window.location.pathname;

export class AuctionShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: path,
    };
  }

  render() {
    let auction = this.props.auction;
    let url = baseUrl + "auction-details/" + auction.id;
    let imageUrl = auction.group_image;
    return (
      <React.Fragment>
        <ul className="socials">
          <li>
            <WhatsappShareButton
              url={url}
              quote={auction.title}
              image={imageUrl}
            >
              {/* title={auction.title} */}
              <WhatsappIcon size={25} round={true} />
            </WhatsappShareButton>
          </li>
          <li>
            <FacebookShareButton
              url={url}
              quote={auction.title}
              image={imageUrl}
            >
              <FacebookIcon size={25} round={true} />
            </FacebookShareButton>
          </li>
          <li>
            <TwitterShareButton
              url={url}
              quote={auction.title}
              image={imageUrl}
            >
              <svg viewBox="0 0 64 64" width="25" height="25">
                <circle cx="32" cy="32" r="31" fill="#0f1419"></circle>
                <path
                  d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z"
                  fill="white"
                ></path>
              </svg>
            </TwitterShareButton>
          </li>
          <li>
            <LinkedinShareButton
              url={url}
              quote={auction.title}
              image={imageUrl}
            >
              <LinkedinIcon size={25} round={true} />
            </LinkedinShareButton>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
