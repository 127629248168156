import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export class ListTable extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let baseColor = "#cccccc";
    const rows = Array.from({ length: 10 }, (_, index) => index);
    return (
      <React.Fragment>
        <table className="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th>
                <Skeleton height={10} width={10} baseColor={baseColor} />
              </th>
              <th>
                <Skeleton height={10} width={50} baseColor={baseColor} />
              </th>
              <th>
                <Skeleton height={10} width={50} baseColor={baseColor} />
              </th>
              <th>
                <Skeleton height={10} width={50} baseColor={baseColor} />
              </th>
              <th>
                <Skeleton height={10} width={50} baseColor={baseColor} />
              </th>
              <th>
                <Skeleton height={10} width={50} baseColor={baseColor} />
              </th>
              <th>
                <Skeleton height={10} width={50} baseColor={baseColor} />
              </th>
              <th>
                <Skeleton height={10} width={50} baseColor={baseColor} />
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <Skeleton height={10} width={10} baseColor={baseColor} />
                </td>
                <td className="">
                  <Skeleton height={10} width={50} baseColor={baseColor} />
                </td>
                <td className="">
                  <Skeleton height={10} width={50} baseColor={baseColor} />
                </td>
                <td className="">
                  <Skeleton height={10} width={50} baseColor={baseColor} />
                </td>
                <td className="">
                  <Skeleton height={10} width={50} baseColor={baseColor} />
                </td>
                <td className="">
                  <Skeleton height={10} width={50} baseColor={baseColor} />
                </td>
                <td className="">
                  <Skeleton height={10} width={50} baseColor={baseColor} />
                </td>
                <td className="">
                  <Skeleton height={10} width={50} baseColor={baseColor} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}
