import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import {
  createUser,
  deleteUser,
  getUserInfo,
  getUsers,
  roles,
} from "../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal, Spinner } from "react-bootstrap";
import { EditUser } from "./helper/edit_user";
import { HomeBanner } from "../home/homeBanner";

let lang = localStorage.getItem("I18N_LANGUAGE");
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

export class UsersList extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
      update_popup: false,
      add: {
        country_code: "+968",
      },
      data: {},
      errors: {},
      is_loading: false,
      users: {},
      hideSidebar: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
      },
      columns: [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          wrap: true,
          width: "50px",
        },
        {
          name: i18n.t("name"),
          selector: "name",
          sortable: true,
        },
        {
          name: i18n.t("username"),
          selector: "username",
          sortable: true,
        },
        {
          name: i18n.t("role"),
          selector: "role",
          wrap: true,
          cell: (row) => roles[row.role],
        },
        {
          name: i18n.t("email"),
          selector: "email",
        },
        {
          name: i18n.t("mobile"),
          selector: "mobile",
          wrap: true,
          cell: (row) => row.country_code + " " + row.mobile,
        },
        {
          name: i18n.t("view"),
          button: true,
          cell: (row) => (
            <button
              className="btn btn-warning"
              onClick={(e) => {
                this.viewRow(row.id);
              }}
            >
              {i18n.t("view")}
            </button>
          ),
        },
        {
          name: i18n.t("delete"),
          button: true,
          cell: (row) =>
            row.role === "A" ? (
              <span className="text-danger">{i18n.t("not_allowed")}</span>
            ) : (
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  this.deleteRow(row.id);
                }}
              >
                {i18n.t("delete")}
              </button>
            ),
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.triggerReload = this.triggerReload.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllUsers(qstring);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }
  handleAddChange(event) {
    let { add } = this.state;
    add[event.target.name] = event.target.value;
    this.setState({ add });
  }

  handleSearch(event) {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllUsers(qstring);
  }

  async loadAllUsers(q) {
    this.setState({ is_loading: true });
    const response = await getUsers(q);
    let users = response.data;
    let meta = response.meta;
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ users: users });
      this.setState({ totalRows: meta.total });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async viewRow(id) {
    const response = await getUserInfo(id);
    let data = response.data;
    this.setState({ data, update_popup: true });
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllUsers(qstring);
  };

  async deleteRow(id) {
    var ans = window.confirm("Do you want to continue.?");
    if (ans) {
      const response = await deleteUser(id);
      if (response.success) {
        swal(i18n.t("success"), "User deleted", "success", {
          buttons: false,
          timer: 2000,
        })
          .then((response) => {
            window.location.reload();
          })
          .catch((response) => {
            swal("Failed", "Something went wrong", "error");
          });
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        const response = await createUser(this.state.add);
        if (response.success) {
          swal(i18n.t("success"), "User created successfully!!", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              this.resetForm();
              let { input } = this.state;
              let q = new URLSearchParams(input).toString();
              this.loadAllUsers(q);
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  resetForm() {
    let { add } = this.state;
    add["name"] = "";
    add["mobile"] = "";
    add["email"] = "";
    add["username"] = "";
    add["country_code"] = "+968";
    add["password"] = "";
    add["role"] = "";
    this.setState({ add: add });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.add;
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your Name.";
    }
    if (!input["username"]) {
      isValid = false;
      errors["username"] = "Please enter a valid Username.";
    }
    if (!input["country_code"]) {
      isValid = false;
      errors["country_code"] = "Please enter phone country code.";
    }
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your Phone Number.";
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["email"] || !pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = "Please enter valid email address.";
    }
    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your Password.";
    }
    if (!input["role"]) {
      isValid = false;
      errors["role"] = "Please select a role.";
    }

    this.setState({
      errors: errors,
    });
    console.log(errors);
    return isValid;
  }

  triggerReload(opt) {
    if (opt) {
      let { input } = this.state;
      let qstring = new URLSearchParams(input).toString();
      this.loadAllUsers(qstring);
    }
  }

  render() {
    let users = this.state.users;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between">
                    <div className=" d-flex">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      <h3 className="card-title">{i18n.t("users")}</h3>
                    </div>
                    <button
                      className="btn btn-dark"
                      onClick={(event) => {
                        this.setState({ open_popup: !this.state.open_popup });
                      }}
                    >
                      {i18n.t("add_new_user")}
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.is_loading && (
                        <div className="text-center">
                          <Spinner animation="border" size="lg" />
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search by Name, Username, email or phone"
                              name="qtext"
                              value={this.state.input.qtext}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <select
                              className="form-control"
                              name="role"
                              value={this.state.input.role}
                              onChange={this.handleChange}
                            >
                              <option value="">
                                -- {i18n.t("select_any_role")} --
                              </option>
                              {Object.keys(roles).map((rKey, i) => {
                                return (
                                  <option value={rKey} key={i}>
                                    {roles[rKey]}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <button
                              className="btn btn-primary btn-block"
                              onClick={this.handleSearch}
                            >
                              {i18n.t("search")}
                            </button>
                          </div>
                        </div>
                        {(this.state.input.qtext || this.state.input.role) && (
                          <div className="col-md-1">
                            <div className="form-group">
                              <button
                                className="btn btn-outline-danger btn-block"
                                onClick={(e) => {
                                  let { input } = this.state;
                                  input.qtext = "";
                                  input.role = "";
                                  this.setState({ input });
                                  this.handleSearch(e);
                                }}
                              >
                                <i className="fa fa-times" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>

                      {!this.state.is_loading && users.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            fixedHeader
                            columns={this.state.columns}
                            data={users}
                            customStyles={customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            paginationPerPage={15}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: i18n.t("users_per_page"),
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          size="lg"
          show={this.state.open_popup}
          onHide={() => this.setState({ open_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("add_new_user")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="single-page">
                <div className="col-md-12 d-block mx-auto">
                  <div className="wrapper wrapper2">
                    <form
                      id="Register"
                      className="card-body"
                      tabindex="500"
                      autoComplete="off"
                    >
                      <div className="name mb-1">
                        <input
                          type="text"
                          name="name"
                          value={this.state.add.name}
                          onChange={this.handleAddChange}
                          autoComplete="name"
                          className="form-control custom-input rounded-pill"
                        />
                        <label>{i18n.t("name")}</label>
                        <div className="text-danger">
                          {this.state.errors.name}
                        </div>
                      </div>

                      <div className="mb-1">
                        <label>{i18n.t("phone_number")}</label>
                        <div className="input-group input-group-sm mt-2">
                          <span className="country-flag-field border col-1">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/flags/ar.svg"
                              }
                              alt=""
                              width="25"
                              height="25"
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control col-9"
                            style={{ "z-index": 0 }}
                            name="mobile"
                            value={this.state.add.mobile}
                            onChange={this.handleAddChange}
                          />
                        </div>
                        <div className="text-danger">
                          {this.state.errors.mobile}
                        </div>
                      </div>

                      <div className="email mb-1">
                        <label>{i18n.t("email")}</label>
                        <input
                          type="text"
                          name="email"
                          value={this.state.add.email}
                          onChange={this.handleAddChange}
                          style={{ direction: "ltr" }}
                          className="form-control custom-input rounded-pill"
                        />
                        <div className="text-danger">
                          {this.state.errors.email}
                        </div>
                      </div>

                      <div className="username mb-1">
                        <label>{i18n.t("username")}</label>
                        <input
                          type="text"
                          name="username"
                          value={this.state.add.username}
                          onChange={this.handleAddChange}
                          autoComplete="username"
                          className="form-control custom-input rounded-pill"
                        />
                        <div className="text-danger">
                          {this.state.errors.username}
                        </div>
                      </div>

                      <div className="passwd mb-1">
                        <label>{i18n.t("password")}</label>
                        <input
                          type="text"
                          name="password"
                          value={this.state.add.password}
                          onChange={this.handleAddChange}
                          className="form-control custom-input rounded-pill"
                        />
                        <div className="text-danger">
                          {this.state.errors.password}
                        </div>
                      </div>

                      <div className="mb-1">
                        <label>{i18n.t("role")}</label>
                        <select
                          className="form-control"
                          name="role"
                          value={this.state.add.role}
                          onChange={this.handleAddChange}
                        >
                          <option value="">
                            -- {i18n.t("select_any_role")} --
                          </option>
                          {Object.keys(roles).map((rKey, i) => {
                            return (
                              <option value={rKey} key={i}>
                                {roles[rKey]}
                              </option>
                            );
                          })}
                        </select>
                        <div className="text-danger">
                          {this.state.errors.role}
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary btn-block mt-5"
                        onClick={this.handleSubmit}
                      >
                        {i18n.t("submit")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          show={this.state.update_popup}
          onHide={() => this.setState({ update_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              View/Edit User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditUser
              data={this.state.data}
              triggerReload={this.triggerReload}
            />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
