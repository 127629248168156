import axios from "axios";
import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const user = getLoggedUser();
const endpoint = apiUrl + "gate_pass";

async function createGatePass(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllGatePasses(q) {
  return fetch(endpoint + "?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getGatePass(id) {
  return fetch(endpoint + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateGatePass(payload, id) {
  return fetch(endpoint + "/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function offlineUpdate(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "update_offline",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteGatePass(id) {
  return fetch(endpoint + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createGatePass,
  getAllGatePasses,
  getGatePass,
  updateGatePass,
  deleteGatePass,
  offlineUpdate,
};
