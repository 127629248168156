import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const maskClasses = {
  D: "Diamond",
  G: "Gold",
};

const endpoint = apiUrl + "groups";
const user = getLoggedUser();

async function createGroup(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(endpoint, payload, config);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllGroups(q) {
  return fetch(endpoint + "?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getGroup(id) {
  return fetch(endpoint + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateGroup(payload, id) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      endpoint + "/" + id,
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteGroup(id) {
  return fetch(endpoint + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getGroupsByTypes(type, subType) {
  return fetch(`getGroups/${type}/${subType}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getEnrolledGroups(q) {
  return fetch(apiUrl + `groups_enrolled?${q}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createGroup,
  getAllGroups,
  getGroup,
  updateGroup,
  deleteGroup,
  getGroupsByTypes,
  getEnrolledGroups,
  maskClasses,
};
