import React from "react";
import { Modal } from "react-bootstrap";
import { BiddingComp } from "./biddingComp";

export class BiddingModal extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
    };
    this.openBidPopup = this.openBidPopup.bind(this);
  }

  openBidPopup() {
    this.setState({ open_popup: true });
  }

  render() {
    let auction = this.props.auction;
    let current_lng = this.props.lng;
    let key = this.props.key;
    return (
      <React.Fragment key={key}>
        <Modal
          size="customModal"
          show={this.props.open_popup}
          onHide={this.props.closeModal}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Mzadcom-Auctions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BiddingComp
              auction={auction}
              lng={current_lng}
              key={key}
              updateRow={this.props.updateRow}
            />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
