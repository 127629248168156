import React from "react";
import { SideBar } from "./sidebar";
import { getMyWinningList } from "../../models/auctions";
import { getEnrolledGroups } from "../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import { apiUrl } from "../../constants/global";
import { getLoggedUser } from "../../state/user";
import { HomeBanner } from "../home/homeBanner";
import { BidderRow } from "../helpers/bidderRow";
import { TableSkeleton } from "../helpers/skeletons/TableSkeleton";
import { getOrganizations } from "../../models/organization";

const user = getLoggedUser();

export class WinningBids extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_menu: false,
      paid_amount: 0,
      is_payment_popup: false,
      input: {
        id: user.id,
      },
      groups: {},
      clients: {},
      bidders: {},
      phone_loader: false,
      open_popup: false,
      bidder_loading: false,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e4f7ff",
            color: "#000000",
          },
        },
      },
      //actionsMemo : React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []),
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          width: "70px",
        },
        {
          name: i18n.t("auction_name"),
          cell: (row) => row.title,
          sortable: true,
        },
        {
          name: i18n.t("client"),
          cell: (row) =>
            row.organizationInfo ? row.organizationInfo.organization_name : "",
          sortable: true,
        },
        {
          name: i18n.t("winning_amount"),
          cell: (row) => row.bid_amount,
        },
        {
          name: i18n.t("paid_amount"),
          cell: (row) => row.client_paid_amount,
        },
        {
          name: i18n.t("balance"),
          cell: (row) =>
            Number(row.bid_amount) - Number(row.client_paid_amount),
        },
        {
          name: i18n.t("invoice"),
          cell: (row) => {
            return (
              <a
                href={
                  apiUrl +
                  "my_winnings/pdf?" +
                  new URLSearchParams({
                    id: user.id,
                    auction: row.id,
                  }).toString()
                }
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="px-1"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/pdf-icon-big.png"
                  }
                  alt=""
                />
              </a>
            );
          },
        },
        {
          name: i18n.t("due_days"),
          cell: (row) => {
            return (
              <button className="btn btn-success btn-sm rounded-5">0</button>
            );
          },
        },
        {
          name: i18n.t("tracking"),
          cell: (row) => {
            return (
              <button
                className="btn btn-success btn-sm rounded-5"
                onClick={() => {
                  window.location.href = `/auction_tracker?auction=${row.id}&group=${row.group}&client=${row.organization}`;
                }}
              >
                <img
                  className="px-1"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/graph-new-white.png"
                  }
                  alt=""
                  width="20"
                  height="15"
                />
              </button>
            );
          },
        },
        {
          name: i18n.t("receipt"),
          cell: (row) => {
            return (
              <a href={true} target="_blank" rel="noreferrer">
                <img
                  className="px-1"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/pdf-icon-big.png"
                  }
                  alt=""
                />
              </a>
            );
          },
        },
        {
          name: i18n.t("pay"),
          cell: (row) => {
            return row.approve_status === "A" ? (
              <button
                className="btn btn-warning btn-sm btn-block rounded-5"
                style={{ fontSize: "0.7rem", textWrap: "nowrap" }}
                onClick={() => {
                  window.location.href = `/client-payment?auction=${row.id}&group=${row.group}`;
                }}
              >
                {i18n.t("pay_now")}
              </button>
            ) : (
              <button
                className="btn btn-warning btn-sm btn-block rounded-5"
                disabled={true}
              >
                {i18n.t("pay_now")}
              </button>
            );
          },
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
    this.loadAllClients();
    this.searchSubmit();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.setState({ input });
    }
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getEnrolledGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAllClients() {
    let q = "";
    if (user && user.id) {
      q += `&user=${user.id}&is_winning=1`;
    }
    const response = await getOrganizations(q);
    let clients = response.data;
    this.setState({ clients });
  }

  async searchSubmit() {
    this.setState({ bidders: {} });
    this.setState({ bidder_loading: true });
    const response = await getMyWinningList(this.state.input);
    if (response.data) {
      this.setState({ bidder_loading: false });
      this.setState({ bidders: response.data });
      this.setState({ total_bid_amount: response.meta.total_amount });
      this.setState({ due_amount: response.meta.due_amount });
    }
  }

  render() {
    let { groups, clients, bidders } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5" id="winning-bids-container">
          <div className="container">
            <div className="row">
              <div
                className={
                  this.state.hide_menu
                    ? "d-none"
                    : "col-xl-3 col-lg-12 col-md-12"
                }
              >
                <SideBar />
              </div>
              <div
                className={
                  this.state.hide_menu
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0 p-3">
                  <div className="border p-2 rounded-4">
                    <div className="container_grid payment-for-large">
                      <form
                        id="UpdateProfile"
                        className="mb-0"
                        autoComplete="off"
                      >
                        <div className="row d-flex justify-content-between">
                          <div className="col-md-auto col-lg-6 left-column">
                            <div>
                              <img
                                className="img_response mb-3"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/orange-uil_trophy.png"
                                }
                                height="38"
                                width="38"
                                alt=""
                              />
                              <span
                                className="winning-bid-title"
                                style={{ fontSize: "34px", fontWeight: "700" }}
                              >
                                {i18n.t("winning_bids")}
                              </span>
                            </div>
                            {/* <!-- Left Column - Row 2 --> */}
                            <div
                              className="grey-text text-start"
                              style={{
                                fontSize: "11px",
                                fontWeight: "500",
                                color: "#00000080",
                                marginLeft: "7px",
                              }}
                            >
                              <span>{i18n.t("winning_overview_text")}</span>
                            </div>
                          </div>
                          <div className="col-md-auto col-lg-6 right-column mt-2">
                            {/* <!-- Right Column - Row 1 --> */}
                            <div className="col-sm-12 col-xs-12 col-lg-5 col-md-5">
                              <select
                                aria-label="Default select example"
                                className="form-select select fs14 text-black"
                                name="client"
                                value={this.state.input.client}
                                onChange={this.handleChange}
                              >
                                <option value="">
                                  {i18n.t("select_client")}
                                </option>
                                {clients.length > 0 &&
                                  clients.map((item) => {
                                    return (
                                      <option value={item.id} key={item.id}>
                                        {item.organization_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="col-sm-12 col-xs-12 col-lg-5 col-md-5">
                              <select
                                className="form-select select fs14 text-black"
                                data-placeholder="Select Class"
                                name="group"
                                value={this.state.input.group}
                                onChange={this.handleChange}
                              >
                                <option value="">
                                  {i18n.t("select_group")}
                                </option>
                                {groups.length > 0 &&
                                  groups.map((item) => {
                                    return (
                                      <option value={item.id} key={item.id}>
                                        {item.group_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <div className="col-sm-12 col-xs-12 col-lg-2 col-md-2">
                              <div className="form-group">
                                <button
                                  type="button"
                                  className="btn btn-success rounded-5 p-1 px-2 border border-black"
                                  onClick={this.searchSubmit}
                                >
                                  <span className="">
                                    <i className="fa fa-search" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="user-brd rounded-4 p-4 payment-main mt-2">
                      <div className="d-flex align-items-center gap-1">
                        <div className="user-brd rounded-4 p-2 br-div">
                          <div className="d-flex justify-content-center align-items-center">
                            <div>
                              <div className="text-black fs12">
                                {i18n.t("payment_dues")}
                                <img
                                  className="px-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/refresh-blue-icon.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="d-flex align-items-center gap-2 mt-1">
                                <div className="fs24 text-blue fwb">
                                  <span>{this.state.due_amount}</span>
                                </div>
                                <div className="fs16">
                                  <span>{i18n.t("omr")}</span>
                                </div>
                                <div className="fs12 text-gray d-mob-none">
                                  <span>Updated 3 min ago</span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="pnow-div"
                              style={{ paddingLeft: "5px" }}
                            >
                              {/* <button
                                className="btn rounded-pill btn-warning mt-2 pnow text-white wrap-no"
                                onClick={() => {
                                  window.location.href = `/client-payment${
                                    this.state.input.group
                                      ? `?group=${this.state.input.group}`
                                      : ""
                                  }`;
                                }}
                              >
                                {i18n.t("pay_now")}
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {bidders.length > 0 && (
                          <div>
                            <div className="d-flex">
                              <div className="p-2">
                                <a
                                  href={
                                    apiUrl +
                                    "my_winnings/pdf?" +
                                    new URLSearchParams(
                                      this.state.input
                                    ).toString()
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {i18n.t("download")}
                                  <img
                                    className="px-1"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/pdf-icon-big.png"
                                    }
                                    alt=""
                                  />
                                </a>
                              </div>
                              {/* <div className="p-2">
                              <a href={true}>
                                <img
                                  className="px-1"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/excel-icon-big.png"
                                  }
                                  alt=""
                                />
                              </a>
                            </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="table-responsive ">
                      {this.state.bidder_loading && (
                        <div className="text-center">
                          <TableSkeleton columns={5} rows={9} />
                        </div>
                      )}
                      {!this.state.bidder_loading && !bidders.length && (
                        <div className="alert alert-danger">
                          {i18n.t("no_result")}
                        </div>
                      )}

                      {bidders.length > 0 && (
                        <div className="row">
                          <div className="col-12">
                            <div
                              className="fs15 text-blue fwb border rounded-3 m-auto p-1 px-2"
                              style={{ textAlign: "right" }}
                            >
                              {i18n.t("total_amount")} : {i18n.t("omr")}
                              <b>{this.state.total_bid_amount}</b>
                            </div>
                          </div>
                        </div>
                      )}

                      {bidders.length > 0 && (
                        <div>
                          <div className="table-wrapper d-mob-none">
                            <DataTable
                              title=""
                              columns={this.state.columns}
                              data={bidders}
                              customStyles={this.state.customStyles}
                              highlightOnHover
                              pagination
                              paginationPerPage={15}
                              paginationRowsPerPageOptions={[15, 25, 50, 100]}
                              paginationComponentOptions={{
                                rowsPerPageText: "Bids per page:",
                                rangeSeparatorText: i18n.t("out"),
                              }}
                              //actions={this.state.actionsMemo}
                            />
                          </div>
                          <div className="show_mobile">
                            {bidders.map((item) => {
                              return <BidderRow bidder={item} />;
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
