import React from "react";
import i18n from "../../../i18n";
let lng = localStorage.getItem("I18N_LANGUAGE");

export class OrganizationAuctionItem extends React.Component {
  constructor() {
    super();
    this.state = {
      expand: false,
    };
  }

  componentDidMount() {}

  render() {
    let { data } = this.props;
    let { expand } = this.state;
    let cls_nm = "accordian-body collapse";
    if (expand) {
      cls_nm = "accordian-body";
    }
    let { auctions } = data;
    return (
      <React.Fragment>
        <tr
          data-toggle="collapse"
          data-target="#demo1"
          className="accordion-toggle"
        >
          <td>{data.id}</td>
          <td>
            {lng === "ar" ? data.organization_name_ar : data.organization_name}
          </td>
          <td>{auctions.length}</td>
          <td>
            <button
              className="btn btn-default btn-xs"
              onClick={() => {
                this.setState({ expand: !this.state.expand });
              }}
            >
              <span
                className={
                  this.state.expand
                    ? "fa fa-minus-circle fa-2x text-danger"
                    : "fa fa-plus-circle fa-2x text-success"
                }
              ></span>
            </button>
          </td>
        </tr>

        <tr>
          <td colspan="12" className="hiddenRow" style={{ padding: "0" }}>
            <div className={cls_nm} id="demo1">
              <table className="table table-striped pb-0 mb-0 border border-info rounded-0">
                <thead>
                  <tr className="info">
                    <th
                      style={{ backgroundColor: "#00f2d8" }}
                      class="rounded-0"
                    >
                      {i18n.t("ID")}
                    </th>
                    <th
                      style={{ backgroundColor: "#00f2d8" }}
                      class="rounded-0"
                    >
                      {i18n.t("auction")}
                    </th>
                    <th
                      style={{ backgroundColor: "#00f2d8" }}
                      class="rounded-0"
                    >
                      {i18n.t("start_date")}
                    </th>
                    <th
                      style={{ backgroundColor: "#00f2d8" }}
                      class="rounded-0"
                    >
                      {i18n.t("end_date")}
                    </th>
                    <th
                      style={{ backgroundColor: "#00f2d8" }}
                      class="rounded-0"
                    ></th>
                  </tr>
                </thead>

                <tbody>
                  {auctions.map((item, i) => {
                    return (
                      <tr
                        data-toggle="collapse"
                        className="accordion-toggle"
                        data-target="#demo10"
                      >
                        <td class="rounded-0">{item.id}</td>
                        <td class="rounded-0">
                          {lng === "ar" ? item.title_ar : item.title}
                        </td>
                        <td class="rounded-0">{item.start_date}</td>
                        <td class="rounded-0">{item.end_date}</td>
                        <td class="rounded-0">
                          <a
                            target="_new"
                            href={"auction-details/" + item.id}
                            className="btn btn-default btn-sm p-0 px-2"
                          >
                            <i
                              className={
                                lng === "ar"
                                  ? "glyphicon glyphicon-arrow-left text-info"
                                  : "glyphicon glyphicon-arrow-right text-info"
                              }
                            ></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}
