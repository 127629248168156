import React from "react";
import i18n from "../../i18n";
export class WhatIsMzadcom extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">{i18n.t("whatis_mzadcom")}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container mb-8">
            <div className="text-justify">
              <h2 className="mb-7">{i18n.t("whatis_mzadcom")}</h2>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <p className="leading-Automatic">{i18n.t("aboutUsPara1")}</p>
                  <p className="leading-Automatic mb-4">
                    {i18n.t("aboutUsPara2")}
                  </p>
                  <p className="leading-Automatic mb-4">
                    {i18n.t("aboutUsPara3")}
                  </p>
                  <p className="leading-Automatic mb-4">
                    {i18n.t("aboutUsPara4")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
