import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import getUser from "../../models/getUser";
import updateUser from "../../models/updateUser";
import swal from "sweetalert";
import i18n from "../../i18n";
import {
  sendProfileOTP,
  verifyOTP,
  sendOTP,
  verifyOTPCreds,
} from "../../models/users";
import { Modal, Spinner } from "react-bootstrap";
import { HomeBanner } from "../home/homeBanner";
import FileInput from "../helpers/fileInput";
//import { ProfileDocs } from "./profile_docs";

export class EditProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      user_loading: false,
      user: {},
      input: {
        is_company: false,
        password: "",
      },
      verify: {},
      errors: {},
      opt_modal: false,
      otp_loading: false,
      is_loading: false,
      is_mail_loading: false,
      is_phone_loading: false,
      otp_msg: "",
      open_popup: false,
      signature: null,
      file_id_number: null,
      file_auth_letter: null,
      file_cr_number: null,
      file_vat_certificate: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleVerifyChange = this.handleVerifyChange.bind(this);
    this.handleBeforeSubmit = this.handleBeforeSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendProfileVerificationOTP =
      this.sendProfileVerificationOTP.bind(this);
    this.sendVerification = this.sendVerification.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ user_loading: true });
    window.scrollTo(0, 0);
    const response = await getUser();
    let user = response.data;
    if (user.mobile.length < 3) {
      user.mobile = "";
    }
    this.setState({ input: user });
    this.setState({ user });
    this.setState({ user_loading: false });
  }

  onFileChange = (event) => {
    if (event.target.name === "signature") {
      if (!this.validateFileSizeSig(event.target.files[0])) {
        swal("Failed", "Allowed file size between 5KB - 5MB", "error");
        this.setState({ signature: null });
        return;
      }
      if (!this.validateFileType(event.target.files[0])) {
        swal("Failed", "Allowed only jpg/jpeg/png/gif/bmp images", "error");
        this.setState({ signature: null });
        return;
      }
      this.setState({ signature: event.target.files[0] });
    } else {
      if (!this.validateFileSize(event.target.files[0])) {
        swal("Failed", "File size should be less than 5MB", "error");
        return;
      }
      if (event.target.name === "file_id_number") {
        this.setState({ file_id_number: event.target.files[0] });
      }
      if (event.target.name === "file_auth_letter") {
        this.setState({ file_auth_letter: event.target.files[0] });
      }
      if (event.target.name === "file_cr_number") {
        this.setState({ file_cr_number: event.target.files[0] });
      }
      if (event.target.name === "file_vat_certificate") {
        this.setState({ file_vat_certificate: event.target.files[0] });
      }
    }
  };

  validateFileType(file) {
    let fileName = file.name;
    let allowedExtensions = ["bmp", "jpg", "jpeg", "png", "gif"];
    var extension = fileName
      .substr(fileName.lastIndexOf(".") + 1)
      .toLowerCase();
    if (!allowedExtensions.includes(extension)) {
      return;
    }
    return true;
  }

  validateFileSizeSig(file) {
    const MIN_FILE_SIZE = 5; // 1MB
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  validateFileSize(file) {
    const MIN_FILE_SIZE = 5; // 1MB
    const MAX_FILE_SIZE = 5120; // 2MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  validateFileDimension(file) {
    const WIDTH = 200;
    const HEIGHT = 50;
    console.log(file);
    if (file.height !== HEIGHT) {
      return;
    }
    if (file.width !== WIDTH) {
      return;
    }
    return true;
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleVerifyChange(event) {
    let { verify } = this.state;
    verify[event.target.name] = event.target.value;
    this.setState({ verify });
  }

  handleBeforeSubmit = async (event) => {
    this.setState({ is_loading: true });
    const response = await verifyOTP(this.state.verify);
    if (response.success) {
      this.handleSubmit(event);
    } else {
      this.setState({ is_loading: false });
      swal("Failed", "Invalid OTP", "error");
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", this.state.input.name);
      formData.append("username", this.state.input.username);
      formData.append("email", this.state.input.email);
      formData.append("mobile", this.state.input.mobile);
      formData.append(
        "password",
        this.state.input.password ? this.state.input.password : ""
      );

      formData.append(
        "resident_card_number",
        this.state.input.resident_card_number
      );
      formData.append("is_company", Boolean(this.state.input.is_company));
      formData.append("account_number", this.state.input.account_number);
      formData.append("bank", this.state.input.bank);
      formData.append("authority_name", this.state.input.authority_name);
      formData.append("vat_number", this.state.input.vat_number);
      formData.append("cr_number", this.state.input.cr_number);

      if (this.state.signature) {
        formData.append(
          "signature",
          this.state.signature,
          this.state.signature.name
        );
      }

      if (this.state.file_id_number) {
        formData.append(
          "file_id_number",
          this.state.file_id_number,
          this.state.file_id_number.name
        );
      }
      if (this.state.file_auth_letter) {
        formData.append(
          "file_auth_letter",
          this.state.file_auth_letter,
          this.state.file_auth_letter.name
        );
      }
      if (this.state.file_cr_number) {
        formData.append(
          "file_cr_number",
          this.state.file_cr_number,
          this.state.file_cr_number.name
        );
      }
      if (this.state.file_vat_certificate) {
        formData.append(
          "file_vat_certificate",
          this.state.file_vat_certificate,
          this.state.file_vat_certificate.name
        );
      }

      const response = await updateUser(formData);
      if ("id" in response.data) {
        swal(i18n.t("success"), response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((value) => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem(
              "mzad_user_info",
              JSON.stringify(response.data)
            );
            this.setState({ is_loading: false });
            this.setState({ opt_modal: false });
            this.setState({ input: response.data });
            // this.setState({ signature: null });
            // this.setState({ file_id_number: null });
            // this.setState({ file_auth_letter: null });
            // this.setState({ file_cr_number: null });
            // this.setState({ file_vat_certificate: null });
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
        this.setState({ is_loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  async sendProfileVerificationOTP() {
    this.setState({ otp_loading: true });
    let response = await sendProfileOTP();
    if (response.success) {
      this.setState({ opt_modal: true, otp_loading: false });
    }
  }

  async sendVerification(type) {
    if (type === "email") {
      this.setState({ is_mail_loading: true });
    }
    if (type === "sms") {
      this.setState({ is_phone_loading: true });
    }
    let response = await sendOTP(type);
    let msg = response.message;
    this.setState({ otp_msg: msg });
    if (response.success) {
      let { input } = this.state;
      input.verify_type = type;
      this.setState({
        is_mail_loading: false,
        is_phone_loading: false,
        open_popup: true,
        input,
      });
    } else {
      swal("Failed", msg, "error");
      this.setState({ is_mail_loading: false, is_phone_loading: false });
    }
  }

  async verifyOTP() {
    let { input } = this.state;
    const response = await verifyOTPCreds({
      otp: input.otp,
      type: input.verify_type,
    });
    if (response.success) {
      let user = response.data;
      input.otp = "";
      this.setState({ user, input });
      this.setState({ open_popup: false });
      swal(i18n.t("success"), response.message, "success");
    } else {
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 dash-container dashboard-wrapper">
                <div className="col-xl-12 col-lg-12 col-md-12 p-0">
                  <div className="">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          {!this.state.user_loading &&
                            !this.state.user.email_verified_at && (
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-sm-9 col-md-9">
                                    <div className="alert alert-danger">
                                      {i18n.t("email_verify_prof")}
                                    </div>
                                  </div>
                                  <div className="col-sm-3 col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-success btn-block"
                                      onClick={() => {
                                        this.sendVerification("email");
                                      }}
                                    >
                                      {this.state.is_mail_loading && (
                                        <Spinner animation="border" size="sm" />
                                      )}
                                      {!this.state.is_mail_loading && (
                                        <span>{i18n.t("send_otp")}</span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                          {!this.state.user_loading &&
                            !this.state.user.mobile_verified_at && (
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-sm-9 col-md-9">
                                    <div className="alert alert-danger">
                                      {i18n.t("mob_verify_prof")}
                                    </div>
                                  </div>
                                  <div className="col-sm-3 col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-success btn-block"
                                      onClick={() => {
                                        this.sendVerification("sms");
                                      }}
                                    >
                                      {this.state.is_phone_loading && (
                                        <Spinner animation="border" size="sm" />
                                      )}
                                      {!this.state.is_phone_loading && (
                                        <span>{i18n.t("send_otp")}</span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <form
                  id="UpdateProfile"
                  className="card mt-3 dash-container-head"
                  autoComplete="off"
                >
                  <div className="card-header d-flex">
                    <h3 className="card-title" style={{ width: "80%" }}>
                      {i18n.t("edit_profile")}
                    </h3>
                    <div className="col-sm p-0">
                      <fieldset class="">
                        <div className="btn-group d-flex">
                          <button
                            type="button"
                            className={
                              !this.state.input.is_company
                                ? "btn btn-danger w-100"
                                : "btn btn-outline-danger w-100"
                            }
                            onClick={(event) => {
                              let { input } = this.state;
                              input.is_company = false;
                              this.setState({
                                input,
                              });
                            }}
                          >
                            {i18n.t("individual")}
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className={
                              this.state.input.is_company
                                ? "btn btn-danger w-100"
                                : "btn btn-outline-danger w-100"
                            }
                            onClick={(event) => {
                              let { input } = this.state;
                              input.is_company = true;
                              this.setState({
                                input,
                              });
                            }}
                          >
                            {i18n.t("company")}
                          </button>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("name")}</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder={i18n.t("name")}
                            value={this.state.input.name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("username")}
                          </label>
                          <input
                            type="text"
                            name="username"
                            className="form-control"
                            placeholder={i18n.t("username")}
                            value={this.state.input.username}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("email")} &nbsp;
                            {this.state.user.email_verified_at && (
                              <small className="text-success">
                                <i className="fa fa-check-circle" />
                                &nbsp;Verified
                              </small>
                            )}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={i18n.t("email")}
                            name="email"
                            value={this.state.input.email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("phone_number")}&nbsp;
                            {this.state.user.mobile_verified_at && (
                              <small className="text-success">
                                <i className="fa fa-check-circle" />
                                &nbsp;Verified
                              </small>
                            )}
                          </label>
                          <input
                            type="number"
                            name="mobile"
                            className="form-control"
                            placeholder={i18n.t("phone_number")}
                            value={this.state.input.mobile}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("password")}
                          </label>
                          <input
                            type="text"
                            name="password"
                            className="form-control"
                            placeholder={i18n.t("password")}
                            value={this.state.input.password}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("personal_card_no")}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="resident_card_number"
                            value={this.state.input.resident_card_number}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("bank")}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="bank"
                            value={this.state.input.bank}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("account_number")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="account_number"
                            value={this.state.input.account_number}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      {(this.state.input.is_company ||
                        this.state.input.is_company !== 0) && (
                        <React.Fragment>
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("cr_number")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={this.state.input.cr_number}
                                name="cr_number"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("vat_number")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={this.state.input.vat_number}
                                name="vat_number"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("auth_name")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={this.state.input.authority_name}
                                name="authority_name"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="row border border-warning p-1 m-1">
                        <div className="col-sm-4 col-md-2 border border-primary">
                          {this.state.input.signature && (
                            <div className="form-group">
                              <a
                                href={this.state.input.signature}
                                target="_new"
                              >
                                <img
                                  src={this.state.input.signature}
                                  alt=""
                                  style={{ width: "100%", display: "block" }}
                                />
                              </a>
                            </div>
                          )}
                          {!this.state.input.signature && (
                            <i className="fa fa-image" />
                          )}
                        </div>

                        <div className="col-sm-8 col-md-8">
                          <div className="form-group">
                            <label
                              for="form-label"
                              className="form-label"
                              style={{ textAlign: "left" }}
                            >
                              {i18n.t("signature")}
                            </label>
                            <FileInput
                              placeholder={i18n.t("choose_file")}
                              className="form-control"
                              name="signature"
                              onChange={this.onFileChange}
                              value={this.state.file_category_image}
                            />
                            <div className="text-help">
                              <small>
                                Image dimension should be 200px(W) x 50px(H)
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row border border-warning p-1 m-1">
                        <div
                          className="col-sm-4 col-md-2 border border-primary"
                          style={
                            this.state.input.file_id_number
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  background: "#2effa629",
                                }
                              : {
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "nowrap",
                                  background: "#ffa9a94d",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  fontSize: "5rem",
                                }
                          }
                        >
                          {this.state.input.file_id_number && (
                            <div className="form-group">
                              <a
                                href={this.state.input.file_id_number}
                                target="_new"
                              >
                                <img
                                  src={this.state.input.file_id_number}
                                  alt=""
                                  style={{ width: "100%", display: "block" }}
                                />
                              </a>
                            </div>
                          )}
                          {!this.state.input.file_id_number && (
                            <i className="fa fa-image" />
                          )}
                        </div>

                        <div className="col-sm-8 col-md-8">
                          <div className="form-group">
                            <label
                              for="form-label"
                              className="form-label"
                              style={{ textAlign: "left" }}
                            >
                              {i18n.t("attach_id_no")}
                            </label>
                            <FileInput
                              placeholder={i18n.t("choose_file")}
                              className="form-control"
                              name="file_id_number"
                              onChange={this.onFileChange}
                              value={this.state.file_category_image}
                            />
                          </div>
                        </div>
                      </div>

                      {(this.state.input.is_company ||
                        this.state.input.is_company !== 0) && (
                        <div>
                          <div className="row border border-warning p-1 m-1">
                            <div
                              className="col-sm-4 col-md-2 border border-primary"
                              style={
                                this.state.input.file_auth_letter
                                  ? {
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                      background: "#2effa629",
                                    }
                                  : {
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "nowrap",
                                      background: "#ffa9a94d",
                                      justifyContent: "center",
                                      alignContent: "center",
                                      fontSize: "5rem",
                                    }
                              }
                            >
                              {this.state.input.file_auth_letter && (
                                <div className="form-group">
                                  <a
                                    href={this.state.input.file_auth_letter}
                                    target="_new"
                                  >
                                    <img
                                      src={this.state.input.file_auth_letter}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        display: "block",
                                      }}
                                    />
                                  </a>
                                </div>
                              )}
                              {!this.state.input.file_auth_letter && (
                                <i className="fa fa-image" />
                              )}
                            </div>

                            <div className="col-sm-8 col-md-8">
                              <div className="form-group">
                                <label
                                  for="form-label"
                                  className="form-label"
                                  style={{ textAlign: "left" }}
                                >
                                  {i18n.t("auth_letter")}
                                </label>
                                <FileInput
                                  placeholder={i18n.t("choose_file")}
                                  className="form-control"
                                  name="file_auth_letter"
                                  onChange={this.onFileChange}
                                  value={this.state.file_auth_letter}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row border border-warning p-1 m-1">
                            <div
                              className="col-sm-4 col-md-2 border border-primary"
                              style={
                                this.state.input.file_cr_number
                                  ? {
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                      background: "#2effa629",
                                    }
                                  : {
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "nowrap",
                                      background: "#ffa9a94d",
                                      justifyContent: "center",
                                      alignContent: "center",
                                      fontSize: "5rem",
                                    }
                              }
                            >
                              {this.state.input.file_cr_number && (
                                <div className="form-group">
                                  <a
                                    href={this.state.input.file_cr_number}
                                    target="_new"
                                  >
                                    <img
                                      src={this.state.input.file_cr_number}
                                      alt=""
                                      style={{
                                        width: "100%",
                                        display: "block",
                                      }}
                                    />
                                  </a>
                                </div>
                              )}
                              {!this.state.input.file_cr_number && (
                                <i className="fa fa-image" />
                              )}
                            </div>

                            <div className="col-sm-8 col-md-8">
                              <div className="form-group">
                                <label
                                  for="form-label"
                                  className="form-label"
                                  style={{ textAlign: "left" }}
                                >
                                  {i18n.t("attacth_cr_copy")}
                                </label>
                                <FileInput
                                  placeholder={i18n.t("choose_file")}
                                  className="form-control"
                                  name="file_cr_number"
                                  onChange={this.onFileChange}
                                  value={this.state.file_cr_number}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row border border-warning p-1 m-1">
                            <div
                              className="col-sm-4 col-md-2 border border-primary"
                              style={
                                this.state.input.file_vat_certificate
                                  ? {
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                      background: "#2effa629",
                                    }
                                  : {
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "nowrap",
                                      background: "#ffa9a94d",
                                      justifyContent: "center",
                                      alignContent: "center",
                                      fontSize: "5rem",
                                    }
                              }
                            >
                              {this.state.input.file_vat_certificate && (
                                <div className="form-group">
                                  <a
                                    href={this.state.input.file_vat_certificate}
                                    target="_new"
                                  >
                                    <img
                                      src={
                                        this.state.input.file_vat_certificate
                                      }
                                      alt=""
                                      style={{
                                        width: "100%",
                                        display: "block",
                                      }}
                                    />
                                  </a>
                                </div>
                              )}
                              {!this.state.input.file_vat_certificate && (
                                <i className="fa fa-image" />
                              )}
                            </div>

                            <div className="col-sm-8 col-md-8">
                              <div className="form-group">
                                <label
                                  for="form-label"
                                  className="form-label"
                                  style={{ textAlign: "left" }}
                                >
                                  {i18n.t("attach_vat")}
                                </label>
                                <FileInput
                                  placeholder={i18n.t("choose_file")}
                                  className="form-control"
                                  name="file_vat_certificate"
                                  onChange={this.onFileChange}
                                  value={this.state.file_vat_certificate}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={this.sendProfileVerificationOTP}
                    >
                      {this.state.otp_loading && (
                        <Spinner animation="border" size="sm" />
                      )}
                      {!this.state.otp_loading && i18n.t("submit")}
                    </button>
                  </div>
                </form>

                {/* {this.state.user && <ProfileDocs data={this.state.user} />} */}
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.opt_modal}
          onHide={() => this.setState({ opt_modal: false })}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="otp-modal-sizes-title-lg">
              OTP verification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="cust-alert">
                  {i18n.t("otp_verification_msg")}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8 col-md-8">
                <div className="form-group">
                  <input
                    type="text"
                    name="otp"
                    className="form-control"
                    placeholder={i18n.t("OTP")}
                    value={this.state.verify.otp}
                    onChange={this.handleVerifyChange}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    onClick={this.handleBeforeSubmit}
                  >
                    {this.state.is_loading && (
                      <Spinner animation="border" size="sm" />
                    )}
                    {!this.state.is_loading && i18n.t("verify_submit")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.open_popup}
          onHide={() => this.setState({ open_popup: false })}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>OTP verification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.otp_msg && (
              <div className="row p-5">
                <div className="col-sm-12 col-md-12">
                  <div>{this.state.otp_msg}</div>
                </div>
              </div>
            )}

            <div className="row px-5">
              <div className="col-sm-9 col-md-9">
                <div className="form-group">
                  <input
                    type="text"
                    name="otp"
                    className="form-control"
                    placeholder={i18n.t("Enter OTP and verify")}
                    value={this.state.input.otp}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-sm-3 col-md-3">
                <div className="form-group">
                  <button className="btn btn-primary" onClick={this.verifyOTP}>
                    {i18n.t("verify")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
