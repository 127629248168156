import React from "react";
import { SideBar } from "./sidebar";
import {
  getAuctions,
  updateInvoiceHelpers,
  getAllAuctionsByGroup,
  auctionApproval,
  reAuction,
} from "../../models/auctions";
import { getAllCategories } from "../../models/categories";
import { getAllGroups } from "../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { getLoggedUser } from "../../state/user";
import { MzadLoader } from "../helpers/mzadLoader";
import { HomeBanner } from "../home/homeBanner";
import { getAllOrganization } from "../../models/organization";
const user = getLoggedUser();

function objectToQueryString(obj) {
  const queryString = Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryString;
}
export class ApprovedAuctions extends React.Component {
  constructor() {
    super();
    //let urlSearchParams = new URLSearchParams(window.location.search);
    //let params = Object.fromEntries(urlSearchParams.entries());
    this.state = {
      is_loading: false,
      auctions: [],
      open_popup: false,
      categories: [],
      clients: [],
      groups: [],
      group_auctions: [],
      totalRows: 0,
      currentPage: 1,
      approve: {},
      reauction: {},
      input: {
        section: "main",
        page: 1,
        limit: 15,
      },
      rem_amount: "",
      service_inv_link: "",
      remarks_popup: false,
      remarks: {
        amount: "",
        remark: "",
      },
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e4f7ff",
            color: "#000000",
          },
        },
      },
      selected_id: null,
      columns: [
        {
          id: 1,
          name: "ID",
          sortable: true,
          wrap: true,
          width: "70px",
          cell: (row) => row.id,
        },
        {
          id: 4,
          name: i18n.t("title"),
          cell: (row) => row.title,
          sortable: true,
        },
        {
          id: 6,
          name: i18n.t("group_name"),
          cell: (row) => row.group_name,
        },
        {
          id: 7,
          name: i18n.t("client"),
          cell: (row) =>
            row.organizationDetails
              ? row.organizationDetails.organization_name
              : "",
          wrap: true,
        },
        {
          id: 8,
          name: i18n.t("start_date"),
          cell: (row) => row.start_date,
          wrap: true,
        },
        {
          id: 9,
          name: i18n.t("end_date"),
          cell: (row) => row.end_date,
          wrap: true,
        },
        {
          id: 9,
          name: i18n.t("approved_document"),
          cell: (row) => {
            return row.file_approved_doc ? (
              <a
                className="btn btn-outline-info btn-sm text-info"
                href={row.file_approved_doc}
                target="_new"
              >
                {i18n.t("view")}
              </a>
            ) : (
              ""
            );
          },
          wrap: true,
        },
        {
          id: 9,
          name: i18n.t("reauction_document"),
          cell: (row) => {
            return row.file_reauction_doc ? (
              <a
                className="btn btn-outline-danger btn-sm text-danger"
                href={row.file_reauction_doc}
                target="_new"
              >
                {i18n.t("view")}
              </a>
            ) : (
              ""
            );
          },
          wrap: true,
        },
      ],
      bidder_loader: false,
      file_approved_doc: null,
      file_reauction_doc: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRemarkChange = this.handleRemarkChange.bind(this);
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
    this.remarksPopup = this.remarksPopup.bind(this);
    this.updateInveHelpers = this.updateInveHelpers.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleApproveChange = this.handleApproveChange.bind(this);
    this.handleReauctionChange = this.handleReauctionChange.bind(this);
    this.submitReAuction = this.submitReAuction.bind(this);
    this.submitApproval = this.submitApproval.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    console.log("params", params);
    let { input } = this.state;
    let qstring =
      params.type === "click"
        ? new URLSearchParams(params).toString()
        : objectToQueryString(input);
    this.loadAllCategories();
    this.loadAllOrganisation();
    this.loadAllGroups();
    this.loadAllAuctions(qstring);

    if (user.role === "U") {
      let columns = this.state.columns;
      delete columns[1];
      delete columns[2];
      this.setState({ columns });
    }
  }

  handlePageChange = (page) => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    let { input } = this.state;
    input["page"] = page;
    this.setState({ currentPage: page });
    this.setState({ input: input });
    this.setState({ query: params });
    let qstring = new URLSearchParams(input).toString();
    this.loadAllAuctions(qstring);
  };

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
    if (event.target.name === "group") {
      this.loadAuctionsByGrp();
    }
  }

  handleApproveChange(event) {
    let { approve } = this.state;
    approve[event.target.name] = event.target.value;
    this.setState({ approve });
  }

  handleReauctionChange(event) {
    let { reauction } = this.state;
    reauction[event.target.name] = event.target.value;
    this.setState({ reauction });
  }

  handleRemarkChange(event) {
    let { remarks } = this.state;
    remarks[event.target.name] = event.target.value;
    this.setState({ remarks });
  }

  async loadAllCategories() {
    const response = await getAllCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let clients = response.data;
    this.setState({ clients });
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuctionsByGrp(grp) {
    let { input } = this.state;
    const response = await getAllAuctionsByGroup(input.group);
    let group_auctions = response.data;
    this.setState({ group_auctions });
  }

  async loadAllAuctions(params) {
    params += "&is_approved_auctions=1";
    this.setState({ is_loading: true });
    const response = await getAuctions("?" + params);
    if (response) {
      let auctions = response.data;
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ auctions: auctions, is_loading: false });
    }
  }

  remarksPopup(link, amt, row) {
    if (row.inv_amount_words && row.inv_remarks && row.inv_title) {
      window.open(link, "_new");
      return;
    }
    this.setState({ service_inv_link: link, rem_amount: amt });
    this.setState({ remarks_popup: true });
    this.setState({ selected_id: row.id });
  }

  formatQueryNewLine(str) {
    str = encodeURIComponent(str);
    return str.replace(/%0A/g, "\\n");
  }

  updateInveHelpers() {
    let { remarks, selected_id } = this.state;
    let payload = {
      id: selected_id,
      inv_amount_words: remarks.amount,
      inv_title: remarks.title,
      inv_remarks: remarks.remark,
    };
    const response = updateInvoiceHelpers(payload);
    if (response) {
      let auction = response.data;
      console.log(auction);
      let params = {
        type: "",
      };
      this.loadAllAuctions(params);

      swal(i18n.t("success"), "Details updated", "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          this.setState({ remarks_popup: false });
          let url = this.state.service_inv_link;
          window.open(url, "_new");
        })
        .catch((response) => {
          swal("Failed", "Something went wrong", "error");
        });
    }
  }

  openApprove(id) {
    let { approve } = this.state;
    approve.approve_status = "A";
    this.setState({ open_approve_popup: true, approve, selected_id: id });
  }
  openReject(id) {
    let { approve } = this.state;
    approve.approve_status = "R";
    this.setState({ open_approve_popup: true, approve, selected_id: id });
  }
  openReAuction(id) {
    this.setState({ open_reauction_popup: true, selected_id: id });
  }

  async submitReAuction() {
    let { selected_id, reauction } = this.state;
    const formData = new FormData();
    formData.append("id", selected_id);
    formData.append("reg_start_date", reauction.reg_start_date);
    formData.append("reg_end_date", reauction.reg_end_date);
    formData.append("start_date", reauction.start_date);
    formData.append("end_date", reauction.end_date);
    let msg = i18n.t("success");
    if (this.state.file_reauction_doc) {
      formData.append(
        "file_reauction_doc",
        this.state.file_reauction_doc,
        this.state.file_reauction_doc.name
      );
    }
    const response = await reAuction(formData);
    if (response.success) {
      this.setState({ open_reauction_popup: false });
      swal(i18n.t("success"), msg, "success");
    } else {
      swal(i18n.t("error"), response.message, "error");
      return;
    }
  }

  async submitApproval() {
    let { selected_id, approve } = this.state;
    const formData = new FormData();
    formData.append("id", selected_id);
    formData.append("approve_status", approve.approve_status);
    let msg = i18n.t("rejected");
    if (approve.approve_status === "A") {
      if (this.state.file_approved_doc) {
        formData.append(
          "file_approved_doc",
          this.state.file_approved_doc,
          this.state.file_approved_doc.name
        );
      }
      msg = i18n.t("approved");
      formData.append("approved_date", approve.approved_date);
    }
    const response = await auctionApproval(formData);
    if (response.success) {
      this.setState({ open_approve_popup: false });
      swal(i18n.t("success"), msg, "success");
    } else {
      swal(i18n.t("error"), response.message, "error");
      return;
    }
  }
  render() {
    let { clients, groups, auctions, group_auctions } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0 ">
                  <div className="card-header d-flex justify-content-between">
                    <div className="d-flex">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      <h3 className="card-title" style={{ textWrap: "nowrap" }}>
                        {i18n.t("approved_auctions")}
                      </h3>
                    </div>
                    <div className="search-bar d-flex gap-1 justify-content-end">
                      <div className="col-sm-2 col-md-2">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Client"
                          name="client"
                          value={this.state.input.client}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_client")}</option>
                          {clients.length > 0 &&
                            clients.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.organization_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Group"
                          name="group"
                          value={this.state.input.group}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_group")}</option>
                          {groups.length > 0 &&
                            groups.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.group_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Auction"
                          name="auction"
                          value={this.state.input.auction}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_auction")}</option>
                          {group_auctions.length > 0 &&
                            group_auctions.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.title}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <button
                          className="btn btn-primary btn-block rounded-5"
                          type="button"
                          onClick={() => {
                            let { input } = this.state;
                            let qstring = new URLSearchParams(input).toString();
                            this.loadAllAuctions(qstring);
                          }}
                        >
                          {i18n.t("search")}
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* {!this.state.is_loading && ( */}
                  <div className="card-body">
                    {this.state.is_loading && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(255, 255, 255, 0.7)",
                          zIndex: "99",
                        }}
                      >
                        <MzadLoader
                          animation="grow"
                          size="md"
                          loading={this.state.is_loading}
                          style={{ position: "relative" }}
                        />
                      </div>
                    )}
                    <div className="table-responsive ">
                      {auctions.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            responsive={true}
                            title=""
                            fixedHeader
                            columns={this.state.columns}
                            data={auctions}
                            customStyles={this.state.customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            paginationPerPage={this.state.input.limit}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: "Auctions per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                            onChangeRowsPerPage={(size) => {
                              this.setState({ rows_per_page: 15 });
                              let { input } = this.state;
                              input.limit = size;
                              this.setState({ input });
                              this.handlePageChange(1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* )} */}
                  {!this.state.is_loading && auctions.length === 0 && (
                    <div className="m-3 text-center p-3 text-red">
                      {i18n.t("no_auctions_found")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
