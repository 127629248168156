import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const user = getLoggedUser();

async function getPayments() {
  return fetch(apiUrl + "payments", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getUserPayments() {
  return fetch(apiUrl + "user_payments", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updatePaymentRefund(payload) {
  return fetch(apiUrl + "update_refund", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getPaymentsByType(q) {
  return fetch(apiUrl + "payments_list?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateRefundStatus(payload) {
  return fetch(apiUrl + "update_refund_status", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getNotificationCounts() {
  return fetch(apiUrl + "notification_count", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  getPayments,
  getUserPayments,
  updatePaymentRefund,
  getPaymentsByType,
  updateRefundStatus,
  getNotificationCounts,
};
