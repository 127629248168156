import React from "react";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export class TermsConditions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <h3 className="card-title text-center mb-3 mt-3">
              {i18n.t("terms_conditions")}
            </h3>
            <div className="col-12 text-center">
              <iframe
                src="https://mzadcom.om/docs/mzad_terms.pdf"
                type="application/pdf"
                title="Mzadcom Terms"
                style={{ width: "80%", height: "100vh", overflow: "auto" }}
                allowfullscreen={true}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
